import React from "react";
//import "./spinner.css";
// import "./../assets/layout/spinner.css"
import '../../assets/layout/spinner.css';

export default function LoadingSpinner() {
    return (
        <div className="spinner-container">
            {/* <div className="loading-spinner">
            </div> */}
            <div className="spinner">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </div>
    );
}
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useFormik } from 'formik'
import { InputTextarea } from "primereact/inputtextarea";
import classNames from 'classnames';
import { useHistory, useParams, useLocation } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Toolbar } from 'primereact/toolbar';
import { InputNumber } from 'primereact/inputnumber';
import { Password } from 'primereact/password';

const Addcompany = () => {

    let emptyCompany = {
        id: '',
        name: '',
        firstname: '',
        lastname: '',
        contactperson: '',
        address: '',
        phone: '',
        email: '',
        remarks: '',
        noofdoctor: 0,
        password:'',

    };

    // Hooks
    const [Companydata, setCompanydata] = useState(emptyCompany);
    const [id, setid] = useState('');
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [password, setpassword] = useState('');

    // Flags
    const [submitted, setSubmitted] = useState(false);   //Checking Submitted Or Not
    const [emaildisable, setEmaildisable] = useState(false);
    const [addEditFlag, setAddEditFlag] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [viewAttendeesFlag, setViewAttendeesFlag] = useState(false);
    const [addupdateflag, setaddupdateflag] = useState(false);

    // Const
    const UserName = localStorage.getItem('Username');
    const toast = useRef(null);
    const param = useParams();
    const history = useHistory();
    const location = useLocation();


    useEffect(() => {
        if (param.id) {
            getcompanybyid(param.id);
        }
    }, []);

    // Add New Company
    const insertupdatecompany = () => {
        // history.push({ pathname: '/home/company', state: { detail: "AddCompany" } });
        setSubmitted(true);
        if (Companydata.name.trim() && Companydata.contactperson.trim() && Companydata.phone.trim() &&
            Companydata.email.trim() && (formik.errors.email == '' || formik.errors.email == undefined) &&
            (formik.errors.phone == '' || formik.errors.phone == undefined) && (formik.errors.password == '' || formik.errors.password == undefined)) {
            if (Companydata.id == '') {
                const url = '/api/Company/RegisterCompany';
                let splitname = Companydata.contactperson?.split(' ');
                Companydata.password = password;
                const data = {
                    "mrcompany": {
                        "Name": Companydata.name?.toUpperCase().trim(),
                        "Contactperson": Companydata.contactperson?.toUpperCase(),
                        "Address": Companydata.address?.toUpperCase(),
                        "Phone": Companydata.phone,
                        "Email": Companydata.email?.toUpperCase().trim(),
                        "Remarks": Companydata.remarks?.toUpperCase(),
                        "Noofdoctor": Companydata.noofdoctor == "" ? 0 : Companydata.noofdoctor,
                        "CreatedBy": UserName?.toUpperCase(),
                        "Password": password
                    },
                    "firstName": splitname.join(' ').toUpperCase(),
                    "lastName": splitname.pop().toUpperCase(),
                    "email": Companydata.email.trim(),
                    "phoneNumber": Companydata.phone,
                    "password": password,
                    "confirmPassword":password,
                    "role": "MR",
                };
                axios.post(url, data)
                    .then(() => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Company Added', life: 3000 });
                        history.push({ pathname: '/home/company', state: { detail: "AddCompany" } });
                    }).catch((error) => {
                        if (error?.response.data[0].code == "DuplicateUserName" || error.response.data.includes("System.Exception: Company With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Company Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
            if (Companydata.id) {
                const url = '/api/Company/Update';
                const data = {
                    "id": Companydata.id,
                    "Name": Companydata.name?.toUpperCase().trim(),
                    "Contactperson": Companydata.contactperson?.toUpperCase(),
                    "Address": Companydata.address?.toUpperCase(),
                    "Phone": Companydata.phone,
                    "Email": Companydata.email?.toUpperCase(),
                    "Remarks": Companydata.remarks?.toUpperCase(),
                    "Noofdoctor": Companydata.noofdoctor == "" ? 0 : Companydata.noofdoctor,
                    "CreatedBy": CreatedBy?.toUpperCase(),
                    "Created": Created,
                    "ModifiedBy": UserName?.toUpperCase(),
                    "Password": Companydata.password,
                };
                axios.put(url, data)
                    .then(() => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Updated', life: 3000 });
                        history.push({ pathname: '/home/company', state: { detail: "UpdateCompany" } });
                    }).catch((error) => {
                        if (error?.response.data.includes("System.Exception: Company With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Company Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
    };

    const getcompanybyid = (id) => {
        setIsLoading(true);
        axios.get('/api/Company/GetAsync/' + id)
            .then((Response) => {
                if (location.pathname.includes("viewcompany")) {
                    setDisabled(true);
                    setViewAttendeesFlag(true);
                    setAddEditFlag(true);
                };
                setaddupdateflag(true);
                setEmaildisable(true)
                setid(Response.data.id);
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                let _company = { ...Response.data };
                _company['id'] = (Response.data.id);
                _company['name'] = (Response.data.name);
                _company['firstname'] = (Response.data.firstname);
                _company['lastname'] = (Response.data.lastname);
                _company['contactperson'] = (Response.data.contactperson);
                _company['address'] = (Response.data.address);
                _company['phone'] = (Response.data.phone);
                _company['email'] = (Response.data.email);
                _company['remarks'] = (Response.data.remarks);
                _company['noofdoctor'] = (Response.data.noofdoctor?.toString());
                _company['password'] = (Response.data.password?.toString());
                setCompanydata(_company);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // FUNCTIONS
    // On Input Change
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || e.value || '';
        let _company = { ...Companydata };
        _company[`${name}`] = val;
        setCompanydata(_company);
    };

    // Email And Phone Validation
    const validate = (company) => {
        const errors = {};

        if (!company.email) {
            errors.email = ''
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(company.email)) {
            errors.email = 'Invalid email address'
        };
        if (!company.phone) {
            errors.phone = ''
        } else if (! /^(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(company.phone)) {
            errors.phone = 'Invalid phone number'
        };
        if (!password) {
            errors.password = ''
        } else if (! /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/g.test(password)) {
            errors.password = 'Password must be minimum 8 characters, contain at least one number, one special character and a mixture of upper and lower case letters'
        };
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            phone: '',
            password: ''
        },
        validate,
        onSubmit: (company) => {
            insertupdatecompany(company);
        },
    });

    const EditCompany = (rowdataid) => {
        history.push('/home/editcompany/' + rowdataid);
    };

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button severity="secondary" outlined icon="fa fa-arrow-left" className="p-button-secondary" tooltip="Back" tooltipOptions={{ position: 'bottom' }} onClick={(e) => history.goBack()} />
                <i class="pi pi-bars p-toolbar-separator"></i>
                {addEditFlag ? <Button label="Edit" icon="fa fa-pencil" className="p-button-secondary mr-2" onClick={() => { EditCompany(id); }} />
                    : <Button label="Save" icon="fa fa-save" onClick={() => { insertupdatecompany(id) }} outlined />
                }
            </div>
        );
    };

    return (
        <div className="trico-Form">
            <Toast ref={toast} />
            {isLoading ? <LoadingSpinner /> :
                (
                    <>
                        <div className="p-col">
                            <div className="card" >
                                <h5 className="headerfont">
                                    <span>
                                        <i className={addupdateflag ? viewAttendeesFlag ? `fa fa-binoculars mr-2` : `fa fa-edit mr-2` : `fa fa-plus-square mr-2`}></i>
                                        {addupdateflag ? viewAttendeesFlag ? `View Company` : `Update Company` : `Add Company`}
                                    </span>
                                </h5>
                                <Toolbar className="field" left={leftToolbarTemplate}></Toolbar>
                                {/* <form onSubmit={formik.handleSubmit}> */}
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 md:col-6 sm:col-12">
                                        <label id="label" htmlFor="Company">Company</label>
                                        <label id="Mandatoryfield">*</label>
                                        <InputText autoFocus value={Companydata.name?.toUpperCase()} name="name" required placeholder="Enter Company" onChange={(e) => onInputChange(e, 'name')}
                                            className={classNames({ 'p-invalid': submitted && !Companydata.name.trim() })} disabled={disabled} maxLength="100" />
                                        {submitted && !Companydata.name.trim() && <small className="p-invalid">Company is required.</small>}
                                    </div>
                                    <div className="field col-12 md:col-6 sm:col-12">
                                        <label id="label" htmlFor="firstname">Name</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText value={Companydata.contactperson?.toUpperCase()} name="contactperson" required placeholder="Enter Name" onChange={(e) => onInputChange(e, 'contactperson')}
                                            className={classNames({ 'p-invalid w-full': submitted && !Companydata.contactperson.trim() })} disabled={disabled} maxLength="50" />
                                        {submitted && !Companydata.contactperson && <small className="p-invalid">Name is required.</small>}
                                    </div>
                                    <div className="field col-12 md:col-3 sm:col-12">
                                        <label id="label" htmlFor="Phone">Phone No.</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText value={Companydata.phone} onBlur={formik.handleBlur} name="phone"
                                            onChange={(e) => { onInputChange(e, 'phone'); formik.handleChange(e)}}
                                            placeholder="Enter Phone No" required maxLength="20"
                                            className={classNames({ 'p-invalid w-full': submitted && !Companydata.phone })} disabled={disabled} />
                                        {submitted && formik.touched.phone && formik.errors.phone && (<span className="p-invalid">{formik.errors.phone}</span>)}
                                        {submitted && !Companydata.phone && <small className="p-invalid">Phoneno is required.</small>}
                                    </div>
                                    <div className="field col-12 md:col-3 sm:col-12">
                                        <label id="label" htmlFor="Email">Email</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText disabled={emaildisable} value={Companydata.email?.toUpperCase()} onBlur={formik.handleBlur} type="email" name="email"
                                            required placeholder="Enter Email" onChange={(e) => { onInputChange(e, 'email'); formik.handleChange(e) }}
                                            className={classNames({ 'p-invalid w-full': submitted && !Companydata.email })} />
                                        {formik.touched.email && formik.errors.email && (<span className="p-invalid">{formik.errors.email}</span>)}
                                        {submitted && !Companydata.email && <small className="p-invalid">Email is required.</small>}
                                    </div >
                                    <div className="field col-12 md:col-3 sm:col-12">
                                        <label id="label" htmlFor="Noofdoctor">No. Of Doctor</label>
                                        <InputNumber className="w-full" value={Companydata.noofdoctor} onChange={(e) => onInputChange(e, 'noofdoctor')} keyfilter="int" required placeholder="Enter No Of Doctors" disabled={disabled} allowEmpty={false} />
                                    </div>
                                    <div className={Companydata.id == ''  || viewAttendeesFlag ? 'field col-12 md:col-3 sm:col-12' : "hidden"}>
                                        <label id="label" htmlFor="Password" className="mb-1 font-bold"  >Password</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText value={password || Companydata.password} onBlur={formik.handleBlur} name="password"
                                            onChange={(e) => { setpassword(e.target.value) ;formik.handleChange(e) }} disabled={disabled}
                                            placeholder="Enter Password" required maxLength="15"
                                            className={classNames({ 'p-invalid w-full': submitted && !password })}  />
                                             {submitted && formik.touched.password && formik.errors.password && (<span className="p-invalid">{formik.errors.password}</span>)}
                                                {submitted && !password && <small className="p-invalid">Password is required.</small>}
                                        {/* <Password value={password} onBlur={formik.handleBlur}
                                                    type="password" name="password" placeholder="Enter Password" onChange={(e) =>{ setpassword(e.target.value) ;formik.handleChange(e) }} toggleMask feedback={false}
                                                    className={classNames({ 'p-invalid': submitted && !password })} />
                                                {submitted && formik.touched.password && formik.errors.password && (<span className="p-invalid">{formik.errors.password}</span>)}
                                                {submitted && !password && <small className="p-invalid">Password is required.</small>} */}
                                    </div>
                                    <div className="field col-12 md:col-6 sm:col-12">
                                        <label id="label" htmlFor="Address">Address</label>
                                        <InputTextarea className="w-full" value={Companydata.address?.toUpperCase()} onChange={(e) => onInputChange(e, 'address')} style={{ resize: "none" }} rows={5} cols={10} placeholder="Enter Address" required disabled={disabled} maxLength="250" />
                                    </div>
                                    <div className="field col-12 md:col-6 sm:col-12">
                                        <label id="label" htmlFor="Remarks">Remarks</label>
                                        <InputTextarea className="w-full" value={Companydata.remarks?.toUpperCase()} onChange={(e) => onInputChange(e, 'remarks')} style={{ resize: "none" }} rows={5} cols={10} placeholder="Enter Remarks" required disabled={disabled} maxLength="500" />
                                    </div>
                                </div>
                                <Toolbar className="field" left={leftToolbarTemplate}></Toolbar>
                                {/* </form> */}
                            </div >
                        </div>
                    </>
                )
            };
        </div>
    );
};

export default Addcompany;
import 'react-app-polyfill/ie11';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Login from './Login';
import { HashRouter, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import axios from 'axios';
import ResetPassword from './ResetPassword';
import EventClosed from './EventClosed';

var token = localStorage.getItem('access_token');

var eventenddt = null
var currentDate = new Date();
var page = null;


// Define an async function to handle the fetching of config.json
const loadConfig = async () => {
  try {
    const response = await fetch('config.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error loading configuration:', error);
    return null;
  }
};

// Use async/await to ensure that axios.defaults.baseURL is set after loading config.json
const init = async () => {
  const data = await loadConfig();
  if (data) {
    axios.defaults.baseURL = data[3].BaseUrl;
    eventenddt = new Date(data[4].RegistrationendDt);
    // currentDate = new Date().toLocaleDateString('en-GB', {
    //   day: '2-digit',
    //   month: '2-digit',
    //   year: 'numeric',
    // })
    if (currentDate > eventenddt) {
      page = 'eventclosed'
    }
    else {
      page = 'eventopen'
    }
  }

  // Render the React application
  ReactDOM.render(
    <React.StrictMode>

      {/* <HashRouter>
        <ScrollToTop>
          <>
            {page == 'eventclosed' ?
              <Route path={'/'} exact render={() => <EventClosed />} />
              : <Route path={'/'} exact render={() => <Login />} />
            }
            <Route path="/reset-password/:id" component={ResetPassword}></Route>
            <Route path={'/home'} component={App} />
          </>
        </ScrollToTop>
      </HashRouter> */}

      <HashRouter>
        <ScrollToTop>
          <>
            <Route path={'/'} exact render={() => <Login />} />
            <Route path="/reset-password/:id" component={ResetPassword}></Route>
            <Route path={'/home'} component={App} />
          </>
        </ScrollToTop>
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

// Call the init function
init();

axios.interceptors.request.use(async (request) => {
  return request;
});
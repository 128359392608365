import React, { useState, useEffect, useRef } from "react";
import {useHistory} from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from 'primereact/dropdown';


const NavigationList = () => {
    let emptyNavigation = {
       "navigation": {
        id: null,
        name: String,
        icon: String,
        url: String,
        parentid: null,
        menuorder: 0,
        haschildren: Boolean,
        isdisplay: Boolean,
       },
       "allowRoles" : [
        {
            id: String,
            concurrencyStamp: "",
            name: String,
            normalizedName: String
        }
    ]
    };

    const [Navigations, SetNavigations] = useState([]);
    const [skip, setSkip] = useState(1)
    const [limit, setLimit] = useState(10)
    const [totalRecords, setTotalRecords] = useState(0)
    const [NavigationDialog, setNavigationDialog] = useState(false);
    const [deleteNavigationDialog, setDeleteNavigationDialog] = useState(false);
    const [selectedNavigation, setSelectedNavigation] = useState(emptyNavigation);
    const [parentName, setParentName] = useState([{ name: '', code: '' }])
    const [parentValue, setParentValue] = useState(null)
    const [submitted, setSubmitted] = useState(false);
    const [Roles, SetRoles] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [roleId,setRoleId] = useState(new Array())
    const [roleName,setRoleName] = useState(new Array())
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();

    useEffect(() => {
        getnavigationList(-1, skip, limit);
    }, []);

    const getnavigationList = () => {
        setIsLoading(true);
        SetNavigations([])
        axios.post('/api/Navigation/GetAll', {
            sortOrder: "-1",
            CurrentPageNumber: skip,
            PageSize: limit
        }).then(Response => {
            SetNavigations(Response.data.data)
            setTotalRecords(Response.data.totalRecords)
            GetrolesList()
            let data = []
            data.push({name: '', code: null})
            for (const dataObj of Response.data.data.filter((i) => i.hasChildren === true)) {
                data.push({ name: dataObj.name, code: dataObj.id })
            }
            setParentName(data);
            setIsLoading(false);
        })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const saveNavigation = (e) => {
        let rolesArray = []
        for (const dataObj of selectedRoles) {
            rolesArray.push({  id:dataObj.key,
                concurrencyStamp: "",
                name: dataObj.name,
                normalizedName: dataObj.name })
        }
        setSubmitted(true);
        if (selectedNavigation.id == null) {
            axios.post(`/api/Navigation/Insert/Navigation`, {
            "navigation" : {
                            id : selectedNavigation.id,
                            name: selectedNavigation.name,
                            icon: "fa fa-home",
                            url: selectedNavigation.url,
                            parentId: parentValue.code,
                            menuOrder: selectedNavigation.menuorder,
                            hasChildren: selectedNavigation.haschildren,
                            isDisplay: selectedNavigation.isdisplay,
                        },
              "allowRoles" : rolesArray

            }
            )
                .then(res => {
                    const data = res.data;
                    setSelectedNavigation(data);
                    setNavigationDialog(false);
                    history.push('/home/navigation');
                });
        }
        // }
    }

    const deleteNavigation = () => {
        let _navigations = Navigations.filter((val) => val.id !== selectedNavigation.id);
        axios.delete(`api/Navigation/delete/${selectedNavigation.id}`)
            .then(res => {
                const data = res.data;
                setSelectedNavigation(data);
            });
        setDeleteNavigationDialog(false);
        toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Navigation Deleted",
            life: 3000
        });
    };

    const editNavigation = () => {
        // SetNavigations({...hotel});
        setNavigationDialog(true);
    }
    const confirmDeleteNavigation = (navigation) => {
        setSelectedNavigation(navigation);
        setDeleteNavigationDialog(true);
    }

    const hideDialog = () => {
        setNavigationDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteNavigationDialog(false);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded  p-button-success mr-2" onClick={(e) => editNavigation(e)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={(e) => confirmDeleteNavigation(e)} />
            </div>
        );
    }

    const NavigationDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}  style={{color:'#646464'}}  />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={(e) => saveNavigation()} style={{color:'#646464'}}/>
        </>
    );
    const deleteNavigationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={(e) => deleteNavigation()} />
        </>
    );

    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button label="Add Navigation" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew}  />
            </div>
        )
    }

    const openNew = () => {
        setSelectedNavigation(emptyNavigation);
        setSubmitted(false);
        setNavigationDialog(true);
    };

    const GetrolesList = () => {
        SetRoles([])
        axios.post('/api/Roles/GetAll', {
            paginationRequired: false
        }).then(Response => {
            let data = []
            for (const dataObj of Response.data.data) {
                data.push({ name: dataObj.name, key: dataObj.id })
            }
            SetRoles(data);
            setTotalRecords(Response.data.totalRecords)
        })
            .catch((error) => {
            });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _navigation = { ...selectedNavigation };
        _navigation[`${name}`] = val;
        setSelectedNavigation(_navigation);
    }

    const onRoleChange = (e) => {
        setRoleId((ing) => [...ing, e.value.key])
        setRoleName((ing) => [...ing, e.value.name])
        let _selectedRoles = [...selectedRoles];

        if (e.checked)
            _selectedRoles.push(e.value);
        else
            _selectedRoles = _selectedRoles.filter(roles => roles.name !== e.value.name);

        setSelectedRoles(_selectedRoles);
    };

    //pagination
    const onBasicPageChange = (event) => {
        SetNavigations([])
        setSkip(event.first)
        setLimit(event.rows)
        setTotalRecords(event.totalRecords)
        getnavigationList(-1, event.first, event.rows)
    }
    //Template for pagination
    const template2 = {
        layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        }
    };

    return (
        <div className="card">
            <Toast ref={toast} />
            <h4>Navigations</h4>
            <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
            <DataTable size="small" stripedRows ref={dt} value={Navigations}
                dataKey="id" className="datatable-responsive"
                responsiveLayout="scroll" >
                <Column field="name" header="Header" sortable ></Column>
                <Column field="url" header="Links" sortable ></Column>
                <Column field="parentName" header="ParentName" sortable ></Column>
                <Column field="menuOrder" header="OrderID" sortable ></Column>
                <Column body={actionBodyTemplate} exportable={false} header="Action" style={{ minWidth: '8rem' }}></Column>
            </DataTable>
            <Paginator className='justify-content-end' first={skip} rows={limit} totalRecords={totalRecords} template={template2} rowsPerPageOptions={[10, 20, 50]} onPageChange={onBasicPageChange} ></Paginator>
            <Dialog visible={NavigationDialog} draggable={false} style={{ width: '600px' }} header="Add Navigation" modal className="p-fluid" footer={NavigationDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="Navigation Name" className="mb-2 font-bold">Navigation Name</label>
                    <InputText id="name" required autoFocus className="mb-3" value={selectedNavigation.name} onChange={(e) => onInputChange(e, "name")} />
                    <label htmlFor="Navigation Link" className="mb-2 font-bold">Navigation Link</label>
                    <InputText id="name" required autoFocus className="mb-3" value={selectedNavigation.url} onChange={(e) => onInputChange(e, "url")} />
                    <label htmlFor="Order ID" className="mb-2 font-bold">Order ID</label>
                    <InputText id="name" required autoFocus className="mb-3" value={selectedNavigation.menuorder} onChange={(e) => onInputChange(e, "menuorder")} />
                    <label htmlFor="Parent Name" className="mb-2 font-bold">Parent Name</label>
                    <Dropdown value={parentValue} onChange={(e) => setParentValue(e.target.value)} optionLabel="name" options={parentName} htmlFor="Parent Name" className="mb-3"></Dropdown>
                    <label htmlFor="Select Permission Role" className="mb-2 font-bold">Select Permission Role</label>

                    {
                    Roles.map((roles) => {
                        return (
                            <div key={roles.key} className="flex align-items-center mb-3">
                            <Checkbox inputId={roles.key} name="roles" value={roles} onChange={(e)=>onRoleChange(e)} checked={selectedRoles.some((item) => item.key === roles.key)} />
                            <label htmlFor={roles.key} className="ml-2">
                                {roles.name}
                            </label>
                        </div>
                        );
                    })}


                </div>
            </Dialog>

            <Dialog visible={deleteNavigationDialog} draggable={false} style={{ width: '450px' }} header="Confirm" modal footer={deleteNavigationDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle mr-3"
                        style={{ fontSize: "2rem" }}
                    />
                    {Navigations && (
                        <span>
                            Are you sure you want to delete <b>{''}</b>?
                        </span>
                    )}
                </div>
            </Dialog>
        </div>
    )
}

export default NavigationList;
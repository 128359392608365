import React, { useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useEffect } from "react";

const QrScanner = () => {


    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader',
            {
                qrbox: {
                    width: 250,
                    height: 250
                },
                fps: 5
            });

        function success(result) {
            scanner.clear();
            window.open(result, '_self')
            window.close()
        }

        const error = (err) => {
            console.warn(err);
        }

        scanner.render(success, error);
    }, [])

    return (
        <div className="card" >
            <div id='reader'></div>
        </div>
    )
}

export default QrScanner;
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Paginator } from 'primereact/paginator';
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { InputText } from 'primereact/inputtext';
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import userDetails from "../../services/userTokenDetails";
import * as ActiveStatus from "./../../enums/ActiveStatus"

const SubMrList = (props) => {

    let emptyUser = {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        role: '',
    };

    // Hooks
    const [User, setUser] = useState(emptyUser);
    const [Roles, SetRoles] = useState([{ name: '', key: '' }])
    const [Userlist, setUserlist] = useState([]);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [userId, setUserId] = useState(null);
    const [isEnabled, setisEnabled] = useState(null);
    const [firstName, setfirstName] = useState(null);
    const [status, setstatus] = useState(null);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [company, setCompany] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [fullName, setfullName] = useState(null);

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    var usrDetails = userDetails();
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: null,
        name: null,
        contactperson: null,
        email: null,
        phone: null,
        noofdoctor: null
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        if (props?.location.state?.detail == "Addsubmr") {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Sub Mr Added', life: 3000 });
        }
        GetUsersList();
        document.documentElement.style.setProperty('overflow', 'hidden')
    }, []);


    // - All Api
    const GetUsersList = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setUserlist([]);
        axios.post('/api/User/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "FirstName",
            rolename: lazyState.current.rolenameFilter ? lazyState.current.rolenameFilter.name.trim() : null,
            UserName: lazyState.current.name ? lazyState.current.name.trim() : null,
            IsEnabled: lazyState.current.isenabledfilter ? lazyState.current.isenabledfilter.code.trim() : null,
            email: lazyState.current.email ? lazyState.current.email.trim() : null,
            phone: lazyState.current.phone ? lazyState.current.phone.trim() : null,
            company: lazyState.current.Company ? lazyState.current.Company.trim() : null,
            Parentkey: usrDetails.sub,
            Role:'ITADMIN'
        }).then(Response => {
            if (!isExportExcel) setUserlist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    Id: dataObj.id,
                    Email: dataObj.email,
                    PhoneNumber: dataObj.phoneNumber,
                    Status: dataObj.status,
                }
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    }
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        Id: dataObj.id,
                        Email: dataObj.email,
                        PhoneNumber: dataObj.phoneNumber,
                        Status: dataObj.status,
                    }
                    tempData.push(allExcelData);
                }
            }
            if (isExportExcel) {
                exportData(tempData, 'Sub Mr');
                setIsLoading(false);
                return false;
            }
            setUserlist(Response.data.data);
            setTotalRecords(Response.data.totalRecords);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const UpdateUserStatus = (id, isEnabled) => {
        setIsLoading(true);
        axios.get(`/api/User/UpdateUserStatusById/${id}?isEnabled=` + isEnabled)
            .then(res => {
                const data = res.data;
                setUser(data);
                setDeleteUserDialog(false);
                GetUsersList(isExportExcel, lazyState.current);
                // document.documentElement.style.setProperty('overflow', 'auto');
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="fullName" value={fullName} placeholder="Enter Name" style={{ width: '15rem' }}
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const EmailFilterTemplate = () => {
        return (
            <InputText id="email" value={email} placeholder="Enter Email" style={{ width: '15rem' }}
                onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => EmailFilterOnEnter(e)} />
        );
    };

    const CompanyFilterTemplate = () => {
        return (
            <InputText id="company" value={company} placeholder="Enter Company" style={{ width: '15rem' }}
                onChange={(e) => setCompany(e.target.value)} onKeyDown={(e) => CompanyFilterOnEnter(e)} />
        );
    };

    const PhoneNoFilterTemplate = () => {
        return (
            <InputText id="phone" value={phone} placeholder="Enter Contact No" style={{ width: '15rem' }}
                onChange={(e) => setPhone(e.target.value)} onKeyDown={(e) => PhoneFilterOnEnter(e)} />
        );
    };

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.name = e.target.value ? e.target.value : null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                lazyState.current.name = null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            }
        }
    };

    const EmailFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.email = e.target.value ? e.target.value : null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                lazyState.current.email = null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        }
    };

    const PhoneFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.phone = e.target.value ? e.target.value : null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                lazyState.current.phone = null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const CompanyFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.Company = e.target.value ? e.target.value : null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                lazyState.current.Company = null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const StatusFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.isenabledfilter} options={ActiveStatus.ActiveStatus} optionLabel="name" showClear onChange={(e) => { lazyState.current.isenabledfilter = (e.target.value ? e.target.value : null); GetUsersList(isExportExcel, { IsEnabled: e.value }); e.preventDefault() }} placeholder="Select One" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const RoleFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.rolenameFilter} options={Roles} optionLabel="name" showClear onChange={(e) => { lazyState.current.rolenameFilter = (e.target.value ? e.target.value : null); GetUsersList(isExportExcel, { rolename: e.value }); e.preventDefault() }} placeholder="Select One" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                {/* <AccessControl
                    allowedPermissions={["add:company"]}> */}
                <Link to={'/home/addmr'}> <Button label="Add Sub Mr"
                    icon="fa fa-plus" outlined /> </Link>
                {/* </AccessControl> */}
            </div>
        );
    };

    const confirmDeleteUser = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setIsLoading(true);
        setDeleteUserDialog(true);
        setIsLoading(false);
    };

    const hideDeleteProductDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto');
        setDeleteUserDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <AccessControl
                    allowedPermissions={["edit:user"]}>
                    {/* <Button icon="fa fa-pencil" className="p-button-rounded mr-2" onClick={() => { editUser(rowData); setrolename(rowData.roleId); setUserName(rowData.userName); setnormalizedUserName(rowData.normalizedUserName); setnormalizedemail(rowData.normalizedEmail); setpasswordhash(rowData.passwordHash) }} tooltip="Edit" tooltipOptions={{ position: 'bottom' }} outlined /> */}
                    <Button icon={rowData.isEnabled == true ? "fa fa-toggle-on" : "fa fa-toggle-off"} className="p-button-rounded mr-2" style={{ backgroundColor: '#6c757d', border: '#6c757d' }} tooltip={rowData.status == 'Active' ? 'Deactive' : 'Active'} tooltipOptions={{ position: 'bottom' }}
                        onClick={() => {
                            confirmDeleteUser(rowData); setUserId(rowData.id); setisEnabled(!rowData.isEnabled); setfirstName(rowData.firstName);
                            if (rowData.status == 'Active') {
                                setstatus('Deactive');
                            }
                            else {
                                setstatus('Active');
                            };
                        }}
                        outlined />
                </AccessControl>
            </div>
        );
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        GetUsersList(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            GetUsersList(isExportExcel, lazyState.current);
            setUserlist([]);
            setIsLoading(false);
        // }
    }

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    const ButtonMailto = (rowData) => {
        return (
            <Link className='linkcolors' id='mailto'
                to='#'
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >{rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link className='linkcolors' id='callto'
                to='#'
                onClick={(e) => {
                    window.location.href = `tel:${rowData.phoneNumber}`;
                    e.preventDefault();
                }}
            >{rowData.phoneNumber}
            </Link>
        );
    };

    const removeFilter = () => {
        lazyState.current.name = null;
        setfullName(null);
        lazyState.current.email = null;
        setEmail(null);
        lazyState.current.Company = null;
        setCompany(null);
        lazyState.current.phone = null;
        setPhone(null);
        lazyState.current.rolenameFilter = null;
        lazyState.current.isenabledfilter = null;
        GetUsersList(isExportExcel, lazyState.current);
        GetUsersList(isExportExcel, lazyState.current);
    }

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.name == null && lazyState.current.email == null && lazyState.current.Company == null && lazyState.current.phone == null && lazyState.current.rolenameFilter == null && lazyState.current.isenabledfilter == null ? true : false}
                    icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                <AccessControl
                    allowedPermissions={["export:user"]}>
                    <Button disabled={!(Userlist != null && Userlist.length > 0)} onClick={() => { GetUsersList(true); }} icon="pi pi-file-excel" className="mr-2" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        )
    }

    const deleteUserDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" autoFocus onClick={(e) => { UpdateUserStatus(userId, isEnabled); GetUsersList(isExportExcel, e) }} outlined />
            <Button label="No" icon="fa fa-times" onClick={hideDeleteProductDialog} outlined />
        </>
    );

    return (
        <AccessControl
            allowedPermissions={["list:user"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-user mr-2"></i>Sub Mr</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <Tooltip target="#viewcompany" content="View Company" />
                                    <Tooltip target="#mailto" content="Mail To" />
                                    <Tooltip target="#callto" content="Call To" />
                                    <DataTable lazy rows={lazyState.current.rows} stripedRows ref={dt} value={Userlist} size="small"
                                        filterDisplay={"row"}
                                        dataKey="id" className="datatable-responsive"
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort
                                        responsiveLayout="scroll" emptyMessage="No Users found." scrollHeight="calc(100vh - 250px)" scrollable>
                                        <Column field="fullName" sortField="FirstName" header="Name" sortable filter showFilterMenu={false} filterElement={UserFilterTemplate} headerStyle={{ minWidth: '8rem' }}></Column>
                                        <Column body={ButtonMailto} sortField="Email" header="Email" sortable headerStyle={{ minWidth: '8rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                        <Column body={ButtonPhoneto} sortField="PhoneNumber" sortable header="Contact No" headerStyle={{ minWidth: '8rem' }} filter showFilterMenu={false} filterElement={PhoneNoFilterTemplate}></Column>
                                        <Column field="status" sortField="Status" header="Status" sortable filter showFilterMenu={false} filterElement={StatusFilterTemplate} headerStyle={{ minWidth: '8rem' }}></Column>
                                        <div style={{ display: "none" }}>
                                            <Column field="isEnabled" header="isEnabled" sortable ></Column>
                                            <Column field="normalizedEmail" header="normalizedEmail" sortable ></Column>
                                            <Column field="userName" header="userName" sortable ></Column>
                                            <Column field="normalizedUserName" header="normalizedUserName" sortable ></Column>
                                            <Column field="passwordHash" header="passwordHash" sortable ></Column>
                                        </div>
                                        <Column body={actionBodyTemplate} exportable={false} header="Action" style={{ minWidth: '8rem' }}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>

                                    <Dialog visible={deleteUserDialog} draggable={false} style={{ width: '450px' }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteProductDialog}>
                                        <div className="flex align-items-center">
                                            <i
                                                className="fa fa-exclamation-triangle mr-3"
                                                style={{ fontSize: "2rem" }}
                                            />
                                            {
                                                deleteUserDialog && (
                                                    <span>
                                                        Are you sure you want to {status} user <b>{firstName}</b>?
                                                    </span>
                                                )}
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    );
};

export default SubMrList;
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from 'primereact/inputnumber';
import { Paginator } from 'primereact/paginator';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Dropdown } from 'primereact/dropdown';

const Hotel = () => {
    let emptyHotel = {
        id: null,
        name: '',
        sglRoomCapacity: 0,
        dblRoomCapacity: 0,
        createdBy: '',
        modifiedBy: ''
    };

    const toast = useRef(null);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const dt = useRef(null);
    const [hotelDialog, setHotelDialog] = useState(false);
    const [hotel, setHotel] = useState(emptyHotel);
    const [submitted, setSubmitted] = useState(false);
    const [deleteHotelDialog, setDeleteHotelDialog] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [sglRoomCapacity, setSGLRoomCapacity] = useState(false);
    const [dblRoomCapacity, setDBLRoomCapacity] = useState(false);
    const [sglroomremaining, setsglroomremaining] = useState(null);
    const [dblroomremaining, setdbltroomremaining] = useState(null);
    const [rowid, setrowid] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const UserName = localStorage.getItem('Username');
    const date = new Date();
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [hotelName, setHotelName] = useState("");
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        hotelname: null,
    }); // For Sorting And Pagination Purpose
    const [isExportExcel, setIsExportExcel] = useState(false);

    useEffect(() => {
        getHotelList();
        document.documentElement.style.setProperty('overflow', 'hidden');
    }, []);

    const getHotelList = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setSelectedHotel([]);
        axios.post('/api/hotel/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event ? event.sortField : "Name",
            HotelName: lazyState.current.hotelname ? lazyState.current.hotelname.trim() : null
        })
            .then((Response) => {
                if (!isExportExcel) setSelectedHotel([]);
                let tempData = [];
                for (let dataObj of Response.data.data) {
                    // Pass body data in excelsheets.
                    let allExcelData;
                    let excelcolumnData = {
                        // Id: dataObj.id,
                        Name: dataObj.name,
                        SGLRoomCapacity: dataObj.sglRoomCapacity,
                        DBLRoomCapacity: dataObj.dblRoomCapacity,
                        SGLRoomRemaining: dataObj.sglRoomRemaining,
                        DBLRoomRemaining: dataObj.dblRoomRemaining
                    };
                    if (selectedColumns.length > 0) {
                        for (let selectedColumn of selectedColumns) {
                            let fieldName = selectedColumn.field;
                            let headerName = selectedColumn.header;
                            excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                        };
                        tempData.push(excelcolumnData);
                    } else {
                        allExcelData = {
                            // Id: dataObj.id,
                            Name: dataObj.name,
                            SGLRoomCapacity: dataObj.sglRoomCapacity,
                            DBLRoomCapacity: dataObj.dblRoomCapacity,
                            SGLRoomRemaining: dataObj.sglRoomRemaining,
                            DBLRoomRemaining: dataObj.dblRoomRemaining
                        };
                        tempData.push(allExcelData);
                    };
                };
                if (isExportExcel) {
                    exportData(tempData, 'Hotel');
                    setIsLoading(false);
                    return false;
                };

                setTotalRecords(Response.data.totalRecords)
                setSelectedHotel(Response.data.data);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const HotelDialogFooter = (
        <React.Fragment>
            <Button label="Save" icon="fa fa-check" onClick={(e) => saveHotel(e)} outlined />
            <Button label="Cancel" icon="fa fa-times" onClick={(e) => hideDialog(e)} outlined />
        </React.Fragment>
    );

    const deleteHotelDialogFooter = (
        <React.Fragment>

            <Button label="Yes" icon="fa fa-check" onClick={(e) => deleteHotel()} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={(e) => hideDeleteHotelDialog(e)} outlined />
        </React.Fragment>
    );

    const hideDeleteHotelDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setDeleteHotelDialog(false);
    };

    const editHotel = (hotel) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        handleEdit(hotel.id);
        setHotel({ ...hotel });
        setHotelDialog(true);
    };

    const confirmDeleteHotel = (hotel) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setHotel(hotel);
        setDeleteHotelDialog(true);
    };

    const deleteHotel = (event) => {
        setIsLoading(true);
        let _hotels = selectedHotel.filter((val) => val.id !== hotel.id);
        axios.delete(`api/hotel/delete/${hotel.id}`)
            .then(res => {
                const data = res.data;
                setHotel(data);
                getHotelList(isExportExcel, lazyState.current);
            });
        setDeleteHotelDialog(false);
        setIsLoading(false);
        toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Hotel Deleted",
            life: 3000
        });
        // document.documentElement.style.setProperty('overflow', 'auto');
    };

    const actionBodyTemplate = (rowData) => {

        setrowid(rowData.id);
        return (
            <div className="actions">
                <AccessControl
                    allowedPermissions={["edit:hotel"]}>
                    <Button icon="fa fa-pencil" className="p-button-rounded mr-2" onClick={() => editHotel(rowData)} tooltip="Edit" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
                <AccessControl
                    allowedPermissions={["delete:hotel"]}>
                    <Button icon="fa fa-trash" className="p-button-rounded mr-2" onClick={() => confirmDeleteHotel(rowData)} tooltip="Delete" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
            </div>
        );
    };

    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setSubmitted(false);
        setHotelDialog(false);
        setDBLRoomCapacity(false);
        setSGLRoomCapacity(false);
    };

    const handleEdit = (id) => {
        setIsLoading(true);
        axios.get('/api/hotel/GetAsync/' + id)
            .then((Response) => {
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
            });
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getHotelList(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getHotelList(isExportExcel, lazyState.current);
            setIsLoading(false);
        // };
    };

    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    // const getAvailableRooms = (rowid) => {
    //     axios.get('/api/Participant/GetSelectedHotelAvailableRooms/' + rowid)
    //         .then((Response) => {
    //             //  setsglroomremaining(Response.data.data)
    //         })
    // }
    const saveHotel = (e) => {
        setSubmitted(true);
        if (hotel.id) {
            if (hotel.name.trim()) {
                axios.put(`api/hotel/update`, {
                    Id: hotel.id,
                    Name: hotel.name.trim(),
                    SGLRoomCapacity: hotel.sglRoomCapacity,
                    DBLRoomCapacity: hotel.dblRoomCapacity,
                    CreatedBy: CreatedBy,
                    Created: Created,
                    ModifiedBy: UserName,
                    // Modified: date
                })
                    .then(res => {
                        const data = res.data;
                        setHotel(data);
                        setHotelDialog(false);
                        getHotelList(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Hotel Updated', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: Hotel With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hotel Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
        if (hotel.id == null) {
            if (hotel.name.trim()) {
                axios.post(`api/hotel/insert`, {
                    Name: hotel.name.trim(),
                    SGLRoomCapacity: hotel.sglRoomCapacity,
                    DBLRoomCapacity: hotel.dblRoomCapacity,
                    CreatedBy: UserName,
                    // Created: date,
                })
                    .then(res => {
                        const data = res.data;
                        setHotel(data);
                        setHotelDialog(false);
                        getHotelList(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Hotel Added', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: Hotel With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hotel Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
    };

    const onInputChange = (e, name) => {
        if (name == 'name') {
            const val = e.target.value;
            let _hotels = { ...hotel };
            _hotels[`${name}`] = val;
            setHotel(_hotels);
        }
        else {
            const val = e.value;
            let _hotels = { ...hotel };
            _hotels[`${name}`] = val;
            setHotel(_hotels);
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <AccessControl
                allowedPermissions={["add:hotel"]}>
                <React.Fragment>
                    <Button
                        label="Add Hotel"
                        icon="fa fa-plus"
                        onClick={openNew}
                        outlined
                    />
                </React.Fragment>
            </AccessControl>
        );
    };

    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setHotel(emptyHotel);
        setSubmitted(false);
        setHotelDialog(true);
    };

    const HotelNameFilter = () => {
        return (
            <InputText name="hotelName" value={hotelName} placeholder="Enter Hotel Name"
                onChange={(e) => setHotelName(e.target.value)}
                onKeyDown={(e) => HotelFilteronEnter(e)} style={{ width: "250px" }}></InputText>
        );
    };

    const HotelFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.hotelname = e.target.value ? e.target.value : null
                getHotelList(isExportExcel, lazyState.current);
            } else {
                setIsLoading(true);
                lazyState.current.hotelname = null
                getHotelList(isExportExcel, lazyState.current);
            };
        };
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.hotelname == null ? true : false} type="button" icon="pi pi-filter-slash" className="mr-2" onClick={(e) => { lazyState.current.hotelname = null; setHotelName(null); getHotelList(isExportExcel, lazyState.current) }} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                <AccessControl
                    allowedPermissions={["export:hotel"]}>
                    <Button disabled={!(selectedHotel != null && selectedHotel.length > 0)} onClick={() => { getHotelList(true); }} icon="pi pi-file-excel" className="mr-2" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        )
    };

    return (

        <AccessControl
            allowedPermissions={["list:hotel"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-cog mr-2"></i>Hotels</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable size="small" stripedRows ref={dt} scrollable scrollHeight="calc(100vh - 250px)"
                                        lazy rows={lazyState.current.rows}
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort value={selectedHotel} selection={selectedHotel} onSelectionChange={(e) => setSelectedHotel(e.value)}
                                        dataKey="Id" responsiveLayout="scroll" filterDisplay={"row"}>
                                        <Column field="name" sortField="Name" header="Name" filter showFilterMenu={false} sortable style={{ minWidth: '12rem' }} filterElement={HotelNameFilter}></Column>
                                        <Column field="sglRoomCapacity" sortField="SGLRoomCapacity" header="SGL Room Capacity" sortable style={{ minWidth: '15rem' }}></Column>
                                        <Column field="dblRoomCapacity" sortField="DBLRoomCapacity" header="DBL Room Capacity" sortable style={{ minWidth: '15rem' }}></Column>
                                        <Column field="sglRoomRemaining" sortField="SGLRoomRemaining" header="SGL Room Remaining" sortable style={{ minWidth: '15rem' }}></Column>
                                        <Column field="dblRoomRemaining" sortField="DBLRoomRemaining" header="DBL Room Remaining" sortable style={{ minWidth: '15rem' }}></Column>
                                        <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>

                                    <Dialog visible={hotelDialog} draggable={false}
                                        style={{ width: '450px' }}
                                        // header={hotel.id == null ? <span><i className="fa fa-plus-square mr-2"></i>Add Hotel</span> : <span><i className="fa fa-edit mr-2"></i>Update Hotel</span>}
                                        header="Hotel Details"
                                        modal
                                        className="p-fluid"
                                        footer={HotelDialogFooter}
                                        onHide={(e) => hideDialog(e)}>
                                        <div className="field">
                                            <label id="label" htmlFor="name">Hotel Name</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText
                                                autoFocus
                                                id="name"
                                                value={hotel.name}
                                                onChange={(e) => onInputChange(e, "name")}
                                                required
                                                maxLength="50"
                                                placeholder="Enter Name"
                                                className={classNames({ "p-invalid": submitted && !hotel.name })} />
                                            {submitted && !hotel.name && <small className="p-error">Name is required.</small>}
                                        </div>
                                        <div className="field">
                                            <label id="label" htmlFor="name">SGL Room Capacity</label>
                                            {/* <label id="Mandatoryfield" >*</label> */}
                                            <InputNumber
                                                id="sglRoomCapacity"
                                                value={hotel.sglRoomCapacity}
                                                onChange={(e) => onInputChange(e, "sglRoomCapacity")}
                                                required
                                                allowEmpty={false}
                                                integeronly
                                                className={classNames({ "p-invalid": submitted && hotel.sglRoomCapacity < 0 })} />
                                            {sglRoomCapacity && (<small className="p-error">SGL room capacity is required.</small>)}
                                        </div>
                                        <div className="field">
                                            <label id="label" htmlFor="name">DBL Room Capacity</label>
                                            {/* <label id="Mandatoryfield" >*</label> */}
                                            <InputNumber
                                                id="dblRoomCapacity"
                                                value={hotel.dblRoomCapacity}
                                                onChange={(e) => onInputChange(e, "dblRoomCapacity")}
                                                required
                                                allowEmpty={false}
                                                integeronly
                                                className={classNames({ "p-invalid": submitted && !hotel.dblRoomCapacity < 0 })} />
                                            {dblRoomCapacity && (<small className="p-error">DBL room capacity is required.</small>)}
                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteHotelDialog} draggable={false}
                                        style={{ width: "450px" }}
                                        header="Confirm"
                                        modal
                                        footer={deleteHotelDialogFooter}
                                        onHide={hideDeleteHotelDialog}>
                                        <div className="confirmation-content">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                            {hotel && (<span>Are you sure you want to delete <b>{hotel.name}</b> hotel?</span>)}
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    );
}
export default Hotel;
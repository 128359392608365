import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import classNames from 'classnames';
import '../../assets/layout/styling.css';
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";

const CompanyWiseAttendees = () => {
    const [CompanyWiseAttendeesData, setCompanyWiseAttendeesData] = React.useState([]);
    const [companyName, setCompanyName] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isExportExcel, setIsExportExcel] = useState(false);


    const toast = useRef(null);
    const dt = useRef(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        Company: null
    }); // For Sorting And Pagination Purpose

    useEffect(() => {
        getCompanyWiseAttendees();
        document.documentElement.style.setProperty('overflow', 'hidden')
    }, []);

    const getCompanyWiseAttendees = (isExportExcel = false, event) => {
        setIsLoading(true);
        setCompanyWiseAttendeesData([]);
        if (!isExportExcel) setCompanyWiseAttendeesData([]);
        axios.post('/api/Report/GetAllCompanyAttendees', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event ? event.sortField : "Name",
            CompanyName: lazyState.current.Company ? lazyState.current.Company.trim() : null
        }).then(Response => {
            setTotalRecords(Response.data.totalRecords);
            setCompanyWiseAttendeesData(Response.data.data);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                let excelcolumnData = {
                    Company: dataObj.companyName,
                    Approved: dataObj.approvedstatus,
                    Rejected: dataObj.rejectedstatus,
                    Cancelled: dataObj.cancelledstatus,
                    Present: dataObj.presetattendees,
                };
                tempData.push(excelcolumnData);
            };
            if (isExportExcel) {
                exportData(tempData, 'CompanyWiseAttendee');
                setIsLoading(false);
                return false;
            };
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        })
    }

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getCompanyWiseAttendees(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getCompanyWiseAttendees(isExportExcel, lazyState.current);
            setIsLoading(false);
        // };
    };

    const CompanyFilterTemplate = () => {
        return (
            <InputText id="companyName" value={companyName} required autoFocus onChange={(e) => { setCompanyName(e.target.value); lazyState.current.Company = e.target.value }} placeholder="Enter Company Name"
                onKeyDown={(e) => CompanyFilteronEnter(e)} style={{ width: "300px" }}></InputText>
        )
    }

    const CompanyFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                getCompanyWiseAttendees(isExportExcel, lazyState.current);
                setIsLoading(false);
            }
        }
    }

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.Company == null ? true : false} type="button"
                    icon="pi pi-filter-slash" className=" mr-2 p-button-primary"
                    onClick={(e) => { lazyState.current.Company = null; setCompanyName(null); getCompanyWiseAttendees(isExportExcel, lazyState.current) }} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* <AccessControl
                    allowedPermissions={["export:batch"]}> */}
                <Button disabled={!(CompanyWiseAttendeesData != null && CompanyWiseAttendeesData.length > 0)} onClick={() => { getCompanyWiseAttendees(true, lazyState.current); }} icon="pi pi-file-excel" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                {/* </AccessControl> */}
            </div>
        )
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    return (
        <div className="trico-Grid">
            {isLoading ? <LoadingSpinner /> :
                <>
                    <div className="p-col">
                        <div className="card">
                            <h5 className="headerfont"><span>Company Wise Attendees</span></h5>
                            <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                            <DataTable size="small" stripedRows ref={dt} value={CompanyWiseAttendeesData} lazy rows={lazyState.current.rows}
                                onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                removableSort dataKey="id" className="datatable-responsive" responsiveLayout="scroll"
                                filterDisplay={"row"} scrollable scrollHeight="calc(100vh - 250px)">
                                <Column frozen field="companyName" header="Company Name" sortable headerStyle={{ width: '40%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} showClearButton></Column>
                                <Column field="approvedstatus" sortable sortField='approvedstatus' header="Approved"></Column>
                                <Column field="rejectedstatus" sortable sortField='rejectedstatus' header="Reject"></Column>
                                <Column field="cancelledstatus" sortable sortField='cancelledstatus' header="Cancelled"></Column>
                                <Column field="presetattendees" sortable sortField='presetattendees' header="Present"></Column>
                            </DataTable>
                            <Paginator className='justify-content-end' style={{ marginRight: "15px" }}
                                first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                        </div>
                    </div>

                </>
            }
        </div>


    )
}

export default CompanyWiseAttendees;
import React from "react";
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';


const NoAccess = () => {
    // const history = useHistory()

    // const goback = () => {
    //    history.push('/home/dashboard')
    // }
    return (
        <div className="card"  >
            <div className="">
                {/* <img src="/demo/images/access/logo-orange.svg" alt="Sakai logo" className="mb-5 w-6rem flex-shrink-0" /> */}
                {/* <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, rgba(247, 149, 48, 0.4) 10%, rgba(247, 149, 48, 0) 30%)' }}> */}
                <div className="w-full surface-card px-5 sm:px-8 flex flex-column align-items-center" style={{ borderRadius: '53px' }}>
                    <div className="flex justify-content-center align-items-center border-circle" style={{ height: '3.2rem', width: '3.2rem',backgroundColor:'#2A6697' }}>
                        <i className="pi pi-fw pi-exclamation-circle text-2xl text-white"></i>
                    </div>
                    <h1 className="text-900 font-bold text-5xl mb-2">Access Denied</h1>
                    <div className="text-600 mb-5">You do not have the necessary permisions.</div>
                    <img src="assets/layout/images/asset-access.svg" alt="Error" />
                    {/* <Button icon="pi pi-arrow-left" label="Go to Dashboard" text onClick={() => goback()} /> */}
                </div>
            </div>
        </div>
        //  </div>
    );
};

export default NoAccess;


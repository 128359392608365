import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Axios from "axios";
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Messages } from 'primereact/messages';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import LoadingSpinner from '../src/components/Spinner/LoadingSpinner';
import classNames from 'classnames';
import { useFormik } from 'formik';

const ResetPassword = (props) => {

    let emptyUser = {
        password: '',
        confirmPassword: '',
    };

    const [User, setUser] = useState(emptyUser);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setconfirmPassword] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const param = useParams();
    const toast = useRef(null);

    const ResetPassword = () => {
        setSubmitted(true);
        if (User.password && User.confirmPassword && User.confirmPassword == User.password) {
            if (formik.errors.password == '' || formik.errors.password == undefined) {
                Axios.post(`/api/Account/ResetPassword/${param.id}`, User).then(Response => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Your password has been reset successfully', life: 3000 });
                    history.push({ pathname: '/', state: { detail: "ResetPassword" } });
                }).catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                });
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Password must be minimum 8 characters, contain at least one number, one special character and a mixture of upper and lower case letters', sticky: true });
            }
        }
    };

    // { submitted && formik.touched.password && formik.errors.password && (<span className="flex p-invalid">{formik.errors.password}</span>) }

    const validate = (user) => {
        const errors = {}
        if (!user.password) {
            errors.password = ''
        } else if (! /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/g.test(user.password)) {
            errors.password = 'Password must be minimum 8 characters, contain at least one number, one special character and a mixture of upper and lower case letters'
        };
        return errors
    };

    const formik = useFormik({
        initialValues: {
            password: ''
        },
        validate,
        onSubmit: (user) => {
            ResetPassword(user);
        },
    });

    return (
        <div className="trico-Grid">
            <Toast ref={toast} />
            {isLoading ? <LoadingSpinner /> :
                (
                    <>
                        <div className="surface-ground" >
                            <div className="flex flex-column align-items-center justify-content-center" style={{ padding: '10rem' }} >
                                <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, rgba(42, 102, 151, 1) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                    <div className="w-full surface-card py-7 px-5 sm:px-8" style={{ borderRadius: '53px', paddingBottom: '6rem', marginTop: '0.3rem', paddingRight: '3.5rem', paddingLeft: '3.5rem' }}>
                                        <div className="text-center mb-5 mt-0">
                                            {/* <img src="assets/layout/images/Trico.png" ></img> */}
                                            <img src="assets/layout/images/tricoguru-logo-3-1.png" ></img>
                                            <h4 style={{ fontFamily: 'New Century Schoolbook, TeX Gyre Schola, serif', fontWeight: "bolder" }}>Reset Password</h4>
                                            <div className="text-900 text-3xl font-medium mb-3"></div>
                                        </div>
                                        <div className="field">

                                            {/* <label id="label" htmlFor="Password" className="block text-900 text-xl font-medium mb-2"  >Password</label> */}
                                            {/* <label id="Mandatoryfield" >*</label> */}
                                            <Password onBlur={formik.handleBlur}
                                                type="password" name="password" placeholder="Enter Password" onChange={(e) => {
                                                    // setUser(e.target.value);
                                                    User.password = e.target.value
                                                    //  setPassword(e.target.value); 
                                                    formik.handleChange(e)
                                                }} toggleMask feedback={false}
                                                className={classNames({ 'p-invalid': submitted && !User.password })} inputClassName='w-full p-3 md:w-30rem' />
                                            {/* {submitted && formik.touched.password && formik.errors.password && (<span className="flex p-invalid">{formik.errors.password}</span>)} */}
                                            {submitted && !User.password && <small className="flex p-invalid">Password is required.</small>}
                                        </div>
                                        <div className="field">
                                            {/* <label id="label" htmlFor="Confirm Password" className="block text-900 font-medium text-xl mb-2">Confirm Password</label> */}
                                            {/* <label id="Mandatoryfield" >*</label> */}
                                            <Password placeholder="Confirm Password" onChange={(e) =>
                                            // setUser(e.target.value)
                                            { User.confirmPassword = e.target.value }
                                                // setconfirmPassword(e.target.value)
                                            } toggleMask feedback={false}
                                                className={classNames({ 'p-invalid': submitted && !User.confirmPassword })} inputClassName='w-full p-3 md:w-30rem' />
                                            {submitted && !User.confirmPassword && <small className="flex p-invalid">Confirm password is required.</small>}
                                            {submitted && User.confirmPassword != '' && User.confirmPassword != User.password && <small className="flex p-invalid">Password and Confirm Password does not match.</small>}
                                        </div>

                                        <div>
                                            <Button label="Save" className="field col-12" onClick={() => ResetPassword()} outlined />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}
export default ResetPassword;
import React from 'react';

export const AppFooter = (props) => {

    return (
        <div id="footer" style={{visibility: 'hidden'}} className="layout-footer">
            <h4 className='font-bold mt-3 mr-2' style={{color:"#c20d12"}}>A Product Of </h4>
            <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="Logo" height="20" className="mr-2" />
            {/* by
            <span className="font-medium ml-2">4cpl</span> */}
        </div>
    );
}

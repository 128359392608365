import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Toolbar } from "primereact/toolbar";
import { Paginator } from 'primereact/paginator';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";

const Airport = () => {

    let emptyAirport = {
        id: null,
        name: '',
        cityId: null,
        cityName: '',
        stateId: '',
        stateName: '',
        countryId: '',
        countryName: '',
        airportCode: '',
    };

    const toast = useRef(null);
    const [selectedAirport, setSelectedAirport] = useState([]);
    const dt = useRef(null);
    const [airportDialog, setAirportDialog] = useState(false);
    const [airport, setAirport] = useState(emptyAirport);
    const [submitted, setSubmitted] = useState(false);
    const [deleteAirportDialog, setDeleteAirportDialog] = useState(false);
    const [cityList, setCityList] = useState();
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0)
    const UserName = localStorage.getItem('Username');
    const [airportName, setAirPortName] = useState("");
    const [citydropdowndata, setcitydropdowndata] = useState(null)
    const [Cityfilter, setCityfilter] = useState(null);
    const [Statefilter, setStatefilter] = useState(null);
    const [Countryfilter, setCountryfilter] = useState(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        cityfilter: null,
        statefilter: null,
        countryfilter: null,
        airportnameFilter: null,
    }); // For Sorting And Pagination Purpose
    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);

    useEffect(() => {
        getAirportList();
        getCityList();
        document.documentElement.style.setProperty('overflow', 'hidden');
    }, []);

    const getAirportList = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setSelectedAirport([]);
        axios.post('/api/Airport/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "Name",
            City: lazyState.current.cityfilter ? lazyState.current.cityfilter.trim() : null,
            State: lazyState.current.statefilter ? lazyState.current.statefilter.trim() : null,
            Country: lazyState.current.countryfilter ? lazyState.current.countryfilter.trim() : null,
            AirportName: lazyState.current.airportnameFilter ? lazyState.current.airportnameFilter.trim() : null,
        }).then((Response) => {
            if (!isExportExcel) setSelectedAirport([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    // Id: dataObj.id,
                    Name: dataObj.name,
                    AirportCode: dataObj.airportCode,
                    City: dataObj.cityName,
                    State: dataObj.stateName,
                    Country: dataObj.countryName
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        // Id: dataObj.id,
                        Name: dataObj.name,
                        AirportCode: dataObj.airportCode,
                        City: dataObj.cityName,
                        State: dataObj.stateName,
                        Country: dataObj.countryName
                    }
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'Airport');
                setIsLoading(false);
                return false;
            };

            setTotalRecords(Response.data.totalRecords);
            setSelectedAirport(Response.data.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const airportDialogFooter = (
        <React.Fragment>
            <Button label="Save" icon="fa fa-check" onClick={(e) => saveAirport(e)} outlined />
            <Button label="Cancel" icon="fa fa-times" onClick={(e) => { hideDialog(e); getAirportList(isExportExcel, lazyState.current) }} outlined />
        </React.Fragment>
    );

    const deleteAirportDialogFooter = (
        <React.Fragment>
            <Button label="Yes" icon="fa fa-check" onClick={(e) => deleteAirport()} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={(e) => hideDeleteAirportDialog(e)} outlined />
        </React.Fragment>
    );

    const hideDeleteAirportDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setDeleteAirportDialog(false);
    };

    const editAirport = (airport) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        handleEdit(airport.id);
        setAirport({ ...airport });
        setAirportDialog(true);
    };

    const confirmDeleteAirport = (airport) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setAirport(airport);
        setDeleteAirportDialog(true);
    };

    const deleteAirport = () => {
        setIsLoading(true);
        let _airports = selectedAirport.filter((val) => val.id !== airport.id);
        axios.delete(`api/airport/delete/${airport.id}`)
            .then(res => {
                const data = res.data;
                setAirport(data);
                getAirportList(isExportExcel, lazyState.current)
                setIsLoading(false);
                // document.documentElement.style.setProperty('overflow', 'auto');
            });
        setDeleteAirportDialog(false);
        toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Airport Deleted",
            life: 3000
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <React.Fragment>
                    <AccessControl
                        allowedPermissions={["edit:airport"]}>
                        <Button icon="fa fa-pencil" className="p-button-rounded mr-2" onClick={() => editAirport(rowData)} tooltip="Edit" tooltipOptions={{ position: 'bottom' }} outlined />
                    </AccessControl>
                    <AccessControl
                        allowedPermissions={["delete:airport"]}>
                        <Button icon="fa fa-trash" className="p-button-rounded mr-2" onClick={() => confirmDeleteAirport(rowData)} tooltip="Delete" tooltipOptions={{ position: 'bottom' }} outlined />
                    </AccessControl>
                </React.Fragment>
            </div>
        );
    };

    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setSubmitted(false);
        setAirportDialog(false);
        setAirport(emptyAirport);
        setcitydropdowndata(null);
    }

    const saveAirport = (e) => {
        setSubmitted(true);
        if (airport.name.trim() && airport.airportCode && airport.cityName.trim()) {
            if (airport.id) {
                axios.put(`api/airport/update`
                    , {
                        Id: airport.id,
                        Name: airport.name?.toUpperCase().trim(),
                        CityId: airport.cityId,
                        StateId: airport.stateId,
                        CountryId: airport.countryId,
                        AirportCode: airport.airportCode?.toUpperCase().trim(),
                        CreatedBy: CreatedBy?.toUpperCase(),
                        Created: Created,
                        ModifiedBy: UserName?.toUpperCase(),
                    })
                    .then(res => {
                        const data = res.data;
                        setAirport(data);
                        setAirportDialog(false);
                        setcitydropdowndata(null);
                        getAirportList(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Airport Updated', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: Airport With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Airport Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
            if (airport.id == null) {
                axios.post(`api/airport/insert`
                    , {
                        Name: airport.name?.toUpperCase().trim(),
                        CityId: airport.cityId,
                        StateId: airport.stateId,
                        CountryId: airport.countryId,
                        AirportCode: airport.airportCode?.toUpperCase().trim(),
                        CreatedBy: UserName?.toUpperCase(),
                    })
                    .then(res => {
                        const data = res.data;
                        setAirport(data);
                        setAirportDialog(false);
                        setcitydropdowndata(null);
                        getAirportList(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Airport Added', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: Airport With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Airport Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getAirportList(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getAirportList(isExportExcel, lazyState.current);
            setIsLoading(false);
        // };
    };

    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value);
        let _airport = { ...airport };
        _airport[`${name}`] = val;
        setAirport(_airport);
    };

    const onDropdownChange = (e) => {
        let _airport = { ...airport };
        _airport['cityId'] = (e.target && e.target.value.id);
        _airport['cityName'] = (e.target && e.target.value.cityName);
        _airport['stateId'] = (e.target && e.target.value.stateId);
        _airport['stateName'] = (e.target && e.target.value.stateName);
        _airport['countryId'] = (e.target && e.target.value.countryId);
        _airport['countryName'] = (e.target && e.target.value.countryName);
        setAirport(_airport);
    };

    // Get By Id for airport
    const handleEdit = (id) => {
        setIsLoading(true);
        axios.get('/api/airport/GetAsync/' + id)
            .then((Response) => {
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <AccessControl
                allowedPermissions={["add:airport"]}>
                <React.Fragment>
                    <Button
                        label="Add Airport"
                        icon="fa fa-plus"
                        onClick={openNew}
                        outlined
                    />
                </React.Fragment>
            </AccessControl>
        );
    };

    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setAirport(emptyAirport);
        setSubmitted(false);
        setAirportDialog(true);
    };

    const getCityList = () => {
        axios.post('/api/City/GetAll', {
            paginationRequired: false
        }).then(Response => {
            let allCity = Response.data.data.filter((cvalue) => {
                return cvalue.isBoarding == true
            });
            setCityList(allCity);
            getAirportList(isExportExcel, lazyState.current);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const CityTemplate = () => {
        return (
            <InputText id="city" required value={Cityfilter} placeholder="Enter City"
                onChange={(e) => setCityfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const StateTemplate = () => {
        return (
            <InputText id="state" required value={Statefilter} placeholder="Enter State"
                onChange={(e) => setStatefilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const CountryTemplate = () => {
        return (
            <InputText id="country" required value={Countryfilter} placeholder="Enter Country"
                onChange={(e) => setCountryfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const AirportNameFilterTemplate = () => {
        return (
            <InputText id="airportName" value={airportName} placeholder="Enter Airport" required autoFocus onChange={(e) => setAirPortName(e.target.value)}
                onKeyDown={(e) => FilterOnEnter(e)}></InputText>
        );
    };

    const FilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "airportName":
                        lazyState.current.airportnameFilter = e.target.value ? e.target.value : null
                        break;
                    case "city":
                        lazyState.current.cityfilter = e.target.value ? e.target.value : null
                        break;
                    case "state":
                        lazyState.current.statefilter = e.target.value ? e.target.value : null
                        break;
                    case "country":
                        lazyState.current.countryfilter = e.target.value ? e.target.value : null
                        break;
                };
                getAirportList(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "airportName":
                        lazyState.current.airportnameFilter = null
                        break;
                    case "city":
                        lazyState.current.cityfilter = null
                        break;
                    case "state":
                        lazyState.current.statefilter = null
                        break;
                    case "country":
                        lazyState.current.countryfilter = null
                        break;
                };
                getAirportList(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const removeFilter = () => {
        lazyState.current.airportnameFilter = null;
        setAirPortName(null);
        lazyState.current.cityfilter = null;
        setCityfilter(null);
        lazyState.current.statefilter = null;
        setStatefilter(null);
        lazyState.current.countryfilter = null;
        setCountryfilter(null);
        getAirportList(isExportExcel, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.airportnameFilter == null && lazyState.current.cityfilter == null && lazyState.current.statefilter == null && lazyState.current.countryfilter == null ? true : false}
                    icon="pi pi-filter-slash" className="p-button-outlined mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }}></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2"
                    onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                <AccessControl
                    allowedPermissions={["export:airport"]}>
                    <Button disabled={!(selectedAirport != null && selectedAirport.length > 0)} onClick={() => { getAirportList(true, lazyState.current); }}
                        icon="pi pi-file-excel" className="mr-2" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        );
    };

    return (
        <AccessControl
            allowedPermissions={["list:airport"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 id="headerfont"><span><i className="fa fa-solid fa-plane fa-rotate-110 mr-2"></i>Airport</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable size="small" lazy rows={lazyState.current.rows}
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort scrollHeight="calc(100vh - 250px)" scrollable
                                        stripedRows ref={dt} value={selectedAirport} selection={selectedAirport} onSelectionChange={(e) => setSelectedAirport(e.value)}
                                        dataKey="Id"
                                        responsiveLayout="scroll"
                                        filterDisplay={"row"}>
                                        {/* filterDisplay={enableFilter ? "row" : null}> */}
                                        <Column field="name" sortField="Name" sortable header="Name" style={{ minWidth: '12rem' }} filterElement={AirportNameFilterTemplate} filter showFilterMenu={false}></Column>
                                        <Column field="airportCode" sortField="AirportCode" sortable header="Code" style={{ minWidth: '12rem' }}></Column>
                                        <Column field="cityName" sortField="CityName" sortable header="City" style={{ minWidth: '16rem' }} filterElement={CityTemplate} filter showFilterMenu={false}></Column>
                                        <Column field="stateName" sortField="StateName" sortable header="State" style={{ minWidth: '16rem' }} filterElement={StateTemplate} filter showFilterMenu={false}></Column>
                                        <Column field="countryName" sortField="CountryName" sortable header="Country" style={{ minWidth: '16rem' }} filterElement={CountryTemplate} filter showFilterMenu={false}></Column>
                                        <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                                </div>

                                <Dialog visible={airportDialog} draggable={false}
                                    style={{ width: '550px' }}
                                    // header={airport.id == null ? <span><i className="fa fa-plus-square mr-2"></i>Add Airport</span> : <span><i className="fa fa-edit mr-2"></i>Update Airport</span>}
                                    header="Airport Details"
                                    modal className="p-fluid"
                                    footer={airportDialogFooter}
                                    onHide={(e) => hideDialog(e)}>
                                    <div className="field">
                                        <label id="label" htmlFor="name">Name</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText maxlength="80"
                                            id="name"
                                            value={airport.name?.toUpperCase()}
                                            onChange={(e) => onInputChange(e, "name")}
                                            required
                                            autoFocus
                                            placeholder="Enter Airport"
                                            className={classNames({ "p-invalid": submitted && !airport.name })}
                                        />
                                        {airport.id == null ?
                                            submitted && !airport.name && (
                                                <small className="p-error">Name is required.</small>
                                            )
                                            :
                                            submitted && !airport.name && (
                                                <small className="p-error">Name is required.</small>
                                            )
                                        }
                                    </div>

                                    <div className="field">
                                        <label id="label" htmlFor="name">Airport Code</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText maxlength="5"
                                            id="name"
                                            value={airport.airportCode?.toUpperCase()}
                                            onChange={(e) => onInputChange(e, "airportCode")}
                                            required
                                            placeholder="Enter Code"
                                            className={classNames({ "p-invalid": submitted && !airport.airportCode })}
                                        />
                                        {airport.id == null ?
                                            submitted && !airport.airportCode && (
                                                <small className="p-error">Airport code is required.</small>
                                            )
                                            :
                                            submitted && !airport.airportCode && (
                                                <small className="p-error">Airport code is required.</small>
                                            )
                                        }
                                    </div>

                                    <div className="field">
                                        <label id="label" htmlFor="city">City</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <Dropdown
                                            id="cityName"
                                            value={citydropdowndata}
                                            options={cityList}
                                            optionLabel="cityName"
                                            filter
                                            // placeholder={"Select City"}
                                            placeholder={airport.cityName ? airport.cityName : "Select City"}
                                            onChange={(e) => { setcitydropdowndata(e.target.value); onDropdownChange(e) }}
                                            className={classNames({ "p-invalid": submitted && !airport.cityName })} />
                                        {submitted && !airport.cityId && <small className="p-error">City is required.</small>}
                                    </div>
                                    <div className="field" >
                                        <label id="label" htmlFor="State">State</label>
                                        <InputText disabled value={airport.stateName?.toUpperCase()} placeholder="Enter State" />
                                    </div>
                                    <div className="field" >
                                        <label id="label" htmlFor="Country">Country</label>
                                        <InputText disabled value={airport.countryName?.toUpperCase()} placeholder="Enter Country" />
                                    </div>
                                </Dialog>

                                <Dialog visible={deleteAirportDialog} draggable={false}
                                    style={{ width: "450px" }}
                                    header="Confirm"
                                    modal
                                    footer={deleteAirportDialogFooter}
                                    onHide={hideDeleteAirportDialog}>
                                    <div className="confirmation-content">
                                        <i
                                            className="pi pi-exclamation-triangle mr-3"
                                            style={{ fontSize: "2rem" }}
                                        />
                                        {airport && (
                                            <span>
                                                Are you sure you want to delete <b>{airport.name}</b> airport?
                                            </span>
                                        )}
                                    </div>
                                </Dialog>

                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    );
};

export default Airport;
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Paginator } from 'primereact/paginator';
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import * as Travelmode from './../../enums/Travelmodes';
import { MultiSelect } from 'primereact/multiselect';
import exportData from "../../services/exportToExcelService";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { useHistory } from 'react-router-dom';
import userDetails from "../../services/userTokenDetails";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';

const TicketsToBeCancel = () => {

    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [id, setid] = useState(new Array());
    const [ActionDisable, setActionDisable] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selecteddata, setselecteddata] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    var company = localStorage.getItem('Company');
    var usrDetails = userDetails();
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [fullName, setfullName] = useState(null)
    const [hospitalNamefilter, sethospitalNamefilter] = useState(null);
    const [companyNameFilter, setcompanyNameFilter] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        genderfilter: null,
        toEventCityFilter: null,
        fromEventCityFilter: null,
        name: null,
        city: null,
        boardingCity: null,
        state: null,
        country: null,
        MobileNo: null,
        email: null,
        filterHospitalName: null,
        filterCompany: null,
        TricoConfirmedfilter:null
    }); // For Sorting And Pagination Purpose
    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [filterCity, setFilterCity] = useState(null);
    const [filterstate, setFilterstate] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [companyList,setCompanyList] = useState([]);
    const [tricoConfirmedList, setTricoConfirmedList] = useState([]);
    const history = useHistory();
    let orderedSelectedColumns = [];
    let unSelectedColumns = [];

    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty('overflow', 'hidden');
        getFilterListsData();
    }, [])

    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
         //Company
         var listCompanyFilterString = null;
         if (lazyState.current.CompanyFilter != null && lazyState.current.CompanyFilter != undefined && lazyState.current.CompanyFilter != '') {
             if (lazyState.current.CompanyFilter.length > 0) {
                 if (lazyState.current.CompanyFilter.length > 1) {
                     listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name).join(',');
                 } else {
                     listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name)[0];
                 }
             }
         }

         //Trico Confirmed Batch
         var listTricoConfirmedFilterString = null;
         if (lazyState.current.TricoConfirmedfilter != null && lazyState.current.TricoConfirmedfilter != undefined && lazyState.current.TricoConfirmedfilter != '') {
             if (lazyState.current.TricoConfirmedfilter.length > 0) {
                 if (lazyState.current.TricoConfirmedfilter.length > 1) {
                     listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name).join(',');
                 } else {
                     listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name)[0];
                 }
             }
         }  
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios.post('/api/Participant/GetAll', {
            TicketInFlightBookingStatus: 'TO BE CANCEL',
            TicketOutFlightBookingStatus: 'TO BE CANCEL',
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "Firstname",
            HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
            CompanyFilter: usrDetails.role === 'MR' ? company : listCompanyFilterString,
            FullName: lazyState.current.name ? lazyState.current.name.trim() : null,
            City: lazyState.current.city ? lazyState.current.city.trim() : null,
            BoardingCity: lazyState.current.boardingCity ? lazyState.current.boardingCity.trim() : null,
            State: lazyState.current.state ? lazyState.current.state.trim() : null,
            country: lazyState.current.country ? lazyState.current.country.trim() : null,
            Email: lazyState.current.email ? lazyState.current.email.trim() : null,
            MobileNo: lazyState.current.MobileNo ? lazyState.current.MobileNo.trim() : null,
            Gender: lazyState.current.genderfilter ? lazyState.current.genderfilter.name.trim() : null,
            ToEventCity: lazyState.current.toEventCityFilter ? lazyState.current.toEventCityFilter.code.trim() : null,
            SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
            FromEventCity: lazyState.current.fromEventCityFilter ? lazyState.current.fromEventCityFilter.code.trim() : null,
            TricoConfirmed: listTricoConfirmedFilterString ? listTricoConfirmedFilterString : null
        }).then(Response => {
            if (!isExportExcel) setParticipantlist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    Id: dataObj.serialNumber,
                    Name: dataObj.fullName,
                    Company: dataObj.company,
                    TricoConfirmed: dataObj.tricoConfirmed,
                    Email: dataObj.email,
                    MobileNo: dataObj.mobileNo,
                    BoardingCity: dataObj.boardingcityname,
                    INF1FlightNo: dataObj.inFlightNo1,
                        INF1Airline: dataObj.inAirlineName1,
                        INF1DepartFrom: dataObj.inDepartFrom1,
                        INF1DepartureDateTime: dataObj.inDepartureDateTime1 ? moment(dataObj.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF1ArriveAt: dataObj.inArriveAt1,
                        INF1ArrivalDateTime: dataObj.inArrivalDateTime1 ? moment(dataObj.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF2FlightNo: dataObj.inFlightNo2,
                        INF2Airline: dataObj.inAirlineName2,
                        INF2DepartFrom: dataObj.inDepartFrom2,
                        INF2DepartureDateTime: dataObj.inDepartureDateTime2 ? moment(dataObj.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF2ArriveAt: dataObj.inArriveAt2,
                        INF2ArrivalDateTime: dataObj.inArrivalDateTime2 ? moment(dataObj.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF3FlightNo: dataObj.inFlightNo3,
                        INF3Airline: dataObj.inAirlineName3,
                        INF3DepartFrom: dataObj.inDepartFrom3,
                        INF3DepartureDateTime: dataObj.inDepartureDateTime3 ? moment(dataObj.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF3ArriveAt: dataObj.inArriveAt3,
                        INF3ArrivalDateTime: dataObj.inArrivalDateTime3 ? moment(dataObj.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF4FlightNo: dataObj.inFlightNo4,
                        INF4Airline: dataObj.inAirlineName4,
                        INF4DepartFrom: dataObj.inDepartFrom4,
                        INF4DepartureDateTime: dataObj.inDepartureDateTime4 ? moment(dataObj.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        INF4ArriveAt: dataObj.inArriveAt4,
                        INF4ArrivalDateTime: dataObj.inArrivalDateTime4 ? moment(dataObj.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OUTF1FlightNo: dataObj.outFlightNo1,
                        OUTF1Airline: dataObj.outAirlineName1,
                        OUTF1DepartFrom: dataObj.outDepartFrom1,
                        OUTF1DepartureDateTime: dataObj.outDepartureDateTime1 ? moment(dataObj.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF1ArriveAt: dataObj.outArriveAt1,
                        OUTF1ArrivalDateTime: dataObj.outArrivalDateTime1 ? moment(dataObj.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2FlightNo: dataObj.outFlightNo2,
                        OUTF2Airline: dataObj.outAirlineName2,
                        OUTF2DepartFrom: dataObj.outDepartFrom2,
                        OUTF2DepartureDateTime: dataObj.outDepartureDateTime2 ? moment(dataObj.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2ArriveAt: dataObj.outArriveAt2,
                        OUTF2ArrivalDateTime: dataObj.outArrivalDateTime2 ? moment(dataObj.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3FlightNo: dataObj.outFlightNo3,
                        OUTF3Airline: dataObj.outAirlineName3,
                        OUTF3DepartFrom: dataObj.outDepartFrom3,
                        OUTF3DepartureDateTime: dataObj.outDepartureDateTime3 ? moment(dataObj.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3ArriveAt: dataObj.outArriveAt3,
                        OUTF3ArrivalDateTime: dataObj.outArrivalDateTime3 ? moment(dataObj.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4FlightNo: dataObj.outFlightNo4,
                        OUTF4Airline: dataObj.outAirlineName4,
                        OUTF4DepartFrom: dataObj.outDepartFrom4,
                        OUTF4DepartureDateTime: dataObj.outDepartureDateTime4 ? moment(dataObj.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4ArriveAt: dataObj.outArriveAt4,
                        OUTF4ArrivalDateTime: dataObj.outArrivalDateTime4 ? moment(dataObj.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",

                    // Hospital: dataObj.hospitalName,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Company: dataObj.company,
                        TricoConfirmed: dataObj.tricoConfirmed,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                        BoardingCity: dataObj.boardingcityname,
                        INF1FlightNo: dataObj.inFlightNo1,
                        INF1Airline: dataObj.inAirlineName1,
                        INF1DepartFrom: dataObj.inDepartFrom1,
                        INF1DepartureDateTime: dataObj.inDepartureDateTime1 ? moment(dataObj.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF1ArriveAt: dataObj.inArriveAt1,
                        INF1ArrivalDateTime: dataObj.inArrivalDateTime1 ? moment(dataObj.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF2FlightNo: dataObj.inFlightNo2,
                        INF2Airline: dataObj.inAirlineName2,
                        INF2DepartFrom: dataObj.inDepartFrom2,
                        INF2DepartureDateTime: dataObj.inDepartureDateTime2 ? moment(dataObj.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF2ArriveAt: dataObj.inArriveAt2,
                        INF2ArrivalDateTime: dataObj.inArrivalDateTime2 ? moment(dataObj.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF3FlightNo: dataObj.inFlightNo3,
                        INF3Airline: dataObj.inAirlineName3,
                        INF3DepartFrom: dataObj.inDepartFrom3,
                        INF3DepartureDateTime: dataObj.inDepartureDateTime3 ? moment(dataObj.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF3ArriveAt: dataObj.inArriveAt3,
                        INF3ArrivalDateTime: dataObj.inArrivalDateTime3 ? moment(dataObj.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF4FlightNo: dataObj.inFlightNo4,
                        INF4Airline: dataObj.inAirlineName4,
                        INF4DepartFrom: dataObj.inDepartFrom4,
                        INF4DepartureDateTime: dataObj.inDepartureDateTime4 ? moment(dataObj.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        INF4ArriveAt: dataObj.inArriveAt4,
                        INF4ArrivalDateTime: dataObj.inArrivalDateTime4 ? moment(dataObj.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OUTF1FlightNo: dataObj.outFlightNo1,
                        OUTF1Airline: dataObj.outAirlineName1,
                        OUTF1DepartFrom: dataObj.outDepartFrom1,
                        OUTF1DepartureDateTime: dataObj.outDepartureDateTime1 ? moment(dataObj.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF1ArriveAt: dataObj.outArriveAt1,
                        OUTF1ArrivalDateTime: dataObj.outArrivalDateTime1 ? moment(dataObj.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2FlightNo: dataObj.outFlightNo2,
                        OUTF2Airline: dataObj.outAirlineName2,
                        OUTF2DepartFrom: dataObj.outDepartFrom2,
                        OUTF2DepartureDateTime: dataObj.outDepartureDateTime2 ? moment(dataObj.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2ArriveAt: dataObj.outArriveAt2,
                        OUTF2ArrivalDateTime: dataObj.outArrivalDateTime2 ? moment(dataObj.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3FlightNo: dataObj.outFlightNo3,
                        OUTF3Airline: dataObj.outAirlineName3,
                        OUTF3DepartFrom: dataObj.outDepartFrom3,
                        OUTF3DepartureDateTime: dataObj.outDepartureDateTime3 ? moment(dataObj.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3ArriveAt: dataObj.outArriveAt3,
                        OUTF3ArrivalDateTime: dataObj.outArrivalDateTime3 ? moment(dataObj.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4FlightNo: dataObj.outFlightNo4,
                        OUTF4Airline: dataObj.outAirlineName4,
                        OUTF4DepartFrom: dataObj.outDepartFrom4,
                        OUTF4DepartureDateTime: dataObj.outDepartureDateTime4 ? moment(dataObj.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4ArriveAt: dataObj.outArriveAt4,
                        OUTF4ArrivalDateTime: dataObj.outArrivalDateTime4 ? moment(dataObj.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",

                        // Hospital: dataObj.hospitalName,
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'TicketsToBeCancel');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setParticipantlist(Response.data.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const getFilterListsData = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetAllFilterListData")
            .then((Response) => {
                const separatedLists = Response.data.reduce((acc, item) => {
                    // Create a new array for each type if it doesn't exist
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    // Push the formatted item to the respective array
                    acc[item.type].push({ name: item.name, code: item.name });
                    return acc;
                }, {});

                // Sort each list by 'name'
                Object.keys(separatedLists).forEach(type => {
                    separatedLists[type].sort((a, b) => a.name.localeCompare(b.name));
                });
                setCompanyList(separatedLists.Company || [])
                setTricoConfirmedList(separatedLists.TricoConfirmed || []); 
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const FilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.name = e.target.value ? e.target.value : null
                        break;
                    case "city":
                        lazyState.current.city = e.target.value ? e.target.value : null
                        break;
                    case "state":
                        lazyState.current.state = e.target.value ? e.target.value : null
                        break;
                    case "country":
                        lazyState.current.country = e.target.value ? e.target.value : null
                        break;
                    case "Email":
                        lazyState.current.email = e.target.value ? e.target.value : null
                        break;
                    case "MobileNo":
                        lazyState.current.MobileNo = e.target.value ? e.target.value : null
                        break;
                    case "boardingcity":
                        lazyState.current.boardingCity = e.target.value ? e.target.value : null
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                    case "TricoConfirmed":
                        lazyState.current.TricoConfirmedfilter = e.target.value ? e.target.value : null;
                        break;    
                }
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.name = null
                        break;
                    case "city":
                        lazyState.current.city = null
                        break;
                    case "state":
                        lazyState.current.state = null
                        break;
                    case "country":
                        lazyState.current.country = null
                        break;
                    case "Email":
                        lazyState.current.email = null
                        break;
                    case "MobileNo":
                        lazyState.current.MobileNo = null
                        break;
                    case "boardingcity":
                        lazyState.current.boardingCity = null
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = null
                        break;
                    case "company":
                        lazyState.current.filterCompany = null
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                    case "TricoConfirmed":
                        lazyState.current.TricoConfirmedfilter = null;
                        break;     
                }
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="fullName" required autoFocus value={fullName} placeholder="Enter FirstName"
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const EmailFilterTemplate = () => {
        return (
            <InputText id="Email" required value={filterEmail} placeholder="Enter Email"
                onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const BoardingCityFilterTemplate = () => {
        return (
            <InputText id="boardingcity" required value={filterBoardingCity} placeholder="Enter Boarding City"
                onChange={(e) => setFilterBoardingCity(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const CityFilterTemplate = () => {
        return (
            <InputText id="city" required value={filterCity} placeholder="Enter City"
                onChange={(e) => setFilterCity(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const StateFilterTemplate = () => {
        return (
            <InputText id="state" required value={filterstate} placeholder="Enter State"
                onChange={(e) => setFilterstate(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const CountryFilterTemplate = () => {
        return (
            <InputText id="country" required value={filterCountry} placeholder="Enter Country"
                onChange={(e) => setFilterCountry(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const ToEventCityFilterTemplate = () => {
        return (

            <Dropdown id="toeventcity" required value={lazyState.current.toEventCityFilter} options={Travelmode.Travelmode} optionLabel="name" placeholder="Select To Event City"
                showClear onChange={(e) => { lazyState.current.toEventCityFilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { ToEventCity: e.value }); e.preventDefault() }} />

        );
    };

    const FromEventCityFilterTemplate = () => {
        return (
            <Dropdown id="fromeventcity" required value={lazyState.current.fromEventCityFilter} options={Travelmode.Travelmode} optionLabel="name" placeholder="Select From Event City"
                showClear onChange={(e) => { lazyState.current.fromEventCityFilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { FromEventCity: e.value }); e.preventDefault() }} />
        );
    };

    const MobileFilterTemplate = () => {
        return (
            <InputText id="MobileNo" required value={filterMobileNo} placeholder="Enter Mobile No."
                onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };


    const HospitalFilterTemplate = () => {
        return (
            <InputText id="hospital" required value={filterHospitalName} placeholder="Enter Hospital"
                onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const CompanyFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.CompanyFilter}
                options={companyList}
                itemTemplate={CompanyFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => CompanyFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const CompanyFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const CompanyFilterBodyTemplate = (rowData) => {
        const company = rowData.company;
        return (
            <div className="flex align-items-center gap-2">
                <span>{company}</span>
            </div>
        );
    };

    const CompanyFilterClick = (e) => {
        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { CompanyFilter: e.value });
        e.preventDefault();
    }

    const TricoConfirmedFilterTemplate = () => {
                        return (
                            <MultiSelect
                                value={lazyState.current.TricoConfirmedfilter}
                                options={tricoConfirmedList}
                                itemTemplate={TricoConfirmedFilterItemTemplate}
                                optionLabel="name"
                                onChange={(e) => TricoConfirmedFilterClick(e)}
                                placeholder="Select Any"
                                className="p-column-filter"
                                maxSelectedLabels={1}
                                selectionLimit={3}
                                style={{ minWidth: '14rem' }}
                                filter
                            />
                        );
                    };
                
        const TricoConfirmedFilterBodyTemplate = (rowData) => {
                const tricoConfirmed = rowData.tricoConfirmed;
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{tricoConfirmed}</span>
                    </div>
                    );
                };
        const TricoConfirmedFilterItemTemplate = (option) => {
                 return (
                    <div className="flex align-items-center gap-2">
                        <span>{option.name}</span>
                    </div>
                    );
                };
                
        const TricoConfirmedFilterClick = (e) => {
                lazyState.current.TricoConfirmedfilter = e.target.value ? e.target.value : null;
                getparticipantlist(isExportExcel, { TricoConfirmed: e.value });
                e.preventDefault();
                };
    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
        setIsLoading(false);
        // };
    }

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    const exportExcel = () => {
        getparticipantlist(true);
    };

    const removeFilter = () => {
        lazyState.current.name = null;
        setfullName(null);
        lazyState.current.email = null;
        setFilterEmail(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.MobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.boardingCity = null;
        setFilterBoardingCity(null);
        lazyState.current.city = null;
        setFilterCity(null);
        lazyState.current.state = null;
        setFilterstate(null);
        lazyState.current.country = null;
        setFilterCountry(null);
        lazyState.current.genderfilter = null;
        lazyState.current.toEventCityFilter = null;
        lazyState.current.fromEventCityFilter = null;
        setSerialNumber(null);
        lazyState.current.serialnumber = null;
        lazyState.current.TricoConfirmedfilter = null;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["route:ticketstobecancel"]}>
                    <Button disabled={lazyState.current.name == null && lazyState.current.email == null && lazyState.current.filterHospitalName == null &&
                        lazyState.current.filterCompany == null && lazyState.current.MobileNo == null && lazyState.current.boardingCity == null &&
                        lazyState.current.country == null && lazyState.current.state == null && lazyState.current.city == null &&
                        lazyState.current.genderfilter == null && lazyState.current.toEventCityFilter == null && lazyState.current.serialnumber == null && lazyState.current.fromEventCityFilter == null && lazyState.current.TricoConfirmedfilter == null ? true : false}
                        icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                </AccessControl>
                <div className="my-2">{header}</div>
                <AccessControl allowedPermissions={["export:ticketstobecancel"]}>
                    <Button disabled={!(participantlist != null && participantlist.length > 0)} tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={() => { exportExcel(); }} icon="pi pi-file-excel" className="mr-2" outlined />
                </AccessControl>

            </div>
        )
    }

    //#region Method for Column toggle
    //#region Method for Column toggle
    const hidecolumns = [
        { field: "cityname", header: "City" },
        { field: "statename", header: "State" },
        { field: "countryname", header: "Country" },
        { field: "travelmode_Inbound", header: "To Event City" },
        { field: "travelmode_Outbound", header: "From Event City" },
    ];

    const sortColumn = {
        "City": 'cityname',
        "State": 'statename',
        "Country": 'countryname',
        "To Event City": 'Travelmode_Inbound',
        "From Event City": 'Travelmode_Outbound',
    };


    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        orderedSelectedColumns = hidecolumns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter(o => !selectedColumns.some(i => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map(col => {
                switch (col.header) {
                    case "City":
                        if (lazyState.current.city != null) {
                            lazyState.current.city = null;
                            isFiltered = true;
                            return setFilterCity(null);
                        };
                        break;
                    case "State":
                        if (lazyState.current.state != null) {
                            lazyState.current.state = null;
                            isFiltered = true;
                            return setFilterstate(null);
                        };
                        break;
                    case "Country":
                        if (lazyState.current.country != null) {
                            lazyState.current.country = null;
                            isFiltered = true;
                            return setFilterCountry(null);
                        };
                        break;
                    case "To Event City":
                        if (lazyState.current.toEventCityFilter != null) {
                            lazyState.current.toEventCityFilter = null;
                            isFiltered = true;
                            return lazyState.current.toEventCityFilter;
                        };
                        break;
                    case "From Event City":
                        if (lazyState.current.fromEventCityFilter != null) {
                            lazyState.current.fromEventCityFilter = null;
                            isFiltered = true;
                            return lazyState.current.fromEventCityFilter;
                        };
                        break;
                };
            });
            if (isFiltered) {
                getparticipantlist(isExportExcel, lazyState.current);
            };
        };
    };

    const hidecolumnComponents = selectedColumns.map(col => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: '15%', minWidth: '15rem' }} sortField={sortColumn[col.header]}
            filter showFilterMenu={false} filterElement={col.header == 'City' ? CityFilterTemplate : col.header == "State" ? StateFilterTemplate : col.header == "To Event City" ? ToEventCityFilterTemplate : col.header == "From Event City" ? FromEventCityFilterTemplate : CountryFilterTemplate}
        />;
    });

    const header = (
        <AccessControl allowedPermissions={["route:ticketstobecancel"]}>
            <div style={{ textAlign: 'left', marginRight: '10px' }}>
                <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: '20em' }} display="chip" />
            </div>
        </AccessControl>
    );

    //#endregion

    const ButtonMailto = (rowData) => {
        return (
            <Link className='linkcolors' id='mailto'
                to='#'
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >{rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link className='linkcolors' id='callto'
                to='#'
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >{rowData.mobileNo}
            </Link>
        );
    }

    //Row select
    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(e.data.map((i, k) => {
            return i.id
        }));
    };

    const onRowSelect = (event) => {
        setActionDisable(false);
        setid((ing) => [...ing, event.data.id])
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            };
        } else {
            var removefromarray = id.indexOf(event.data.id);
            id.splice(removefromarray, 1);
        };
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(e.data.map((i, k) => {
            return i.id
        }));
        id.splice(0, id.length);
    };

    const CancelTickets = () => {
        setIsLoading(true);
        axios.put('/api/Participant/CancelTickets?id=' + id.toString())
            .then(res => {
                getparticipantlist(isExportExcel, lazyState.current);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ticket Cancelled', life: 3000 });
                setselecteddata(null);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <Button disabled={ActionDisable} q label="Cancel Tickets" tooltip="Cancel Tickets" tooltipOptions={{ position: "bottom", className: "showtooltip" }} icon="fa fa-times" className="p-button-secondary mr-2" onClick={CancelTickets} />
        )
    };

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to='#' onClick={(e) => history.push({ pathname: '/home/viewattendeeRecord/' + rowData.id, state: { detail: "TicketToBeCancel" } })}> <span className="fa fa-solid fa-eye" id="viewattendeerecord" style={{ color: ' rgb(42, 102, 151)' }}></span></Link>
            </div>
        );
    };

    return (
        <AccessControl allowedPermissions={["route:ticketstobecancel"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-ticket mr-2"></i>Tickets to be Cancel</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <Tooltip target="#mailto" content="Mail To" />
                                    <Tooltip target="#callto" content="Call To" />
                                    <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                    <DataTable size="small" stripedRows ref={dt} value={participantlist} selectionMode={"checkbox"}
                                        selection={selecteddata} onSelectionChange={(e) => setselecteddata(e.value)} onAllRowsSelect={onAllRowSelect}
                                        onRowSelect={onRowSelect}
                                        onRowUnselect={onRowUnselect} onAllRowsUnselect={onAllRowUnSelect}
                                        dataKey="id" className="datatable-responsive" responsiveLayout="scroll" scrollable scrollHeight="calc(100vh - 250px)"
                                        lazy rows={lazyState.current.rows}
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort filterDisplay={"row"} >
                                        <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: '0%', minWidth: '2rem' }} ></Column>
                                        <Column frozen selectionMode="multiple" headerStyle={{ width: '1%', minWidth: '1rem' }}></Column>
                                        <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                        <Column frozen sortField="FullName" field="fullName" header="Name" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={UserFilterTemplate} showClearButton></Column>
                                        <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} body={CompanyFilterBodyTemplate} filterField="Company" filterMenuStyle={{ width: '14rem' }} ></Column>
                                        <Column field="tricoConfirmed" sortField="TricoConfirmed" sortable header="Trico Confirmed" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={TricoConfirmedFilterTemplate} body={TricoConfirmedFilterBodyTemplate} filterField="tricoConfirmed" filterMenuStyle={{ width: '14rem' }} ></Column>
                                        <Column body={ButtonMailto} sortField="Email" header="Email" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate} showClearButton></Column>
                                        <Column body={ButtonPhoneto} sortField="MobileNo" header="Mobile No" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={MobileFilterTemplate} showClearButton></Column>
                                        <Column field="boardingcityname" sortField="boardingcityname" header="Boarding City" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={BoardingCityFilterTemplate} showClearButton></Column>
                                        {/* <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} showClearButton></Column> */}
                                        {hidecolumnComponents}

                                        <Column field="inFlightNo1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Flight No"></Column>
                                        <Column field="inAirlineName1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Airline"></Column>
                                        <Column field="inDepartFrom1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Depart From"></Column>
                                        <Column field="inDepartureDateTime1" body={(rowData) => (<span>{rowData?.inDepartureDateTime1? moment(rowData.inDepartureDateTime1).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Departure DateTime"></Column>
                                        <Column field="inArriveAt1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Arrive At"></Column>
                                        <Column field="inArrivalDateTime1" body={(rowData) => (<span>{rowData?.inArrivalDateTime1? moment(rowData.inArrivalDateTime1).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Arrival DateTime"></Column>

                                        <Column field="inFlightNo2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Flight No"></Column>
                                        <Column field="inAirlineName2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Airline"></Column>
                                        <Column field="inDepartFrom2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Depart From"></Column>
                                        <Column field="inDepartureDateTime2" body={(rowData) => (<span>{rowData?.inDepartureDateTime2? moment(rowData.inDepartureDateTime2).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Departure DateTime"></Column>
                                        <Column field="inArriveAt2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Arrive At"></Column>
                                        <Column field="inArrivalDateTime2" body={(rowData) => (<span>{rowData?.inArrivalDateTime2? moment(rowData.inArrivalDateTime2).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Arrival DateTime"></Column>

                                        <Column field="inFlightNo3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Flight No"></Column>
                                        <Column field="inAirlineName3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Airline"></Column>
                                        <Column field="inDepartFrom3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Depart From"></Column>
                                        <Column field="inDepartureDateTime3" body={(rowData) => (<span>{rowData?.inDepartureDateTime3? moment(rowData.inDepartureDateTime3).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Departure DateTime"></Column>
                                        <Column field="inArriveAt3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Arrive At"></Column>
                                        <Column field="inArrivalDateTime3" body={(rowData) => (<span>{rowData?.inArrivalDateTime3? moment(rowData.inArrivalDateTime3).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Arrival DateTime"></Column>

                                        <Column field="inFlightNo4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Flight No"></Column>
                                        <Column field="inAirlineName4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Airline"></Column>
                                        <Column field="inDepartFrom4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Depart From"></Column>
                                        <Column field="inDepartureDateTime4" body={(rowData) => (<span>{rowData?.inDepartureDateTime4? moment(rowData.inDepartureDateTime4).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Departure DateTime"></Column>
                                        <Column field="inArriveAt4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Arrive At"></Column>
                                        <Column field="inArrivalDateTime4" body={(rowData) => (<span>{rowData?.inArrivalDateTime4? moment(rowData.inArrivalDateTime4).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Arrival DateTime"></Column>

                                        <Column field="outFlightNo1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Flight No"></Column>
                                        <Column field="outAirlineName1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Airline"></Column>
                                        <Column field="outDepartFrom1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Depart From"></Column>
                                        <Column field="outDepartureDateTime1" body={(rowData) => (<span>{rowData?.outDepartureDateTime1? moment(rowData.outDepartureDateTime1).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Departure DateTime"></Column>
                                        <Column field="outArriveAt1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Arrive At"></Column>
                                        <Column field="outArrivalDateTime1" body={(rowData) => (<span>{rowData?.outArrivalDateTime1? moment(rowData.outArrivalDateTime1).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Arrival DateTime"></Column>

                                        <Column field="outFlightNo2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Flight No"></Column>
                                        <Column field="outAirlineName2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Airline"></Column>
                                        <Column field="outDepartFrom2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Depart From"></Column>
                                        <Column field="outDepartureDateTime2" body={(rowData) => (<span>{rowData?.outDepartureDateTime2? moment(rowData.outDepartureDateTime2).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Departure DateTime"></Column>
                                        <Column field="outArriveAt2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Arrive At"></Column>
                                        <Column field="outArrivalDateTime2" body={(rowData) => (<span>{rowData?.outArrivalDateTime2? moment(rowData.outArrivalDateTime2).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Arrival DateTime"></Column>

                                        <Column field="outFlightNo3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Flight No"></Column>
                                        <Column field="outAirlineName3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Airline"></Column>
                                        <Column field="outDepartFrom3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Depart From"></Column>
                                        <Column field="outDepartureDateTime3" body={(rowData) => (<span>{rowData?.outDepartureDateTime3? moment(rowData.outDepartureDateTime3).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Departure DateTime"></Column>
                                        <Column field="outArriveAt3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Arrive At"></Column>
                                        <Column field="outArrivalDateTime3" body={(rowData) => (<span>{rowData?.outArrivalDateTime3? moment(rowData.outArrivalDateTime3).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Arrival DateTime"></Column>

                                        <Column field="outFlightNo4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Flight No"></Column>
                                        <Column field="outAirlineName4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Airline"></Column>
                                        <Column field="outDepartFrom4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Depart From"></Column>
                                        <Column field="outDepartureDateTime4" body={(rowData) => (<span>{rowData?.outDepartureDateTime4? moment(rowData.outDepartureDateTime4).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Departure DateTime"></Column>
                                        <Column field="outArriveAt4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Arrive At"></Column>
                                        <Column field="outArrivalDateTime4" body={(rowData) => (<span>{rowData?.outArrivalDateTime4? moment(rowData.outArrivalDateTime4).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Arrival DateTime"></Column>
                                        {/* <Column header="Action" headerStyle={{ width: '18%', minWidth: '18rem' }}></Column> */}
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    )
}
export default TicketsToBeCancel;
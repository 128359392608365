import React, { useState, useEffect, useRef } from "react";
import { Toast } from 'primereact/toast';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { Redirect, useHistory, useParams, useLocation } from "react-router-dom";
import { Button } from 'primereact/button';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { UniqueComponentId, classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from "primereact/calendar";
import moment from 'moment';
import { Checkbox } from "primereact/checkbox";
import '../../../src/assets/layout/styling.css';
import * as Zones from '../../enums/Zones';
import { Toolbar } from 'primereact/toolbar';
import { InputNumber } from 'primereact/inputnumber';


const AddFlight = (props) => {
    let emptyFlight =
    {
        boardingCity: null,// uuid
        boardingcityname: null,
        returnBoardingCity: null, //uuid
        returnBoardingCityname: null,
        zone: null,
        ActualTicketPrice: 0,
        ReturnActualTicketPrice: 0,
        isdirect: false,
    }

    let emptyconnectingflight = [
        {
            // id: null,
            flightNo: '',
            from: null,  // uuid
            to: null,  // uuid
            departOn: Date,
            arriveAt: Date,
            airlineName: null,  // uuid
            cityName: '',
            toName: null,  //name
            fromName: null,  //name
            departOnTime: Date,
            arriveAtTime: Date,
            airlineNameForDisplay: null,  //name
            fromairportcode: '',
            toairportcode: '',
            isReturnFlight: false,
            ticketPrice: 0
        }
    ];

    const [flight, setFlight] = useState(emptyFlight);
    const [connectingflightList, setConnectingFlightList] = useState(emptyconnectingflight);
    const toast = useRef(null);
    const [submitted, setSubmitted] = useState(false);
    const [AddFlightDialog, setAddFlightDialog] = useState(false);
    const [ViewFlightDialog, setViewFlightDialog] = useState(false);
    const [ShowFlightDetails, setShowFlightDetails] = useState(false);
    const [boardingCity, setBoardingCity] = useState();
    const [allCity, setAllCity] = useState();
    const [allAirline, setAllAirline] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const [fromDropdown, setFromDropdown] = useState(null);
    const [fromToAirport, setFromToAirport] = useState(false);
    const [departArriveDate, setDepartArriveDate] = useState(false);
    const [departArriveTime, setDepartArriveTime] = useState(false);
    const [arriveDepartTime, setArriveDepartTime] = useState(false);
    const [isReturnFlight, setisReturnFlight] = useState(false);
    const [isReturnFlightforupdate, setisReturnFlightforupdate] = useState(false);
    const [flightNo, setFlightNo] = useState(false);

    const [cityValue, setcityValue] = useState([]);

    const [lastAddedFromValue, setLastAddedFromValue] = useState([]);
    const [lastAddedToValue, setLastAddedToValue] = useState([]);
    const [lastAddedToAirporcode, setLastAddedToAirporcode] = useState([]);
    const UserName = localStorage.getItem('Username');
    const history = useHistory();
    const lastflightname = useRef();
    let formateArriveAtTime;
    let formateDepartonTime;
    const param = useParams();

    useEffect(() => {
       if(props?.location?.state?.name == "AddOnwardsFlight")
        {
            emptyconnectingflight[0].isReturnFlight =false;
        }
        else if(props?.location?.state?.name == "AddReturnFlight")
        {
            emptyconnectingflight[0].isReturnFlight =true;
            setisReturnFlight(true);
            getboardingcitybyzone(null);
        }
        else{
            emptyconnectingflight[0].isReturnFlight =false;
        }
       
        document.documentElement.style.setProperty('overflow', 'auto');
        getAirportAndAirLine();
        if (param.id) {
            getflightdetailsbyid(param.id);
        }
    }, []);


    const getflightdetailsbyid = (id) => {
        setIsLoading(true);
        axios.get('/api/Flight/GetSubFlightDetailById/' + id)
            .then((Response) => {
                console.log(Response.data);
                let _flight = { ...Response.data };
                _flight['zone'] = (Response.data[0].zone);
                _flight['boardingCity'] = (Response.data[0].boardingcityid);
                _flight['boardingcityname'] = (Response.data[0].boardingcityname);
                if (Response.data[0].isReturnFlight) {
                    _flight['ReturnActualTicketPrice'] = (Response.data[0].actualTicketPrice);
                }
                else {
                    _flight['ActualTicketPrice'] = (Response.data[0].actualTicketPrice);
                }
                setFlight(_flight);
                Response.data.forEach(element => {
                    element.departOnTime = new Date(element.departOn);
                    element.arriveAtTime = new Date(element.arriveAt);
                    element.departOn = new Date(element.departOn.split('T')[0]);
                    element.arriveAt = new Date(element.arriveAt.split('T')[0]);
                    if (Response.data[0].isReturnFlight) {
                        setisReturnFlightforupdate(true);
                    }
                    connectingflightList.push(element);
                });
                connectingflightList.shift();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const getboardingcitybyzone = (zone = null) => {
        // axios.post('/api/City/BoardingCityByZoneList'
        //     , {
        //         Zone: zone
        //     }
        // ).then(res => {
        //     const data = res.data;
        //         let city = []
        //         for (const dataObj of res.data) {
        //             city.push({ name: dataObj.name, code: dataObj.id });
        //         };
        //         setcityValue(city);
        //         setBoardingCity(data);
        //         setIsLoading(false);
        // }).catch((error) => {
        //     setIsLoading(false);
        //     toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        // });

        axios.post('/api/City/CityList'
            , {
                paginationRequired: false,
                isBoarding: true,
                Zone: zone,
                SortField: "CityName"
            }
        ).then(res => {
            const data = res.data;
            let city = [];
            let bCity = []
            for (const dataObj of res.data) {
                city.push({ name: dataObj.cityName, code: dataObj.id });
                bCity.push({ name: dataObj.cityName, id: dataObj.id });
            };
                
            setcityValue(city);
            setBoardingCity(bCity);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const getAirportAndAirLine = () => {
        // axios.get(`/api/City/BoardingCityList`)
        //     .then(res => {
        //         const data = res.data;
        //         let city = []
        //         for (const dataObj of res.data) {
        //             city.push({ name: dataObj.name, code: dataObj.id });
        //         };
        //         setcityValue(city);
        //         setBoardingCity(data);
        //         setIsLoading(false);
        //     });
        axios.post(`/api/airport/GetAll`, {
            paginationRequired: false
        })
            .then(res => {
                const data = res.data.data;
                setAllCity(data);
                setIsLoading(false);
            });
        axios.post(`/api/Airline/GetAll`, {
            paginationRequired: false
        })
            .then(res => {
                const data = res.data.data;
                setAllAirline(data);
                setIsLoading(false);
            });
    }

    const fromDropdownData = (flight) => {
        if (allCity) {
            let fromCity = allCity.filter((cvalue) => {
                if (flight.id) {
                    if (flight.boardingCity) {
                        if (typeof flight.boardingCity === 'object') {
                            return cvalue.cityId == flight.boardingCity.id;
                        } else {
                            return cvalue.cityId == flight.boardingCity;
                        }
                    }
                }
                if (flight.id == null) {
                    if (flight.boardingCity) {
                        return cvalue.cityId == flight.boardingCity.id;
                    }
                }
            })
            setFromDropdown(fromCity);
        }
    }

    //On Flight Details Change
    const onFlightDetailsChange = (e, name) => {
        let _flight = { ...flight };
        if (name == 'boardingCity') {
            _flight['boardingCity'] = (e.target && e.target.value.id);
            _flight['boardingcityname'] = (e.target && e.target.value.name);
            setFlight(_flight);
            fromDropdownData(_flight);
        }

        else if (name == 'returnBoardingCity') {
            _flight['returnBoardingCity'] = (e.target && e.target.value.id);
            _flight['returnBoardingCityname'] = (e.target && e.target.value.name);
            setFlight(_flight);
            fromDropdownData(_flight);
        }
        else {
            const val = e.target.value.name;
            if(isReturnFlight !== true)
            {
                getboardingcitybyzone(val);
            }
            _flight[`${name}`] = val;
            setFlight(_flight);
        }
    };

    // On Input Change
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || e.value || '';
        let _flight = { ...flight };
        _flight[`${name}`] = val;
        setFlight(_flight);
    };

    // On Dropdown Change
    const onDropdownChange = (e, name, flightobj) => {
        let _flight = [...connectingflightList];
        if (name === 'airlineName' || name === 'flightNo' || name === 'from' || name === 'to' || name === 'departOn' || name === 'departOnTime' || name === 'arriveAt' || name === 'arriveAtTime' || name === 'ticketPrice') {
            if (name === 'airlineName') {
                flightobj.airlineName = (e.target && e.target.value.id) || '';
                flightobj.airlineNameForDisplay = (e.target && e.target.value.name) || '';

                setConnectingFlightList(_flight);
            }

            if (name === 'from') {
                flightobj.from = (e.target && e.target.value.id) || '';
                flightobj.fromName = (e.target && e.target.value.name) || '';
                flightobj.fromairportcode = (e.target && e.target.value.airportCode) || '';
                setConnectingFlightList(_flight);
                setFromToAirport(false);
                if (flightobj.fromName == flightobj.toName) {
                    setFromToAirport(true);
                }
            }
            if (name === 'to') {
                flightobj.to = (e.target && e.target.value.id) || '';
                flightobj.toName = (e.target && e.target.value.name) || '';
                flightobj.toairportcode = (e.target && e.target.value.airportCode) || '';
                setConnectingFlightList(_flight);
                setFromToAirport(false);
                if (flightobj.fromName == flightobj.toName) {
                    setFromToAirport(true);
                }
            }
            if (name === 'flightNo') {
                const val = (e.target && e.target.value) || '' || e.value;

                flightobj.flightNo = val;
                setConnectingFlightList(_flight);
                if (flightobj.flightNo != undefined) {
                    setFlightNo(false);
                    if (flightobj.flightNo.length < 0 || flightobj.flightNo.length > 10) {
                        setFlightNo(true);
                    }
                }
            }
            if (name === 'ticketPrice') {
                const val = (e.target && e.target.value) || '' || e.value;

                flightobj.ticketPrice = val;
                setConnectingFlightList(_flight);
            }

            if (name === 'departOn' || name === 'departOnTime' || name === 'arriveAt' || name === 'arriveAtTime') {
                const val = (e.target && e.target.value) || '' || e.value;
                flightobj[`${name}`] = val;
                setConnectingFlightList(_flight);
                // To Check Validation
                if (flightobj.arriveAtTime?.name == undefined && flightobj.departOnTime?.name == undefined && flightobj.arriveAt?.name == undefined && flightobj.departOn?.name == undefined) {
                    setDepartArriveTime(false);
                    if (flightobj.arriveAt?.getDate() + "-" + flightobj.arriveAt?.getMonth() + "-" + flightobj.arriveAt?.getFullYear() == flightobj.departOn?.getDate() + "-" + flightobj.departOn?.getMonth() + "-" + flightobj.departOn?.getFullYear()) {
                        if (
                            flightobj.departOnTime?.getHours() + ":" + String(flightobj.departOnTime?.getMinutes()).padStart(2, "0") + ":" + String(flightobj.departOnTime?.getSeconds()).padStart(2, "0")
                            ==
                            flightobj.arriveAtTime?.getHours() + ":" + String(flightobj.arriveAtTime?.getMinutes()).padStart(2, "0") + ":" + String(flightobj.arriveAtTime?.getSeconds()).padStart(2, "0")) {
                            setDepartArriveTime(true);
                        }
                    }
                }
                if (flightobj.arriveAt?.name == undefined && flightobj.departOn.name == undefined) {
                    setDepartArriveDate(false);
                    let arriveAtDate = new Date(flightobj.arriveAt);
                    let departOnDate = new Date(flightobj.departOn);
                    let arriveAtDateLength = arriveAtDate?.getDate() + "-" + arriveAtDate?.getMonth() + "-" + arriveAtDate?.getFullYear();
                    let departOnDateLength = departOnDate?.getDate() + "-" + departOnDate?.getMonth() + "-" + departOnDate?.getFullYear();
                    let arriveAtDateMonth;
                    let departOnDateMonth;
                    if (arriveAtDateLength.length == 8) {
                        arriveAtDateMonth = "0" + arriveAtDate?.getDate() + "-" + "0" + arriveAtDate?.getMonth();
                    } else {
                        arriveAtDateMonth = arriveAtDate?.getDate() + "-" + arriveAtDate?.getMonth();
                    }
                    if (departOnDateLength.length == 8) {
                        departOnDateMonth = "0" + departOnDate?.getDate() + "-" + "0" + departOnDate?.getMonth();
                    } else {
                        departOnDateMonth = departOnDate?.getDate() + "-" + departOnDate?.getMonth();
                    }
                    if (arriveAtDateMonth + "-" + arriveAtDate?.getFullYear() < departOnDateMonth + "-" + departOnDate.getFullYear()) {
                        setDepartArriveDate(true);
                    }

                    if (arriveAtDateMonth + "-" + arriveAtDate?.getFullYear() == departOnDateMonth + "-" + departOnDate.getFullYear()) {
                        setArriveDepartTime(false)
                        if (flightobj.arriveAtTime?.name != 'Date' && flightobj.departOnTime?.name != 'Date') {

                            let departOnTimeData;
                            let arriveAtTimeData;

                            let departDateData;
                            let ArriveDateData;

                            let departTimeData;
                            let ArriveTimeData;

                            if (flightobj.departOnTime?.getHours() < 10) {
                                departDateData = "0" + flightobj.departOnTime?.getHours();
                            } else {
                                departDateData = flightobj.departOnTime?.getHours()
                            }

                            if (flightobj.departOnTime?.getMinutes() < 10) {
                                departTimeData = "0" + flightobj.departOnTime?.getMinutes();
                            } else {
                                departTimeData = flightobj.departOnTime?.getMinutes()
                            }

                            if (flightobj.arriveAtTime?.getHours() < 10) {
                                ArriveDateData = "0" + flightobj.arriveAtTime?.getHours();
                            } else {
                                ArriveDateData = flightobj.arriveAtTime?.getHours()
                            }

                            if (flightobj.arriveAtTime?.getMinutes() < 10) {
                                ArriveTimeData = "0" + flightobj.arriveAtTime?.getMinutes();
                            } else {
                                ArriveTimeData = flightobj.arriveAtTime?.getMinutes()
                            }

                            departOnTimeData = departDateData + ":" + departTimeData;
                            arriveAtTimeData = ArriveDateData + ":" + ArriveTimeData;

                            if (arriveAtTimeData < departOnTimeData) {
                                setArriveDepartTime(true)
                            }
                        }
                    }
                }
            }
        }
    }

    const hideDialog = () => {
        setAddFlightDialog(false);
        setSubmitted(false);
    }

    const SaveFlightDetails = () => {

        if (validatetime) {
            setShowFlightDetails(true);
            setAddFlightDialog(false);
            InsertFlight();
        }
        else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please Select Appropriate Time For Flight!', life: 3000 });
        };
    }
    const validatetime = () => {
        connectingflightList.forEach(connectingflight => {
            var arrivaldate = moment(connectingflight.arriveAt);
            var arrivaltime = moment(connectingflight.arriveAtTime);
            var departdate = moment(connectingflight.departOn);
            var departtime = moment(connectingflight.departOnTime);
            if (connectingflight.airlineName != "" && connectingflight.flightNo != "" && connectingflight.from != ""
                && connectingflight.fromairportcode != "" && connectingflight.to != "" && connectingflight.toairportcode != ""
                && departdate.isValid() && departtime.isValid() && arrivaldate.isValid() && arrivaltime.isValid()) {
                return true;
            }
            else {
                return false;
            }
        });

    }
    const FormatDepartureArrivalDate = () => {
        // For Combining Date Time
        connectingflightList.forEach((connectingflight, index) => {
            // Your existing logic here
            if (connectingflight.departOn?.name == undefined) {
                let departDate = new Date(connectingflight.departOn);
                let datetime;
                departDate = connectingflight.departOn.getFullYear() + "-" + (connectingflight.departOn.getMonth() + 1) + "-" + connectingflight.departOn.getDate();
                if (connectingflight.departOnTime != undefined) {
                    if (connectingflight.departOnTime.name != 'Date') {
                        datetime = connectingflight.departOnTime.getHours() + ":" + String(connectingflight.departOnTime.getMinutes()).padStart(2, "0") + ":" + String(connectingflight.departOnTime.getSeconds()).padStart(2, "0");
                    }
                }
                formateDepartonTime = departDate += " " + (datetime == undefined ? '' : datetime);
                connectingflight.departOn = new Date(formateDepartonTime);
            }

            if (connectingflight.departOn?.name == 'Date' && connectingflight.departOnTime?.name != 'Date') {
                let defaultDepartOnDate = new Date();
                let datetime;
                defaultDepartOnDate = defaultDepartOnDate.getFullYear() + "-" + (defaultDepartOnDate.getMonth() + 1) + "-" + defaultDepartOnDate.getDate();
                if (connectingflight.departOnTime != undefined) {
                    if (connectingflight.departOnTime.name != 'Date') {
                        datetime = connectingflight.departOnTime.getHours() + ":" + String(connectingflight.departOnTime.getMinutes()).padStart(2, "0") + ":" + String(connectingflight.departOnTime.getSeconds()).padStart(2, "0");
                    }
                }
                formateDepartonTime = defaultDepartOnDate += " " + (datetime == undefined ? '' : datetime);
                connectingflight.departOn = new Date(formateDepartonTime);
            }

            if (connectingflight.arriveAt?.name == undefined) {
                let arriveAtDate = new Date(connectingflight.arriveAt);
                let arriveAtTm;
                arriveAtDate = connectingflight.arriveAt.getFullYear() + "-" + (connectingflight.arriveAt.getMonth() + 1) + "-" + connectingflight.arriveAt.getDate();
                if (connectingflight.departOnTime != undefined) {
                    if (connectingflight.arriveAtTime.name != 'Date') {
                        arriveAtTm = connectingflight.arriveAtTime.getHours() + ":" + String(connectingflight.arriveAtTime.getMinutes()).padStart(2, "0") + ":" + String(connectingflight.arriveAtTime.getSeconds()).padStart(2, "0");
                    }
                }
                formateArriveAtTime = arriveAtDate += " " + (arriveAtTm == undefined ? '' : arriveAtTm);
                connectingflight.arriveAt = new Date(formateArriveAtTime);
            }

            if (connectingflight.arriveAt?.name == 'Date' && connectingflight.arriveAtTime?.name != 'Date') {
                let defaultArriveAtDate = new Date();
                let datetime;
                defaultArriveAtDate = defaultArriveAtDate.getFullYear() + "-" + (defaultArriveAtDate.getMonth() + 1) + "-" + defaultArriveAtDate.getDate();
                if (connectingflight.arriveAtTime != undefined) {
                    if (connectingflight.arriveAtTime.name != 'Date') {
                        datetime = connectingflight.arriveAtTime.getHours() + ":" + String(connectingflight.arriveAtTime.getMinutes()).padStart(2, "0") + ":" + String(connectingflight.arriveAtTime.getSeconds()).padStart(2, "0");
                    }
                }
                formateArriveAtTime = defaultArriveAtDate += " " + (datetime == undefined ? '' : datetime);
                connectingflight.arriveAt = new Date(formateArriveAtTime);
            }
        });
        SaveFlightDetails();
    };

    // Modals Button To Save Flight Details
    const AddFlightDetailsDialogFooter = (
        <>
            <Button label="Save" icon="fa fa-check" className="p-button-success" onClick={() => FormatDepartureArrivalDate()} />
            <Button label="Cancel" icon="fa fa-times" className="p-button-secondary" onClick={() => setAddFlightDialog(false)} />
        </>
    );

    const RemoveFlightDropdown = (i, isReturn) => {
        let formValues = connectingflightList.filter(x => x.isReturnFlight == isReturn);
        formValues.splice(i, formValues.length);
        setConnectingFlightList(formValues.concat(connectingflightList.filter(x => x.isReturnFlight == !isReturn)));
    }

    const OnAddDetails = () => {
        setLastAddedFromValue(lastAddedToValue);
        onDropdownChange(lastAddedToValue, "from");
    }

    // Insert Api For Flights
    const InsertFlight = () => {
        setSubmitted(true);
        setIsLoading(true);
        if (connectingflightList[0]?.id == null || connectingflightList[0]?.id == undefined) {
            const url = '/api/Flight/SaveFlight';
            const data = {
                connectingflight: connectingflightList,
                flight: {
                    Zone: flight.zone,
                    BoardingCity: flight.boardingCity,
                    ReturnBoardingCity: flight.returnBoardingCity,
                    ActualTicketPrice: flight.ActualTicketPrice,
                    ReturnActualTicketPrice: flight.ReturnActualTicketPrice,
                    CreatedBy: UserName,
                }
            }
            axios.post(url, data)
                .then(() => {
                    setIsLoading(false);
                    history.push('/home/flight');
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Flight Added', life: 3000 });
                }).catch((error) => {
                    setIsLoading(false);
                })
        }
        else {
            const url = '/api/Flight/UpdateFlight';
            const data = {
                connectingflight: connectingflightList,
                flight: {
                    Zone: flight.zone,
                    BoardingCity: flight.boardingCity,
                    ReturnBoardingCity: flight.returnBoardingCity,
                    ActualTicketPrice: flight.ActualTicketPrice,
                    ReturnActualTicketPrice: flight.ReturnActualTicketPrice,
                }
            }
            axios.post(url, data)
                .then(() => {
                    setIsLoading(false);
                    history.push('/home/flight');
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Flight Updated', life: 3000 });
                }).catch((error) => {
                    setIsLoading(false);
                })
        }
    }

    const AddMoreOnwardDetails = () => {
        setConnectingFlightList([...connectingflightList, { ...emptyconnectingflight[0] }]);
    };

    const AddMoreReturnDetails = () => {
        const newReturnFlight = { ...emptyconnectingflight[0] };
        newReturnFlight.isReturnFlight = true;
        setConnectingFlightList([...connectingflightList, { ...newReturnFlight }]);
    };

    const AddReturnFlight = () => {
        var len = connectingflightList.length;
        for (let index = 0; index < len; index++) {
            const newReturnFlight = { ...emptyconnectingflight[0] };
            newReturnFlight.isReturnFlight = true;
            connectingflightList.push(newReturnFlight);
        }
        setisReturnFlight(true);
        getboardingcitybyzone(null);
    };

    const RemoveReturnFlight = () => {
        setisReturnFlight(false);
        let formValues = connectingflightList.filter(x => x.isReturnFlight == true);
        formValues.splice(0, formValues.length);
        setConnectingFlightList(formValues.concat(connectingflightList.filter(x => x.isReturnFlight == false)));
    };

    //Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button outlined icon="fa fa-arrow-left" onClick={(e) => history.goBack()} tooltip="Back" tooltipOptions={{ position: 'bottom' }} />
            </div>
        );
    };

    return (
        <div className="trico-Form">
            {isLoading ? <LoadingSpinner /> :
                (
                    <>
                        <div className="p-col">
                            <div className="card" >
                                <Toast ref={toast} />
                                <h5 className="headerfont">
                                    {connectingflightList[0]?.id == null || connectingflightList[0]?.id == undefined ? "Add Flight" : "Update Flight"}
                                </h5>
                                <Toolbar className="field" left={leftToolbarTemplate}></Toolbar>
                                {/* <span onClick={() => setViewFlightDialog(true)} className="fa fa-solid fa-eye" /> */}
                                <div className="p-fluid formgrid grid">

                                    <div className="field col-12 md:col-6 sm:col-12">
                                        <label id="label" htmlFor="name">Zone</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <Dropdown filter
                                            value={flight.zone}
                                            options={Zones.Zone}
                                            optionLabel="name"
                                            placeholder={flight.zone == null ? "Select Zone" : flight.zone}
                                            onChange={(e) => onFlightDetailsChange(e, "zone")} />
                                    </div>

                                    {/* <div > */}
                                    <div className={props?.location?.state?.name == "AddConnFlight" || props?.location?.state?.name == "AddOnwardsFlight"? "field col-12 md:col-6 sm:col-12" :"hidden"}>
                                        <label id="label" htmlFor="name">Onwards Boarding City</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <Dropdown filter
                                            value={flight.boardingcityname}
                                            options={boardingCity}
                                            optionLabel="name"
                                            placeholder={flight.boardingCity == null ? "Select boarding city" : flight.boardingcityname}
                                            onChange={(e) => onFlightDetailsChange(e, "boardingCity")}
                                            className={classNames({ "p-invalid": submitted && !flight.boardingCity })} />
                                        {submitted && !flight.boardingCity && <small className="p-error">Boarding City is required.</small>}
                                    </div>
                                    {/* </div> */}

                                </div>


                                {connectingflightList.filter(x => x.isReturnFlight == false).map((flight, index) => (
                                    <>
                                        <div key={index}></div>
                                        <div className="p-fluid formgrid grid">
                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Onwards Airline Name {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Dropdown autoFocus filter
                                                    value={flight.airlineNameForDisplay}
                                                    options={allAirline}
                                                    optionLabel="name"
                                                    placeholder={flight.airlineNameForDisplay == null ? "select Airline" : flight.airlineNameForDisplay}
                                                    onChange={(e) => onDropdownChange(e, 'airlineName', flight)}
                                                    className={classNames({ "p-invalid": submitted && !flight.airlineName })} />
                                                {submitted && !flight.airlineName && <small className="p-error">Airline is required.</small>}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Onwards Flight No {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <InputText
                                                    id="name"
                                                    placeholder={flight.flightNo == '' ? "select flight no" : flight.fromName}
                                                    value={flight.flightNo}
                                                    onChange={(e) => onDropdownChange(e, "flightNo", flight)}
                                                    required
                                                    className={classNames({ "p-invalid": submitted && !flight.flightNo })} />
                                                {submitted && !flight.flightNo && <small className="p-error">Flight No is required.</small>}
                                                {flightNo && (<small className="p-error">Flight no must be greater than 0 and less then and equal to 10.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Onwards From Airport {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Dropdown filter
                                                    value={flight.fromName}
                                                    // value={lastflightname.current}
                                                    // disabled={connectingflightList.length >= 1 && (connectingflightList[0].to != null && connectingflightList[0].to != "") ? true : false}
                                                    // options={connectingflightList.length >= 1 && (connectingflightList[0].to != null && connectingflightList[0].to != "") ? allCity : fromDropdown}
                                                    options={allCity}
                                                    optionLabel="name"
                                                    placeholder={flight.from == null ? "select from Airport" : flight.fromName}
                                                    className={classNames({ "p-invalid": submitted && !flight.from })}
                                                    onChange={(e) => { onDropdownChange(e, 'from', flight); }} />
                                                {submitted && !flight.from && <small className="p-error">From airport is required.</small>}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Onwards To Airport {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Dropdown filter
                                                    value={flight.toName}
                                                    options={allCity}
                                                    optionLabel="name"
                                                    placeholder={flight.toName == null ? "Select to Airport" : flight.toName}
                                                    onChange={(e) => { onDropdownChange(e, 'to', flight); setLastAddedToValue(e); setLastAddedToAirporcode(e.value.airportCode); }}
                                                    className={classNames({ "p-invalid": submitted && !flight.to })} />
                                                {submitted && !flight.to && <small className="p-error">To airport is required.</small>}
                                                {fromToAirport && (<small className="p-error">From and To airport does not same.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Onwards Depart On Date {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Calendar
                                                    showIcon
                                                    id="time24"
                                                    value={flight.departOn}
                                                    minDate={new Date()}
                                                    placeholder={flight.id == null ? "select Depart On" : flight.departOn ? moment(flight.departOn).format('DD-MM-YYYY') : "select Depart On"}
                                                    onChange={(e) => onDropdownChange(e, "departOn", flight)}
                                                    dateFormat="dd-mm-yy" />
                                                {submitted && flight.departOn == Date.toString() && <small className="p-invalid">Please select departure date.</small>}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Onwards Depart On Time {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Calendar id="time12" value={flight.departOnTime} onChange={(e) => onDropdownChange(e, "departOnTime", flight)} timeOnly
                                                    placeholder={flight.id == null ? "select Depart On Time" : flight.departOn ? moment(flight.departOn).format('HH:mm') : "select Depart On Time"}
                                                    hourFormat="24" />
                                                {submitted && flight.departOnTime == Date && <small className="p-error">Please fill departure time.</small>}
                                                {departArriveTime && (<small className="p-error">Depart on time and Arrive at time does not same.</small>)}
                                                {arriveDepartTime && (<small className="p-error">Arrive at time not less than depart on time.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Onwards Arrive At Date {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Calendar
                                                    showIcon
                                                    id="time24"
                                                    value={flight.arriveAt}
                                                    minDate={new Date()}
                                                    placeholder={flight.id == null ? "Select Arrive at" : flight.arriveAt ? moment(flight.arriveAt).format('DD-MM-YYYY') : "Select Arrive At"}
                                                    onChange={(e) => onDropdownChange(e, "arriveAt", flight)}
                                                    dateFormat="dd-mm-yy" />
                                                {submitted && flight.arriveAt == Date && <small className="p-error">Please select arrival date.</small>}
                                                {departArriveDate && (<small className="p-error">Arrival at date not less than depart on date.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Onwards Arrive At Time {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Calendar id="time12" value={flight.arriveAtTime} onChange={(e) => onDropdownChange(e, "arriveAtTime", flight)} timeOnly
                                                    placeholder={flight.id == null ? "Select Depart On Time" : flight.arriveAt ? moment(flight.arriveAt).format('HH:mm') : "Select Depart On Time"}
                                                    hourFormat="24" />
                                                {submitted && flight.arriveAtTime == Date && <small className="p-error">Please fill arrival time.</small>}
                                                {departArriveTime && (<small className="p-error">Depart on time and Arrive At time does not same.</small>)}
                                                {arriveDepartTime && (<small className="p-error">Arrive at time not less than depart on time.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-4 sm:col-12">
                                                <label id="label" htmlFor="ticketPrice">Onwards Ticket Price {index + 1}</label>
                                                <InputNumber className="w-full" value={flight.ticketPrice} onChange={(e) => onDropdownChange(e, 'ticketPrice', flight)} keyfilter="int" required placeholder="Enter Ticket Price" allowEmpty={false} />
                                            </div>
                                            <div className="field col-12 md:col-12 sm:col-12">
                                                {/* {index > 0 && ( */}
                                                <Button className={connectingflightList[0]?.id == null || connectingflightList[0]?.id == undefined ? "" : "hidden"} type="button" onClick={() => RemoveFlightDropdown(index, false)} icon="pi pi-trash" />
                                                {/* )} */}
                                            </div>
                                        </div>
                                    </>
                                ))}
                                <div className={props?.location?.state?.name == "AddConnFlight" || props?.location?.state?.name == "AddOnwardsFlight"? "" :"hidden"}>
                                <div className={!isReturnFlightforupdate ? "field col-12 md:col-4 sm:col-12" : "hidden"}>
                                    <label id="label" htmlFor="name" style={{ marginLeft: "-5px" }}>Onwards Actual Ticket Price</label>
                                    <InputNumber className="w-full" style={{ marginLeft: "-5px" }} value={flight.ActualTicketPrice} onChange={(e) => onInputChange(e, 'ActualTicketPrice')} keyfilter="int" required placeholder="Enter Actual Ticket Price" allowEmpty={false} />
                                </div>
                                </div>
                                <div className="mt-2">
                                    <div className={props?.location?.state?.name == "AddConnFlight" || props?.location?.state?.name == "AddOnwardsFlight"? "mt-2" :"hidden"}>
                                    <Button type="button" className={connectingflightList[0]?.id == null || connectingflightList[0]?.id == undefined ? "" : "hidden"} onClick={AddMoreOnwardDetails} label="Add More Onwards Flight" />
                                    </div>
                                   
                                    <div className={props?.location?.state?.name == "AddConnFlight"? "mt-2" :  "hidden"} >
                                        <Button className={ !isReturnFlight && (connectingflightList[0]?.id == null || connectingflightList[0]?.id == undefined) ? "" : "hidden"} type="button" onClick={() => AddReturnFlight()} label="Add Return Flight" />
                                    </div>
                                    <div  className={props?.location?.state?.name == "AddConnFlight"? "mt-2" :  "hidden"}>
                                        <Button className={isReturnFlight && (connectingflightList[0]?.id == null || connectingflightList[0]?.id == undefined) ? "" : "hidden"} type="button" onClick={() => RemoveReturnFlight()} label="Remove Return Flight" />
                                    </div>
                                </div>


                                <div className={isReturnFlight ? "p-fluid formgrid grid" : "hidden"}>

                                    <div className="field col-12 md:col-6 sm:col-12">
                                        <label id="label" htmlFor="name">Return Boarding City</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <Dropdown filter
                                            value={flight.returnBoardingCityname}
                                            options={boardingCity}
                                            optionLabel="name"
                                            placeholder={flight.returnBoardingCity == null ? "Select return boarding city" : flight.returnBoardingCityname}
                                            onChange={(e) => onFlightDetailsChange(e, "returnBoardingCity")}
                                            className={classNames({ "p-invalid": submitted && !flight.returnBoardingCity })} />
                                        {submitted && !flight.returnBoardingCity && <small className="p-error">Return Boarding City is required.</small>}
                                    </div>
                                </div>


                                {connectingflightList.filter(x => x.isReturnFlight).copyWithin().map((retflight, index) => (

                                    <>
                                        <div key={index}></div>

                                        <div className="p-fluid formgrid grid">
                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Return Airline Name {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Dropdown autoFocus filter
                                                    value={retflight.airlineNameForDisplay}
                                                    options={allAirline}
                                                    optionLabel="name"
                                                    placeholder={retflight.airlineNameForDisplay == null ? "select Airline" : retflight.airlineNameForDisplay}
                                                    onChange={(e) => onDropdownChange(e, 'airlineName', retflight)}
                                                    className={classNames({ "p-invalid": submitted && !retflight.airlineName })} />
                                                {submitted && !retflight.airlineName && <small className="p-error">Airline is required.</small>}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Return Flight No {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <InputText
                                                    id="name"
                                                    placeholder={retflight.flightNo == '' ? "select flight no" : retflight.fromName}
                                                    value={retflight.flightNo}
                                                    onChange={(e) => onDropdownChange(e, "flightNo", retflight)}
                                                    required
                                                    className={classNames({ "p-invalid": submitted && !retflight.flightNo })} />
                                                {submitted && !retflight.flightNo && <small className="p-error">Flight No is required.</small>}
                                                {flightNo && (<small className="p-error">Flight no must be greater than 0 and less then and equal to 10.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Return From Airport {index + 1}</label>

                                                <label id="Mandatoryfield">*</label>
                                                <Dropdown
                                                    value={retflight.fromName} filter
                                                    // value={lastflightname.current}
                                                    // disabled={connectingflightList.length >= 1 && (connectingflightList[0].to != null && connectingflightList[0].to != "") ? true : false}
                                                    // options={connectingflightList.length >= 1 && (connectingflightList[0].to != null && connectingflightList[0].to != "") ? allCity : fromDropdown}
                                                    options={allCity}
                                                    optionLabel="name"
                                                    placeholder={retflight.from == null ? "select from Airport" : retflight.fromName}
                                                    className={classNames({ "p-invalid": submitted && !retflight.from })}
                                                    onChange={(e) => { onDropdownChange(e, 'from', retflight); }} />
                                                {submitted && !retflight.from && <small className="p-error">From airport is required.</small>}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Return To Airport {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Dropdown filter
                                                    value={retflight.toName}
                                                    options={allCity}
                                                    optionLabel="name"
                                                    placeholder={retflight.toName == null ? "Select to Airport" : retflight.toName}
                                                    onChange={(e) => { onDropdownChange(e, 'to', retflight); setLastAddedToValue(e); setLastAddedToAirporcode(e.value.airportCode); }}
                                                    className={classNames({ "p-invalid": submitted && !retflight.to })} />
                                                {submitted && !retflight.to && <small className="p-error">To airport is required.</small>}
                                                {fromToAirport && (<small className="p-error">From and To airport does not same.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Return Depart On Date {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Calendar
                                                    showIcon
                                                    id="time24"
                                                    value={retflight.departOn}
                                                    minDate={new Date()}
                                                    placeholder={retflight.id == null ? "select Depart On" : retflight.departOn ? moment(retflight.departOn).format('DD-MM-YYYY') : "select Depart On"}
                                                    onChange={(e) => onDropdownChange(e, "departOn", retflight)}
                                                    dateFormat="dd-mm-yy" />
                                                {submitted && retflight.departOn == Date.toString() && <small className="p-invalid">Please select departure date.</small>}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Return Depart On Time {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Calendar id="time12" value={retflight.departOnTime} onChange={(e) => onDropdownChange(e, "departOnTime", retflight)} timeOnly
                                                    placeholder={retflight.id == null ? "select Depart On Time" : retflight.departOn ? moment(retflight.departOn).format('HH:mm') : "select Depart On Time"}
                                                    hourFormat="24" />
                                                {submitted && retflight.departOnTime == Date && <small className="p-error">Please fill departure time.</small>}
                                                {departArriveTime && (<small className="p-error">Depart on time and Arrive at time does not same.</small>)}
                                                {arriveDepartTime && (<small className="p-error">Arrive at time not less than depart on time.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Return Arrive At {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Calendar
                                                    showIcon
                                                    id="time24"
                                                    value={retflight.arriveAt}
                                                    minDate={new Date()}
                                                    placeholder={retflight.id == null ? "Select Arrive at" : retflight.arriveAt ? moment(retflight.arriveAt).format('DD-MM-YYYY') : "Select Arrive At"}
                                                    onChange={(e) => onDropdownChange(e, "arriveAt", retflight)}
                                                    dateFormat="dd-mm-yy" />
                                                {submitted && retflight.arriveAt == Date && <small className="p-error">Please select arrival date.</small>}
                                                {departArriveDate && (<small className="p-error">Arrival at date not less than depart on date.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-6 sm:col-12">
                                                <label id="label" htmlFor="name">Return Arrive At Time {index + 1}</label>
                                                <label id="Mandatoryfield">*</label>
                                                <Calendar id="time12" value={retflight.arriveAtTime} onChange={(e) => onDropdownChange(e, "arriveAtTime", retflight)} timeOnly
                                                    placeholder={retflight.id == null ? "Select Depart On Time" : retflight.arriveAt ? moment(retflight.arriveAt).format('HH:mm') : "Select Depart On Time"}
                                                    hourFormat="24" />
                                                {submitted && retflight.arriveAtTime == Date && <small className="p-error">Please fill arrival time.</small>}
                                                {departArriveTime && (<small className="p-error">Depart on time and Arrive At time does not same.</small>)}
                                                {arriveDepartTime && (<small className="p-error">Arrive at time not less than depart on time.</small>)}
                                            </div>

                                            <div className="field col-12 md:col-4 sm:col-12">
                                                <label id="label" htmlFor="ticketPrice">Return Ticket Price {index + 1}</label>
                                                <InputNumber className="w-full" value={retflight.ticketPrice} onChange={(e) => onDropdownChange(e, 'ticketPrice', retflight)} keyfilter="int" required placeholder="Enter Ticket Price" allowEmpty={false} />
                                            </div>

                                            <div className="field col-12 md:col-12 sm:col-12">
                                                {/* {index > 0 && ( */}
                                                <Button className={connectingflightList[0]?.id == null || connectingflightList[0]?.id == undefined ? "" : "hidden"} type="button" onClick={() => RemoveFlightDropdown(connectingflightList.indexOf(retflight), true)} icon="pi pi-trash" />
                                                {/* )} */}
                                            </div>
                                        </div>
                                    </>
                                ))}
                                <div className={(isReturnFlight || isReturnFlightforupdate) ? "p-fluid formgrid grid" : "hidden"}>
                                    <div className={(isReturnFlight || isReturnFlightforupdate) ? "field col-12 md:col-4 sm:col-12" : "hidden"} >
                                        <label id="label" htmlFor="name" style={{ marginLeft: "-5px" }}>Return Actual Ticket Price</label>
                                        <InputNumber className="w-full" style={{ marginLeft: "-5px" }} value={flight.ReturnActualTicketPrice} onChange={(e) => onInputChange(e, 'ReturnActualTicketPrice')} keyfilter="int" required placeholder="Enter Actual Ticket Price" allowEmpty={false} />
                                    </div>
                                </div>

                                <div className={props?.location?.state?.name == "AddConnFlight" || props?.location?.state?.name == "AddReturnFlight"? "mt-2" :"hidden"} >
                                   {isReturnFlight}
                                    <Button className={isReturnFlight && (connectingflightList[0]?.id == null || connectingflightList[0]?.id == undefined) ? "" : "hidden"} type="button" onClick={AddMoreReturnDetails} label="Add More Return Flight" />
                                </div>
                                <div className="mt-2">
                                    <Button label="Save" icon="fa fa-check" className="p-button-success" onClick={() => FormatDepartureArrivalDate()} />
                                </div>

                                <Dialog visible={ViewFlightDialog} draggable={false} style={{ width: "1000px" }} header="View Selected Flight" modal onHide={() => setViewFlightDialog(false)} >
                                    <div>
                                        <div className='mt-3 grid' >
                                            {connectingflightList.map((item, index) => {
                                                return (
                                                    <div className={index == 0 || index == 2 ? "lg:col-6 sm:col-12 md:col-4 p-0 card grid flightOrder mr-4" : "lg:col-6 sm:col-12 md:col-4 p-0 card grid flightOrder"}>

                                                        <div class="col-fixed sm:col-12 md:col-4 lg:col-4 p-2 align-self-center align-items-center justify-content-center" style={{ lineHeight: "5rem" }}>{index + 1}</div>
                                                        <div class="col grid p-0 m-0">
                                                            <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                <div className="text-center line-height-4">
                                                                    <label className="text-2xl font-medium">{moment(item.departOnTime).format('HH:mm')}</label>
                                                                </div>
                                                                <div className="text-center">
                                                                    <label className="text-xl">{moment(item.departOn).format('DD/MM/YYYY')}</label>
                                                                </div>
                                                                <div className="text-center line-height-4">
                                                                    <label className="text-4xl font-medium" data-toggle="tooltip" data-placement="left" title={item.fromairportcode}>{item.fromairportcode}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                <div className="text-center line-height-3">
                                                                    <label className="text-4xl font-medium">{item.airlineNameForDisplay}</label>
                                                                </div>
                                                                <hr class="hr-flight-icon"></hr>
                                                                <div className="text-center line-height-3">
                                                                    <label className="text-lg font-medium">{item.airlineNameForDisplay}</label>
                                                                </div>
                                                                <div className="text-center line-height-3">
                                                                    <label className="text-xl">{item.flightNo}</label>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                <div className="text-center line-height-4">
                                                                    <label className="text-2xl font-medium">{moment(item.arriveAtTime).format('HH:mm')}</label>
                                                                    {/* <i style={{ color: 'red', fontSize: "x-large" }} className="fa fa-solid fa-times" severity="danger" onClick={() => RemoveFlightDropdown(index)}></i> */}
                                                                </div>
                                                                <div className="text-center">
                                                                    <label className="text-xl">{moment(item.arriveAt).format('DD/MM/YYYY')}</label>
                                                                </div>
                                                                <div className="text-center line-height-3">
                                                                    <label className="text-4xl font-medium" data-toggle="tooltip" data-placement="left" title={item.toairportcode}>{item.toairportcode}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Dialog>

                            </div>

                        </div>
                    </>
                )
            }
        </div>
    );
}
export default AddFlight;
import React, { useState, useRef,useEffect } from "react";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Button } from "primereact/button";
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import 'font-awesome/css/font-awesome.min.css';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory, useParams, useLocation } from "react-router-dom";
import userDetails from "../../services/userTokenDetails";

const AddSubMr = () => {

    let emptyUser = {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        role: '',
    };

    const [User, setUser] = useState(emptyUser);
    const [Roles, SetRoles] = useState([{ name: '', key: '' }])
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [userName, setUserName] = useState(null);
    const [normalizeduserName, setnormalizedUserName] = useState(null);
    const [normalizedemail, setnormalizedemail] = useState(null);
    const [passwordhash, setpasswordhash] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const history = useHistory();
    const toast = useRef(null);
    var usrDetails = userDetails();
    const CompanyId = localStorage.getItem('CompanyId')


    // On Screen Load
    useEffect(() => {
        document.documentElement.style.setProperty('overflow', 'auto');
    }, []);

    const saveUser = () => {
        setSubmitted(true);
        if (User.id && User.firstName.trim() && User.lastName.trim() && User.phoneNumber && User.email.trim() &&
            (formik.errors.email == '' || formik.errors.email == undefined) && (formik.errors.phoneNumber == '' || formik.errors.phoneNumber == undefined)
            && (formik.errors.password == '' || formik.errors.password == undefined)) {
            setIsLoading(true);
            axios.put(`api/User/update`, {
                id: User.id,
                firstName: User.firstName,
                lastName: User.lastName,
                email: User.email,
                phoneNumber: User.phoneNumber,
                password: User.password,
                confirmPassword: User.confirmPassword,
                role: selectedRoles.name,
                userName: userName,
                normalizedEmail: normalizedemail,
                normalizedUserName: normalizeduserName,
                passwordHash: passwordhash,
                isEnabled: User.isEnabled
            })
                .then(res => {
                    setUser(emptyUser);
                    setSelectedRoles([]);
                    SetRoles([]);
                    setIsLoading(false);
                });
        };
        if (User.id == null && User.firstName.trim() && User.lastName.trim() && User.phoneNumber && User.email.trim() &&
            (formik.errors.email == '' || formik.errors.email == undefined) && (formik.errors.phoneNumber == '' || formik.errors.phoneNumber == undefined)
            && (formik.errors.password == '' || formik.errors.password == undefined) && User.confirmPassword == User.password) {
            axios.post(`/api/Account/registerbyadmin`, {
                firstName: User.firstName,
                lastName: User.lastName,
                email: User.email,
                phoneNumber: User.phoneNumber,
                password: User.password,
                confirmPassword: User.confirmPassword,
                role: "SUBMR",
                Parentkey: usrDetails.sub,
                company: CompanyId ? CompanyId : null,
            }
            )
                .then(res => {
                    history.push({ pathname: '/home/submr', state: { detail: "Addsubmr" } });
                    setIsLoading(false);
                }).catch((error) => {
                    if (error.response.data[0].code == "DuplicateUserName") {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'SubMr Already Exist', life: 3000 });
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                    };
                });
        };
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...User };
        _user[`${name}`] = val;
        setUser(_user);
    };

    const validate = (user) => {
        const errors = {}
        if (!user.email) {
            errors.email = ''
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)) {
            errors.email = 'Invalid email address  '
        };
        if (!user.phoneNumber) {
            errors.phoneNumber = ''
        } else if (! /^(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(user.phoneNumber)) {
            errors.phoneNumber = 'Invalid phone number'
        };
        if (!user.password) {
            errors.password = ''
        } else if (! /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/g.test(user.password)) {
            errors.password = 'Password must be minimum 8 characters, contain at least one number, one special character and a mixture of upper and lower case letters'
        };
        return errors
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            phoneNumber: '',
            password: ''
        },
        validate,
        onSubmit: (user) => {
            saveUser(user);
        },
    });

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button severity="secondary" outlined icon="fa fa-arrow-left" className="p-button-secondary" tooltip="Back" tooltipOptions={{ position: 'bottom' }} onClick={(e) => history.goBack()} />
                <i class="pi pi-bars p-toolbar-separator"></i>
                <Button label="Save" icon="fa fa-save" onClick={() => { saveUser() }} outlined />
            </div>
        );
    };

    return (
        <div className="trico-Grid">
            <Toast ref={toast} />
            {isLoading ? <LoadingSpinner /> :
                (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont"><span><i class="fa fa-solid fa-user mr-2"></i>Add Sub Mr</span></h5>
                                <Toolbar left={leftToolbarTemplate} className="mb-3"></Toolbar>
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 md:col-12 sm:col-12">
                                        <label id="label" htmlFor="FirstName" className="mb-1 font-bold">First Name</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText autoFocus id="FirstName" required value={User.firstName} placeholder="Enter FirstName"
                                            onChange={(e) => onInputChange(e, "firstName")}
                                            maxlength="20"
                                            className={classNames({ 'p-invalid': submitted && !User.firstName })} />
                                        {submitted && !User.firstName && <small className="p-invalid">Firstname is required.</small>}
                                    </div>

                                    <div className="field col-12 md:col-12 sm:col-12">
                                        <label id="label" htmlFor="LastName" className="mb-1 font-bold">Last Name</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText id="LastName" required value={User.lastName} placeholder="Enter LastName"
                                            onChange={(e) => onInputChange(e, "lastName")}
                                            maxlength="20"
                                            className={classNames({ 'p-invalid': submitted && !User.lastName })} />
                                        {submitted && !User.lastName && <small className="p-invalid">Lastname is required.</small>}
                                    </div>

                                    <div className="field col-12 md:col-12 sm:col-12">
                                        <label id="label" className="mb-1" htmlFor="Email">Email</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText disabled={User.id == null ? false : true} value={User.email} onBlur={formik.handleBlur} type="email" name="email"
                                            required placeholder="Enter Email" onChange={(e) => { onInputChange(e, 'email'); formik.handleChange(e) }}
                                            className={classNames({ 'p-invalid': submitted && !User.email })} />
                                        {submitted && User.email && formik.touched.email && formik.errors.email && (<span className="p-invalid">{formik.errors.email}</span>)}
                                        {submitted && !User.email && <small className="p-invalid">Email is required.</small>}
                                    </div >

                                    <div className="field col-12 md:col-12 sm:col-12"  >
                                        <label id="label" htmlFor="Company" className={User.companyName == null ? "hidden" : "mb-1 font-bold"}>Company</label>
                                        <InputText id="Company" className={User.companyName == null ? "hidden" : classNames({ "mb-2": submitted })} required value={User.companyName} disabled={User.companyName != null ? true : false}
                                            onChange={(e) => onInputChange(e, "Company")} />
                                    </div>

                                    <div className="field col-12 md:col-12 sm:col-12">
                                        <label id="label" htmlFor="PhoneNumber" className="mb-1 font-bold">Phone Number</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <InputText value={User.phoneNumber} onBlur={formik.handleBlur} name="phoneNumber"
                                            onChange={(e) => { onInputChange(e, 'phoneNumber'); formik.handleChange(e) }} maxlength="20"
                                            required placeholder="Enter PhoneNumber"
                                            className={classNames({ 'p-invalid': submitted && !User.phoneNumber })} />
                                        {submitted && formik.touched.phoneNumber && formik.errors.phoneNumber && (<span className="p-invalid">{formik.errors.phoneNumber}</span>)}
                                        {submitted && !User.phoneNumber && <small className="p-invalid">Phonenumber is required.</small>}
                                    </div>

                                    <div className={User.id == null ? 'field col-12 md:col-12 sm:col-12' : "hidden"}>
                                        <label id="label" htmlFor="Password" className="mb-1 font-bold"  >Password</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <Password value={User.password} onBlur={formik.handleBlur}
                                            type="password" name="password" placeholder="Enter Password" onChange={(e) => { onInputChange(e, "password"); formik.handleChange(e) }} toggleMask feedback={false}
                                            className={classNames({ 'p-invalid': submitted && !User.password })} />
                                        {submitted && formik.touched.password && formik.errors.password && (<span className="p-invalid">{formik.errors.password}</span>)}
                                        {submitted && !User.password && <small className="p-invalid">Password is required.</small>}
                                    </div>
                                    <div className={User.id == null ? 'field col-12 md:col-12 sm:col-12' : "hidden"}>
                                        <label id="label" htmlFor="Confirm Password" className="mb-1 font-bold">Confirm Password</label>
                                        <label id="Mandatoryfield" >*</label>
                                        <Password value={User.confirmPassword} placeholder="Confirm Password" onChange={(e) => onInputChange(e, "confirmPassword")} toggleMask feedback={false}
                                            className={classNames({ 'p-invalid': submitted && !User.confirmPassword })} />
                                        {submitted && !User.confirmPassword && <small className="p-invalid">Confirm password is required.</small>}
                                        {submitted && User.confirmPassword != '' && User.confirmPassword != User.password && <small className="p-invalid">Password and Confirm Password does not match.</small>}
                                    </div>
                                </div>
                                <Toolbar className="field" left={leftToolbarTemplate}></Toolbar>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
}

export default AddSubMr;
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';
import PrimeReact from 'primereact/api';
import axios from 'axios';
import { Tooltip } from 'primereact/tooltip';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import { string } from 'yup';
// import Airline from './components/Airline';
import 'font-awesome/css/font-awesome.min.css';
// import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Token } from 'prismjs';
import Doctorview from './components/Admin/DoctorView';
import BookedTickets from './components/Ticket Booking/BookedTickets';
import ApproveToSendTravelAgent from './components/Ticket Booking/ApproveToSendTravelAgent';
import CancelledTickets from './components/Ticket Booking/CancelledTickets';
import ApproveToSendParticipant from './components/Ticket Booking/ApproveToSendParticipant';
import Country from './components/Admin/Country';
import State from './components/Admin/State';
import City from './components/Admin/City';
import Airline from './components/Flight/Airline';
import Company from './components/Company/Company';
import RolesList from './components/Admin/Roles';
import NavigationList from './components/Admin/Navigation';
import Users from './components/Admin/User';
import Flight from './components/Flight/Flight';
import Hotel from './components/Admin/Hotel';
import Airport from './components/Flight/Airport';
import Addcompany from './components/Company/AddCompany';
import AddParticipant from './components/Attendees/AddParticipant';
import ViewAllAttendees from './components/Attendees/ViewAllAttendees';
import ViewNewAttendees from './components/Attendees/ViewNewAttendees';
import ApprovedAttendees from './components/Attendees/ApprovedAttendees';
import AttendeesonHold from './components/Attendees/AttendeesOnHold';
import RejectedAttendees from './components/Attendees/RejectedAttendees';
import Resubmission from './components/Attendees/Resubmission';
import CancelledAttendees from './components/Attendees/CancelledAttendees';
import Dashboard from './components/Dashboard/Dashboard';
import DashboardAdmin from './components/Dashboard/DashboardAdmin';
import AttendeesDirectlyRegisterd from './components/Attendees/AttendeesDirectlyRegistered';
import BookTickets from './components/Ticket Booking/BookTickets';
import TicketsToBeCancel from './components/Ticket Booking/TicketsToBeCancel';
import TicketsToBeRebook from './components/Ticket Booking/TicketsToBeRebook';
import TravelSummary from './components/Travel/TravelSummary';
import CheckInCheckOut from './components/AccomodationSummary/CheckInCheckOut';
import Batch from './components/Admin/Batch';
import CompanyWiseAttendees from './components/Reports/CompanyWiseAttendees'
import AttendeesList from './components/Attendees/AttendeesList';
import ArrivalSummary from './components/Travel/ArrivalSummary';
import HotelAllocation from './components/AccomodationSummary/HotelAllocation';
import userDetails from "./services/userTokenDetails";
import menuDetails from "./services/menuTokenDetails";
import ICardDetails from "./components/DayOfEvent/ICardDetails";
import Merchandise from "./components/DayOfEvent/Merchandise";
import CertificateGiven from "./components/DayOfEvent/CertificateGiven";
import AttendanceSummary from "./components/DayOfEvent/AttendanceSummary";
import QrScan from "./components/QrCode/QrScan";
import AbsentSummary from './components/DayOfEvent/AbsentSummary';
import SubMrList from './components/SubMr/SubMrList';
import AddSubMr from './components/SubMr/AddSubMr';
import SentTickets from './components/Ticket Booking/SentTickets';
import ViewAttedeeRecord from './components/Attendees/ViewAttedeeRecord';
import ResetPassword from './ResetPassword';
import CancelledSummary from './components/Travel/CancelledSummary';
import QrScanner from './components/QrCode/QrScanner';
import AddFlight from './components/Flight/AddFlight';
import EventClosed from './EventClosed';
import PresentSummary from './components/DayOfEvent/PresentSummary';
import ViewAttendeeStatus from './components/Attendees/ViewAttendeeStatus';
import AbsentSummaryDay2 from './components/DayOfEvent/AbsentSummaryDay-2';
import PresentSummaryDay2 from './components/DayOfEvent/PresentSummaryDay-2';
import BothDaysPresent from './components/DayOfEvent/BothDaysPresent';
import Day1PresentDay2Absent from './components/DayOfEvent/Day1PresentDay2Absent';
import Day1AbsentDay2Present from './components/DayOfEvent/Day1AbsentDay2Present';


const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [parentMenu, setMenu] = useState([]);
    const copyTooltipRef = useRef();
    const location = useLocation();
    var token = localStorage.getItem('access_token');
    var menu = [];
    var usrDetails = userDetails();
    var menuDetail = menuDetails();
    const [showTopBtn, setShowTopBtn] = useState(false);

    PrimeReact.ripple = true;
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    useEffect(() => {
        setStaticMenuInactive(true)
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (usrDetails != null) {
            convertMenu(JSON.parse(menuDetail.menu));
            if (usrDetails.role == 'DOCTOR' || usrDetails.role == null) {
                setStaticMenuInactive(true)
            }
        }
        else {
            setStaticMenuInactive(true);
        }
    }, []);

    useEffect(() => {
        const handleScrollButtonVisiblity = () => {
            window.pageYOffset > 100 ? setShowTopBtn(true) : setShowTopBtn(false);
        };

        window.addEventListener('scroll', handleScrollButtonVisiblity);

        return () => {
            window.removeEventListener('scroll', handleScrollButtonVisiblity);
        }
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }
    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }
    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }
    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }
    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }
    const onToggleMenuClick = (event) => {
        menuClick = true;
        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setMobileMenuActive(false);
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {

            setMobileMenuActive((prevState) => !prevState);
        }
    }
    const onSidebarClick = () => {
        menuClick = true;
    }
    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }
    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }
    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        onToggleMenuClick(event)
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }


    const convertMenu = (menus) => {
        if (menus.length > 0) {
            let navTypeMenu = {};
            navTypeMenu.items = [];
            for (var i = 0; i < menus.length; i++) {
                if (menus[i].children != null) {
                    let navTypeSubMenu = {};
                    navTypeSubMenu.label = menus[i].name.trim();
                    navTypeSubMenu.icon = menus[i].icon.trim();
                    navTypeSubMenu.items = [];
                    for (var j = 0; j < menus[i].children.length; j++) {
                        let navTypeSubItemMenu = {};
                        navTypeSubItemMenu.label = menus[i].children[j].name.trim();
                        navTypeSubItemMenu.to = menus[i].children[j].url.trim();
                        navTypeSubItemMenu.icon = menus[i].children[j].icon.trim();
                        navTypeSubMenu.items.push(navTypeSubItemMenu);
                    }
                    navTypeMenu.items.push(navTypeSubMenu);
                }
                else {
                    let navTypeSubMenu = {};
                    navTypeSubMenu.label = menus[i].name.trim();
                    navTypeSubMenu.to = menus[i].url.trim();
                    navTypeSubMenu.icon = menus[i].icon.trim();
                    navTypeMenu.items.push(navTypeSubMenu);
                }
            }
            menu.push(navTypeMenu);
            setMenu(menu);
        }
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }
    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { alert("hi") }
      }
    
    return (
        <div onScroll={handleScroll}>

            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                <div className={usrDetails == null ? "hidden" : usrDetails.role === "DOCTOR" || usrDetails.role == null ? "hidden" : "layout-sidebar"} onClick={onSidebarClick}>
                    <AppMenu model={parentMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>
                <div className='layout-main-container'>
                    <div className="layout-main">
                        <Route exact path="/home/dashboard" component={Dashboard}  ></Route>
                        <Route exact path="/home/dashboard/admin" component={DashboardAdmin}  ></Route>
                        <Route path="/home/country" component={Country}></Route>
                        <Route path="/home/state" component={State}  ></Route>
                        <Route path="/home/city" component={City}  ></Route>
                        <Route path="/home/airline" component={Airline}  ></Route>
                        <Route path="/home/company" component={Company}  ></Route>
                        <Route path="/home/roles" component={RolesList}  ></Route>
                        <Route path="/home/navigation" component={NavigationList}  ></Route>
                        <Route path="/home/users" component={Users} ></Route>
                        <Route path="/home/flight" component={Flight} ></Route>
                        <Route path="/home/hotel" component={Hotel}  ></Route>
                        <Route path="/home/batch" component={Batch}  ></Route>
                        <Route path="/home/airport" component={Airport}  ></Route>
                        <Route exact path="/home/editcompany/:id" component={Addcompany}></Route>
                        <Route exact path="/home/addcompany/" component={Addcompany}></Route>
                        <Route exact path="/home/viewcompany/:id" component={Addcompany}></Route>
                        <Route exact path="/home/editparticipant/:id" component={AddParticipant}></Route>
                        <Route exact path="/home/addparticipant/" component={AddParticipant} ></Route>
                        <Route exact path="/home/viewparticipant/:id" component={AddParticipant}></Route>
                        <Route path="/home/viewnewattendees" component={ViewNewAttendees} ></Route>
                        <Route path="/home/viewallattendees" component={ViewAllAttendees} ></Route>
                        <Route path="/home/approvedattendees" component={ApprovedAttendees} ></Route>
                        <Route path="/home/attendeesonhold" component={AttendeesonHold} ></Route>
                        <Route path="/home/rejectedattendees" component={RejectedAttendees} ></Route>
                        <Route path="/home/resubmissions" component={Resubmission} ></Route>
                        <Route path="/home/cancelledattendees" component={CancelledAttendees} ></Route>
                        <Route path="/home/attendeesdirectlyregisterd" component={AttendeesDirectlyRegisterd} ></Route>
                        <Route path="/home/doctorview" component={Doctorview} ></Route>
                        <Route path="/home/bookedtickets" component={BookedTickets} ></Route>
                        <Route path="/home/approvaltosendtravelagent" component={ApproveToSendTravelAgent} ></Route>
                        <Route path="/home/cancelledtickets" component={CancelledTickets} ></Route>
                        <Route path="/home/approvaltosendparticipant" component={ApproveToSendParticipant} ></Route>
                        <Route path="/home/booktickets" component={BookTickets} ></Route>
                        <Route path="/home/ticketstobecancel" component={TicketsToBeCancel} ></Route>
                        <Route path="/home/ticketstoberebook" component={TicketsToBeRebook} ></Route>
                        <Route path="/home/senttickets" component={SentTickets} ></Route>
                        <Route path="/home/dayofevent/i-carddetails" component={ICardDetails} ></Route>
                        <Route path="/home/dayofevent/merchandise" component={Merchandise} ></Route>
                        <Route path="/home/dayofevent/attendanceSummary" component={AttendanceSummary} ></Route>
                        <Route path="/home/dayofevent/certificategiven" component={CertificateGiven} ></Route>
                        <Route path="/home/travel/arrivalsummary" component={ArrivalSummary} ></Route>
                        <Route path="/home/travel/departuresummary" component={ArrivalSummary} ></Route>
                        <Route path="/home/travelsummary" component={TravelSummary} ></Route>
                        <Route path="/home/absentsummary" component={AbsentSummary} ></Route>
                        <Route path="/home/presentsummary" component={PresentSummary} ></Route>
                        <Route path="/home/CheckInCheckOut" component={CheckInCheckOut} ></Route>
                        <Route path="/home/hotelallocation" component={HotelAllocation} ></Route>
                        <Route path="/home/companywise" component={CompanyWiseAttendees}></Route>
                        <Route path="/home/QrCodeScan/:id" component={QrScan}></Route>
                        <Route path="/home/submr" component={SubMrList} ></Route>
                        <Route path="/home/addmr" component={AddSubMr} ></Route>
                        <Route exact path="/home/viewattendeerecord/:id" component={ViewAttedeeRecord}></Route>
                        <Route path="/home/cancelledsummary" component={CancelledSummary} ></Route>
                        <Route path="/reset-password/:id" component={ResetPassword}></Route>
                        <Route path="/home/qrscanner" component={QrScanner}></Route>
                        <Route path="/home/reports" component={CompanyWiseAttendees}></Route>
                        <Route path="/home/addflight" component={AddFlight}></Route>
                        <Route path="/home/editflight/:id" component={AddFlight}></Route>
                        <Route path="/home/eventclosed" component={EventClosed}></Route>
                        <Route path="/home/viewattendeestatus" component={ViewAttendeeStatus}></Route>

                        <Route path="/home/absentsummaryday-2" component={AbsentSummaryDay2} ></Route>
                        <Route path="/home/presentsummaryday-2" component={PresentSummaryDay2} ></Route>
                        <Route path="/home/bothdayspresent" component={BothDaysPresent} ></Route>
                        <Route path="/home/day1presentday2absent" component={Day1PresentDay2Absent} ></Route>
                        <Route path="/home/day1absentday2present" component={Day1AbsentDay2Present} ></Route>

                        {/* <Button icon="fa fa-solid fa-arrow-up" onClick={goToTop} rounded aria-label="Filter" tooltip="Move To Top" tooltipOptions={{ position: 'bottom' }} /> */}

                        {showTopBtn && (
                            <div class="scrollToTop">
                                {/* <button class='fa fa-solid fa-arrow-up' onClick={handleScrollToTop}>
                                    <i class="fa fa-solid fa-arrow-up"></i>
                                </button> */}
                                <Button icon="fa fa-solid fa-arrow-up" className="scrolltotop p-button-rounded mr-2 cursor-pointer" onClick={handleScrollToTop}/>
                            </div>
                        )}
                    </div>
                    <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                        layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />
                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                    {/* <div style={{ width: '250px', height: '200px', 'overflow': 'auto' }}>
                            <ScrollTop target="parent" threshold={100} className="w-2rem h-2rem border-round bg-primary" icon="pi pi-arrow-up text-base" />
                        </div> */}

                </div>
                <AppFooter layoutColorMode={layoutColorMode} />
            </div>
        </div>

    );

}
export default App;

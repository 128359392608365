import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Chart } from "primereact/chart";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";

const Dashboard = (props) => {
    // Hooks
    const [statewiseattendees, setStatewiseattendees] = useState([]);
    const [citywiseattendees, setCitywiseattendees] = useState([]);
    const [statetotalRecords, setstateTotalRecords] = useState(0);
    const [citytotalRecords, setcityTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [allotedRegischartData, setAllotedRegisChartData] = useState({});
    const [allotedRegischartTotalData, setAllotedRegisTotalChartData] = useState({});
    const [allotedRegischartApprovedData, setAllotedRegisApprovedChartData] = useState({});
    const [allotedConfirmedRegischartData, setAllotedConfirmedRegisChartData] = useState({});
    const [NewRegischartData, setNewRegisChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [doughnutChartOptions, setDoughnutchartOption] = useState({});
    const [totalRegisChartData, setTotalRegisChartData] = useState({});
    const [bookingDetailsChartData, setBookingDetailsChartData] = useState({});
    const [presentAttendeesChartData, setPresentAttendeesChartData] = useState({});
    const [WelcomeMessage, setwelcomeMessage] = useState(null);
    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
    const [showHideChart, setshowHideChart] = useState(false);

    const [TotaRegistrationChartdataFlag, setTotaRegistrationChartdataFlag] = useState(false);
    const [PendingAttendeesChartdataFlag, setPendingAttendeesChartdataFlag] = useState(false);
    const [PresentAttendeesChartdataFlag, setPresentAttendeesChartdataFlag] = useState(false);
    const [allotedRegistrationchartdataFlag, setallotedRegistrationchartdataFlag] = useState(false);
    const [ConfirmallotedRegistrationchartdataFlag, setConfirmallotedRegistrationchartdataFlag] = useState(false);
    const [RegisteredAllotedchartDataFlag, setRegisteredAllotedchartDataFlag] = useState(false);
    const [ConfirmattendeesChartDataFlag, setConfirmattendeesChartDataFlag] = useState(false);
    const [bookingDetailsChartDataFlag, setbookingDetailsChartDataFlag] = useState(false);
    const [TotalCount, setTotalCount] = useState(null);
    const [pendingTotalCount, setpendingTotalCount] = useState(null);
    const [AllotedTotalCount, setAllotedTotalCount] = useState(null);

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const lazyStateforstatwWise = useRef({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: 1,
    });

    const lazyStateforcityWise = useRef({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: 1,
    });

    useEffect(() => {
        document.documentElement.style.setProperty("overflow", "auto");
        getcompanylist();
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            responsive: true,
            scales: {
                x: {
                    ticks: {
                        callback: function (value) {
                            let lbl = this.getLabelForValue(value);
                            if (lbl && lbl.length > 17) lbl = `${lbl.substring(0, 16)}....`;
                            return lbl;
                        },
                        color: textColorSecondary,
                        font: {
                            weight: 500,
                        },
                    },
                    grid: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                },
                y: {
                    ticks: {
                        stepSize: 10,
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            return value;
                        },
                    },
                },
            },
        };

        const doughnutOptions = {
            aspectRatio: 1.4,
        };
        setDoughnutchartOption(doughnutOptions);
        setChartOptions(options);
    }, []);

    // On Screen Load
    useEffect(() => {
        getstatewiseattendees();
        getcitywiseattendees();
        getData();
    }, []);

    // Get All StateWise Attendees Count
    const getstatewiseattendees = (event) => {
        //onChangeLoading(true);
        setStatewiseattendees([]);
        axios
            .post("/api/Dashboard/GetStateWiseAttendees", {
                SortDirection: lazyStateforstatwWise.current.sortOrder,
                PageSize: 10,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyStateforstatwWise.current.page,
                SortField: event ? event.sortField : "Name",
            })
            .then((Response) => {
                setstateTotalRecords(Response.data.totalRecords);
                setStatewiseattendees(Response.data.data);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Get All CityWise Attendees Count
    const getcitywiseattendees = (event) => {
        setCitywiseattendees([]);
        axios
            .post("/api/Dashboard/GetCityWiseAttendees", {
                SortDirection: lazyStateforcityWise.current.sortOrder,
                PageSize: 10,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyStateforcityWise.current.page,
                SortField: event ? event.sortField : "Name",
            })
            .then((Response) => {
                setcityTotalRecords(Response.data.totalRecords);
                setCitywiseattendees(Response.data.data);
                //onChangeLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getcompanylist = () => {
        setIsLoading(true);
        axios
            .get("/api/Dashboard/GetParticipantTypeCount")
            .then((Response) => {
                const companyName = [];
                const noOfDoctors = [];
                const noOfTech = [];
                const noOfOthers = [];
                Response.data.forEach((element) => {
                    companyName.push(element.Name ? element.Name : "DIRECT REGISTRATION");
                    // companyName.push(element.Name);
                    noOfDoctors.push(element.doctors);
                    noOfTech.push(element.technician);
                    noOfOthers.push(element.other);
                });
                const data = {
                    labels: companyName,
                    datasets: [
                        {
                            label: "Doctors",
                            backgroundColor: "rgba(42,102,151,0.7)",
                            borderColor: "rgb(54, 162, 235)",
                            borderWidth: 1,
                            data: noOfDoctors,
                        },
                        // {
                        //     label: "Technician",
                        //     backgroundColor: "rgba(255, 159, 64, 0.5)",
                        //     borderColor: "rgb(255, 159, 64)",
                        //     borderWidth: 1,
                        //     data: noOfTech,
                        // },
                        // {
                        //     label: "Other",
                        //     backgroundColor: "rgba(54, 162, 235, 0.5)",
                        //     borderColor: "rgb(50, 162, 235)",
                        //     borderWidth: 1,
                        //     data: noOfOthers,
                        // },
                    ],
                };
                setAllotedRegisChartData(data);
                if (data.datasets[0].data.length >= 1) {
                    setallotedRegistrationchartdataFlag(true);
                }
                getAllRegisteredAttendes(Response.data, true);
                getAllRegisteredAttendes(Response.data, false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getAllRegisteredAttendes = (registeredAllotedData, IsApproved) => {
        axios
            .post("/api/Dashboard/GetConfirmAllottedRegistration", { IsApproved: IsApproved })
            .then((Response) => {
                const companyName = [];
                const noOfAttendees = [];
                const totalAttendees = [];
                Response.data.forEach((element) => {
                    // companyName.push(element.Name);
                    companyName.push(element.Name ? element.Name : "DIRECT REGISTRATION");
                    totalAttendees.push(registeredAllotedData.filter((x) => x.Name == element.Name)[0].doctors);
                    noOfAttendees.push(element.totalattendees);
                });
                const data = {
                    labels: companyName,
                    datasets: [
                        {
                            label: "Total",
                            backgroundColor: "rgba(42,102,151,0.7)",
                            borderColor: "rgb(54, 162, 235)",
                            borderWidth: 1,
                            data: totalAttendees,
                        },
                        {
                            label: IsApproved ? "Confirmed" : "Registered",
                            backgroundColor: "rgba(255, 159, 64, 0.5)",
                            borderColor: "rgb(255, 159, 64)",
                            borderWidth: 1,
                            data: noOfAttendees,
                        },
                    ],
                };

                if (IsApproved) {
                    setAllotedRegisApprovedChartData(data);
                    if (data.datasets[0].data.length >= 1) {
                        setConfirmallotedRegistrationchartdataFlag(true);
                    }
                } else {
                    setAllotedRegisTotalChartData(data);
                    
                    // Assuming data.datasets is an array of objects, and each object has a 'data' property
                    const firstDataset = data.datasets[0];
                    // Assuming 'data' is an array of numbers
                    const sum = firstDataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    setAllotedTotalCount(sum);

                    if (data.datasets[0].data.length >= 1) {
                        setRegisteredAllotedchartDataFlag(true);
                    }
                    setConfirmedAttendes();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const setConfirmedAttendes = () => {
        axios
            .get("/api/Dashboard/GetParticipantTotalCount")
            .then((Response) => {
                const responseData = Response.data;
                const noOfTotal = [responseData[0].totaldoctors, responseData[0].totaltechnician, responseData[0].other];
                const noOfApproeved = [responseData[0].totalapproveddoctors, responseData[0].totalapprovedtechnician, responseData[0].otherapproved];

                const data = {
                    labels: ["Doctor", "Technician", "Other"],
                    datasets: [
                        {
                            label: "Total",
                            backgroundColor: "rgba(42,102,151,0.7)",
                            borderColor: "rgb(54, 162, 235)",
                            borderWidth: 1,
                            data: noOfTotal,
                        },
                        {
                            label: "Confirmed",
                            backgroundColor: "rgba(255, 159, 64, 0.5)",
                            borderColor: "rgb(255, 159, 64)",
                            borderWidth: 1,
                            data: noOfApproeved,
                        },
                    ],
                };
                setAllotedConfirmedRegisChartData(data);
                getBookingDetails();

                if (data.datasets[0].data[0] > 0 || data.datasets[0].data[1] > 0) {
                    setConfirmattendeesChartDataFlag(true);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getBookingDetails = () => {
        axios
            .get("/api/Dashboard/GetBookingDetails")
            .then((Response) => {
                const responseData = Response.data;
                const data = {
                    labels: ["Accomodation", "Airline"],
                    datasets: [
                        {
                            label: "Total",
                            backgroundColor: "rgba(42,102,151,0.7)",
                            borderColor: "rgb(54, 162, 235)",
                            borderWidth: 1,
                            data: [responseData[0].accomtotal, responseData[0].airlinetotal],
                        },
                        {
                            label: "Confirmed",
                            backgroundColor: "rgba(255, 159, 64, 0.5)",
                            borderColor: "rgb(255, 159, 64)",
                            borderWidth: 1,
                            data: [responseData[0].accomconfirmed, responseData[0].airlineconfirmed],
                        },
                        {
                            label: "Pending",
                            backgroundColor: "rgba(54, 162, 235, 0.5)",
                            borderColor: "rgb(50, 162, 235)",
                            borderWidth: 1,
                            data: [responseData[0].accomtotal - responseData[0].accomconfirmed, responseData[0].airlinetotal - responseData[0].airlineconfirmed],
                        },
                    ],
                };
                setBookingDetailsChartData(data);
                if (data.datasets[0].data[0] > 0 || data.datasets[0].data[1] > 0) {
                    setbookingDetailsChartDataFlag(true);
                }

                getPendingAttendess();
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getPendingAttendess = () => {
        axios
            .get("/api/Dashboard/GetNewRegistrationDetails")
            .then((Response) => {
                const companyName = [];
                const noOfAttendees = [];
                Response.data.forEach((element) => {
                    companyName.push(element.Name ? element.Name : "DIRECT REGISTRATION");
                    noOfAttendees.push(element.count);
                });
                const pendingAttendeesColor = getChartColors(companyName);
                const data = {
                    labels: companyName,
                    datasets: [
                        {
                            data: noOfAttendees,
                            backgroundColor: pendingAttendeesColor,
                            // backgroundColor: '#f2632f'
                        },
                    ],
                };
                setNewRegisChartData(data);
                // Assuming data.datasets is an array of objects, and each object has a 'data' property
                const firstDataset = data.datasets[0];
                // Assuming 'data' is an array of numbers
                const sum = firstDataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                setpendingTotalCount(sum);

                if (data.datasets[0].data.length >= 1) {
                    setPendingAttendeesChartdataFlag(true);
                }
                getRegistrationToday();
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getRegistrationToday = () => {
        axios
            .get("/api/Dashboard/GetTotalRegistrationDatewise")
            .then((Response) => {
                const numberOfRegistration = [Response.data[0].registrationtoday, Response.data[0].registrationbeforetoday];
                const totalRegisColor = getChartColors(numberOfRegistration);
                const data = {
                    labels: ["Registration Today", "Registration Before Today"],
                    datasets: [
                        {
                            data: numberOfRegistration,
                            backgroundColor: totalRegisColor,
                            // backgroundColor: '#f2632f'
                        },
                    ],
                };
                setTotalRegisChartData(data);
                setTotalCount(data.datasets[0].data[0] + data.datasets[0].data[1]);
                if (data.datasets[0].data[0] > 0 || data.datasets[0].data[1] > 0) {
                    setTotaRegistrationChartdataFlag(true);
                }
                getPresentAttendees();
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getPresentAttendees = () => {
        axios
            .get("/api/Dashboard/GetPresetAttendees")
            .then((Response) => {
                const companyName = [];
                const noOfAttendees = [];
                Response.data.forEach((element) => {
                    companyName.push(element.Name ? element.Name : "DIRECT REGISTRATION");
                    noOfAttendees.push(element.presentattendees);
                });
                const presentAttendeesColor = getChartColors(Response.data);
                const data = {
                    labels: companyName,
                    datasets: [
                        {
                            data: noOfAttendees,
                            backgroundColor: presentAttendeesColor,
                        },
                    ],
                };
                setPresentAttendeesChartData(data);
                if (data.datasets[0].data.length >= 1) {
                    setPresentAttendeesChartdataFlag(true);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getChartColors = (data) => {
        const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
        const colorData = [];
        data.forEach((element) => {
            colorData.push(`rgba(${randomNum()}, ${randomNum()}, ${randomNum()},0.7)`);
        });
        return colorData;
    };

    const onBasicPageChangeState = (event) => {
        const newPage = event.first / event.rows + 1;
        if (newPage !== lazyStateforstatwWise.current.first / lazyStateforstatwWise.current.rows + 1) {
            setIsLoading(true);
            lazyStateforstatwWise.current.sortField = lazyStateforstatwWise.current.sortField;
            lazyStateforstatwWise.current.sortOrder = lazyStateforstatwWise.current.sortOrder;
            lazyStateforstatwWise.current.first = event.first;
            lazyStateforstatwWise.current.rows = event.rows;
            getstatewiseattendees(lazyStateforstatwWise.current);
            setIsLoading(false);
        }
    };

    const onBasicPageChangeCity = (event) => {
        const newPage = event.first / event.rows + 1;
        if (newPage !== lazyStateforcityWise.current.first / lazyStateforcityWise.current.rows + 1) {
            setIsLoading(true);
            lazyStateforcityWise.current.sortField = lazyStateforcityWise.current.sortField;
            lazyStateforcityWise.current.sortOrder = lazyStateforcityWise.current.sortOrder;
            lazyStateforcityWise.current.first = event.first;
            lazyStateforcityWise.current.rows = event.rows;
            getcitywiseattendees(lazyStateforcityWise.current);
            setIsLoading(false);
        }
    };

    // For Sorting
    const onSortStateWise = (event) => {
        lazyStateforstatwWise.current.sortField = event.sortField;
        lazyStateforstatwWise.current.sortOrder = event.sortOrder;
        lazyStateforstatwWise.current.first = event.first;
        lazyStateforstatwWise.current.rows = event.rows;
        getstatewiseattendees(lazyStateforstatwWise.current);
    };

    // For Sorting
    const onSortCityWise = (event) => {
        lazyStateforcityWise.current.sortField = event.sortField;
        lazyStateforcityWise.current.sortOrder = event.sortOrder;
        lazyStateforcityWise.current.first = event.first;
        lazyStateforcityWise.current.rows = event.rows;
        getcitywiseattendees(lazyStateforcityWise.current);
    };

    //Template for pagination
    const templateState = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
    };

    //Template for pagination
    const templateCity = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
    };

    // generate QR code
    const getData = () => {
        fetch("config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setwelcomeMessage(data[2].WelcomeMessage);
            });
    };

    return (
        <div>
            <Toast ref={toast} />
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="card">
                        <div className="p-fluid formgrid grid">
                            <div className=" col-12 md:col-12 sm:col-12">
                                <div className="card p-1 flex justify-content-center">
                                    <h1 style={{ fontFamily:"Calisto MT",fontWeight:"bold",textAlign: "start", color: "#333", color: "#2A6697", marginTop: "0.9rem", marginLeft: "0.5rem" }}>
                                        {WelcomeMessage}
                                    </h1>
                                </div>
                            </div>
                            <div className="col-4 md:col-4 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">Total Registration : {TotalCount}</h4>
                                    {TotaRegistrationChartdataFlag ? (
                                        <Chart type="doughnut" data={totalRegisChartData} options={doughnutChartOptions} plugins={[ChartDataLabels]} />
                                    ) : (
                                        <h5 className="flex justify-content-center" style={{ color: "darkgrey" }}>
                                            No data to display !
                                        </h5>
                                    )}
                                </div>
                            </div>
                            <div className="col-4 md:col-4 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">Pending Attendees : {pendingTotalCount}</h4>
                                    {PendingAttendeesChartdataFlag ? (
                                        <Chart type="doughnut" data={NewRegischartData} options={doughnutChartOptions} plugins={[ChartDataLabels]} />
                                    ) : (
                                        <h5 className="flex justify-content-center" style={{ color: "darkgrey" }}>
                                            No data to display !
                                        </h5>
                                    )}
                                </div>
                            </div>
                            <div className="col-4 md:col-4 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">Present Attendees</h4>
                                    {PresentAttendeesChartdataFlag ? (
                                        <Chart type="doughnut" data={presentAttendeesChartData} options={doughnutChartOptions} plugins={[ChartDataLabels]} />
                                    ) : (
                                        <h5 className="flex justify-content-center" style={{ color: "darkgrey" }}>
                                            No data to display !
                                        </h5>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-12 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">Registered Allotted Registration</h4>
                                    {RegisteredAllotedchartDataFlag ? (
                                        <Chart type="bar" data={allotedRegischartTotalData} options={chartOptions} plugins={[ChartDataLabels]} />
                                    ) : (
                                        <h5 className="flex justify-content-center" style={{ color: "darkgrey" }}>
                                            No data to display !
                                        </h5>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">Allotted Registration : {AllotedTotalCount}</h4>
                                    {allotedRegistrationchartdataFlag ? (
                                        <Chart type="bar" data={allotedRegischartData} options={chartOptions} plugins={[ChartDataLabels]} />
                                    ) : (
                                        <h5 className="flex justify-content-center" style={{ color: "darkgrey" }}>
                                            No data to display !
                                        </h5>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">Confirmed Allotted Registration</h4>
                                    {ConfirmallotedRegistrationchartdataFlag ? (
                                        <Chart type="bar" data={allotedRegischartApprovedData} options={chartOptions} plugins={[ChartDataLabels]} />
                                    ) : (
                                        <h5 className="flex justify-content-center" style={{ color: "darkgrey" }}>
                                            No data to display !
                                        </h5>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">Confirmed Attendees Details</h4>
                                    {ConfirmattendeesChartDataFlag ? (
                                        <Chart type="bar" data={allotedConfirmedRegischartData} options={chartOptions} plugins={[ChartDataLabels]} />
                                    ) : (
                                        <h5 className="flex justify-content-center" style={{ color: "darkgrey" }}>
                                            No data to display !
                                        </h5>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">Booking Details</h4>
                                    {bookingDetailsChartDataFlag ? (
                                        <Chart type="bar" data={bookingDetailsChartData} options={chartOptions} plugins={[ChartDataLabels]} />
                                    ) : (
                                        <h5 className="flex justify-content-center" style={{ color: "darkgrey" }}>
                                            No data to display !
                                        </h5>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-6 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">State Wise Attendees</h4>
                                    <DataTable
                                        lazy
                                        rows={10}
                                        sortField={lazyStateforstatwWise.current.sortField}
                                        sortOrder={lazyStateforstatwWise.current.sortOrder}
                                        size="small"
                                        stripedRows
                                        ref={dt}
                                        value={statewiseattendees}
                                        onSort={onSortStateWise}
                                        className="datatable-responsive"
                                        responsiveLayout="scroll"
                                        removableSort
                                        emptyMessage="No data to display !"
                                    >
                                        <Column field="name" sortField="Name" header="State" sortable headerStyle={{ width: "50%", minWidth: "10rem" }}></Column>
                                        <Column field="noOfAttendees" sortField="NoOfAttendees" header="Attendees" sortable headerStyle={{ width: "50%", minWidth: "10rem" }}></Column>
                                    </DataTable>
                                    <Paginator
                                        className="justify-content-end"
                                        style={{ marginRight: "15px" }}
                                        first={lazyStateforstatwWise.current.first}
                                        rows={lazyStateforstatwWise.current.rows}
                                        totalRecords={statetotalRecords}
                                        template={templateState}
                                        onPageChange={onBasicPageChangeState}
                                    ></Paginator>
                                </div>
                            </div>
                            <div className="field col-12 md:col-6 sm:col-12">
                                <div className="card">
                                    <h4 className="dashboardchart-header">City Wise Attendees</h4>
                                    <DataTable
                                        lazy
                                        rows={10}
                                        sortField={lazyStateforcityWise.current.sortField}
                                        sortOrder={lazyStateforcityWise.current.sortOrder}
                                        size="small"
                                        stripedRows
                                        ref={dt}
                                        value={citywiseattendees}
                                        onSort={onSortCityWise}
                                        className="datatable-responsive"
                                        responsiveLayout="scroll"
                                        removableSort
                                        emptyMessage="No data to display !"
                                    >
                                        <Column field="name" sortField="Name" header="City" sortable headerStyle={{ width: "50%", minWidth: "10rem" }}></Column>
                                        <Column field="noOfAttendees" sortField="NoOfAttendees" header="Attendees" sortable headerStyle={{ width: "50%", minWidth: "10rem" }}></Column>
                                    </DataTable>
                                    <Paginator className="justify-content-end" style={{ marginRight: "15px" }} first={lazyStateforcityWise.current.first} rows={lazyStateforcityWise.current.rows} totalRecords={citytotalRecords} template={templateCity} onPageChange={onBasicPageChangeCity}></Paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default Dashboard;

import userDetails from "../services/userTokenDetails";

const checkPermissions = (allowedPermissions) => {
  const user = userDetails();
  var bar = '' + allowedPermissions;
  const tokenallow = bar.split(':');

  if (allowedPermissions.length === 0) {
    return true;
  }

  if (user != null && user[tokenallow[1]] != undefined) {
    if (Array.isArray(user[tokenallow[1]])) {
      return user[tokenallow[1]].some(permission =>
        tokenallow[0].includes(permission)
      );
    } else {
      return (user[tokenallow[1]] === tokenallow[0]);
    }
  } else {
    return false;
  }
};

const AccessControl = ({
  allowedPermissions,
  children,
  renderNoAccess,
}) => {
  const permitted = checkPermissions(allowedPermissions);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  renderNoAccess: () => null,
};

export default AccessControl;

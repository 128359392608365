import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Paginator } from 'primereact/paginator';
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';

const Company = (props) => {

    // Hooks
    const [Companylist, setCompanylist] = useState([]);
    const [company, setCompany] = useState([]);
    const [id, setid] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [fullName, setfullName] = useState(null);
    const [filterContactPerson, setFilterContactPerson] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterNoOfDoctor, setFilterNoOfDoctor] = useState(null);

    // Flags
    const [deleteDialog, setdeleteDialog] = useState(false);  // Deleting New Company
    const [enableFilter, setEnableFilter] = useState(false);

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        name: null,
        contactperson: null,
        email: null,
        phone: null,
        noofdoctor: null
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        if (props?.location.state?.detail == "AddCompany") {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Company Added', life: 3000 });
        }
        if (props?.location.state?.detail == "UpdateCompany") {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Updated', life: 3000 });
        }
        getcompanylist();
        document.documentElement.style.setProperty('overflow', 'hidden')
    }, []);


    // - All Api

    // Get All Company
    const getcompanylist = (event, isExportExcel = false) => {
        setIsLoading(true);
        if (!isExportExcel) setCompanylist([]);
        axios.post('/api/Company/GetAll', {
            PaginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event ? event.sortField : "",
            Name: lazyState.current.name ? lazyState.current.name.trim() : null,
            Email: lazyState.current.email ? lazyState.current.email.trim() : null,
            Contactperson: lazyState.current.contactperson ? lazyState.current.contactperson.trim() : null,
            Phone: lazyState.current.phone ? lazyState.current.phone.trim() : null,
            Noofdoctor: lazyState.current.noofdoctor ? lazyState.current.noofdoctor.trim() : null,
        }).then(Response => {
            let tempData = [];
            for (let dataObj of Response.data.data) {
                let allExcelData;
                let excelcolumnData = {
                    Id: dataObj.id,
                    Name: dataObj.name,
                    "Contact Person": dataObj.contactperson,
                    Email: dataObj.email,
                    "Mobile No": dataObj.phone,
                    "No. of Doctor": dataObj.noofdoctor,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        Id: dataObj.id,
                        Name: dataObj.name,
                        "Contact Person": dataObj.contactperson,
                        Email: dataObj.email,
                        "Mobile No": dataObj.phone,
                        "No. of Doctor": dataObj.noofdoctor,
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'Company');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setCompanylist(Response.data.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    // Get By Id
    const getCompanyDetailsById = (id) => {
        setIsLoading(true);
        axios.get('/api/Company/GetAsync/' + id)
            .then((Response) => {
                setid(Response.data.id);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // To Delete Company
    const deleteCompany = () => {
        setIsLoading(true);
        axios.delete('/api/Company/Delete/' + id)
            .then((result) => {
                if (result.status === 200) {
                    setdeleteDialog(false);
                    getcompanylist(lazyState.current, false);
                    setIsLoading(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 3000 });
                    // document.documentElement.style.setProperty('overflow', 'auto')
                };
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // Functions
    // On Delete Company
    const confirmDeleteCompany = (company) => {
        document.documentElement.style.setProperty('overflow', 'hidden')
        setdeleteDialog(true);
        setCompany(company);
        getCompanyDetailsById(company.id);
    };

    // On Hide Delete Dialog
    const hideDeleteDialog = () => {

        setdeleteDialog(false);
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getcompanylist(lazyState.current, false);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getcompanylist(lazyState.current, false);
            setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    // All Buttons

    // Edit And Delete Buttons
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <AccessControl
                    allowedPermissions={["delete:company"]}>
                    <Button icon="fa fa-trash" className="p-button-rounded" onClick={() => confirmDeleteCompany(rowData)} tooltip="Delete" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        );
    };

    // Delete Confirmation Button
    const deleteProductDialogFooter = (
        <>
            <Button label="Yes" icon="pi pi-check" onClick={() => deleteCompany(id)} outlined />
            <Button label="No" autoFocus icon="pi pi-times" onClick={() => hideDeleteDialog(false)} outlined />
        </>
    );

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl
                    allowedPermissions={["add:company"]}>
                    <Link to={'/home/addcompany'}> <Button label="Add Company"
                        icon="fa fa-plus" outlined /> </Link>
                </AccessControl>
            </div>
        );
    };

    const removeFilter = () => {
        lazyState.current.name = null;
        setfullName(null);
        lazyState.current.email = null;
        setFilterEmail(null);
        lazyState.current.phone = null;
        setFilterMobileNo(null);
        lazyState.current.noofdoctor = null;
        setFilterNoOfDoctor(null);
        lazyState.current.contactperson = null;
        setFilterContactPerson(null);
        getcompanylist(lazyState.current, false);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.name == null && lazyState.current.email == null && lazyState.current.phone == null &&
                    lazyState.current.noofdoctor == null && lazyState.current.contactperson == null ? true : false}
                    icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                <div className="my-2">{header}</div>
                <AccessControl
                    allowedPermissions={["export:company"]}>
                    <Button disabled={!(Companylist != null && Companylist.length > 0)} tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={() => { getcompanylist(null, true); }} icon="pi pi-file-excel" className="mr-2" outlined />
                </AccessControl>
            </div>
        );
    };

    // For Email
    const ButtonMailto = (rowData) => {
        return (
            <Link className='linkcolors' id='mailto'
                to='#'
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >{rowData.email}
            </Link>
        );
    };

    // For Phone Number
    const ButtonPhoneto = (rowData) => {
        return (
            <Link className='linkcolors' id='callto'
                to='#'
                onClick={(e) => {
                    window.location.href = `tel:${rowData.phone}`;
                    e.preventDefault();
                }}
            >{rowData.phone}
            </Link>
        );
    };

    const hidecolumns = [
        { field: "address", header: "Address" },
        { field: "remarks", header: "Remarks" },
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = hidecolumns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    };

    const header = (
        <div style={{ textAlign: 'left', marginRight: '10px' }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: '20em' }} display="chip" />
        </div>
    );

    const sortColumn = {
        "Address": 'Address',
        "Remarks": 'Remarks',
    };

    const hidecolumnComponents = selectedColumns.map(col => {
        return <Column key={col.field} style={{ overflowWrap: 'anywhere' }} field={col.field} header={col.header} sortable headerStyle={col.header == 'Address' ? { width: '15%', minWidth: '15rem' } : { width: '15%', minWidth: '15rem' }} sortField={sortColumn[col.header]} />;
    });

    const FilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.contactperson = e.target.value ? e.target.value : null
                        break;

                    case "company":
                        lazyState.current.name = e.target.value ? e.target.value : null
                        break;

                    case "Email":
                        lazyState.current.email = e.target.value ? e.target.value : null
                        break;

                    case "MobileNo":
                        lazyState.current.phone = e.target.value ? e.target.value : null
                        break;

                    case "Noofdoctor":
                        lazyState.current.noofdoctor = e.target.value ? e.target.value : null
                        break;
                };
                getcompanylist(lazyState.current, false,);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.contactperson = null;
                        break;

                    case "company":
                        lazyState.current.name = null;
                        break;

                    case "Email":
                        lazyState.current.email = null;
                        break;

                    case "MobileNo":
                        lazyState.current.phone = null;
                        break;

                    case "Noofdoctor":
                        lazyState.current.noofdoctor = null;
                        break;
                };
                getcompanylist(lazyState.current, false,);
                setIsLoading(false);
            };
        };
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="company" required autoFocus value={fullName} placeholder="Enter Company Name" style={{ overflowWrap: 'anywhere' }}
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} className="w-auto" />
        );
    };

    const ContactPersonFilterTemplate = () => {
        return (
            <InputText id="fullName" required value={filterContactPerson} placeholder="Enter Contact Person"
                onChange={(e) => setFilterContactPerson(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} className="w-auto" />
        );
    };

    const EmailFilterTemplate = () => {
        return (
            <InputText id="Email" required value={filterEmail} placeholder="Enter Email"
                onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const MobileFilterTemplate = () => {
        return (
            <InputText id="MobileNo" required value={filterMobileNo} placeholder="Enter Mobile no"
                onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const NoOfDoctorFilterTemplate = () => {
        return (
            <InputText id="Noofdoctor" required value={filterNoOfDoctor} placeholder="Enter No of Doctor"
                onChange={(e) => setFilterNoOfDoctor(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const ViewCompany = (rowData) => {
        return (
            <Link className='linkcolors' id='viewcompany'
                tooltip='aa'
                to='#'
                onClick={(e) => {
                    history.push('/home/viewcompany/' + rowData.id)
                }}
            >{rowData.name}
            </Link>
        );
    };

    return (
        <AccessControl
            allowedPermissions={["list:company"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-building mr-2"></i>Company</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <Tooltip target="#viewcompany" content="View Company" />
                                    <Tooltip target="#mailto" content="Mail To" />
                                    <Tooltip target="#callto" content="Call To" />
                                    <DataTable size="small" stripedRows ref={dt} value={Companylist}
                                        lazy rows={lazyState.current.rows} scrollHeight="calc(100vh - 250px)" scrollable
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort
                                        className="datatable-responsive" responsiveLayout="scroll"
                                        filterDisplay={"row"}>
                                        {/* filterDisplay={enableFilter ? "row" : null}> */}
                                        <Column body={ViewCompany} sortField="Name" field="name" header="Name" sortable headerStyle={{ width: '25%', minWidth: '20rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={UserFilterTemplate}></Column>
                                        <Column field="contactperson" header="Contact Person" sortField="Firstname" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={ContactPersonFilterTemplate}></Column>
                                        <Column body={ButtonMailto} header="Email" sortable sortField="Email" headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} style={{ overflowWrap: 'anywhere' }} filterElement={EmailFilterTemplate}></Column>
                                        <Column body={ButtonPhoneto} header="Phone" sortField="Phone" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} style={{ overflowWrap: 'anywhere' }} filterElement={MobileFilterTemplate}></Column>
                                        <Column field="noofdoctor" header="No. Of Doctors" sortField="Noofdoctor" sortable style={{ width: "5px", overflowWrap: 'anywhere' }} headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={NoOfDoctorFilterTemplate}></Column>
                                        {hidecolumnComponents}
                                        <Column header="Action" body={actionBodyTemplate} style={{ overflowWrap: 'anywhere' }} headerStyle={{ width: '10px%', minWidth: '10rem' }}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>

                                    <Dialog visible={deleteDialog} draggable={false} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteDialog}>
                                        <div className="flex align-items-center">
                                            <i className="fa fa-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>Are you sure you want to delete <b>{company.name}</b> company?</span>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    );
};

export default Company;
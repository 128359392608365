import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import axios from 'axios';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Splitter, SplitterPanel } from 'primereact/splitter';

const ViewAttedeeRecord = (props) => {
    const [ParticipantInFlightRecord, setParticipantInFlightRecord] = useState([]);
    const [ParticipantOutFlightRecord, setParticipantOutFlightRecord] = useState([]);
    const [ParticipantRecord, setParticipantRecord] = useState([]);
    const [Participantname, setParticipantname] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const param = useParams();
    const history = useHistory();
    const toast = useRef(null);
    const dt = useRef(null);

    const lazyState = useRef({ screen: null });
    //var usrDetails = userDetails();

    useEffect(() => {
        document.documentElement.style.setProperty('overflow', 'auto');
        if (param.id) {
            getparticipantFlightrecordviewbyid(param.id);
            getparticipantrecordviewbyid(param.id);
        }
        if (props?.location.state?.detail == "TravelSummary") {
            lazyState.current.screen = "TravelSummary"
        }
        if (props?.location.state?.detail == "ViewAllAttendee") {
            lazyState.current.screen = "ViewAllAttendee"
        }
        if (props?.location.state?.detail == "ArrivalSummary") {
            lazyState.current.screen = "ArrivalSummary"
        }
        if (props?.location.state?.detail == "DepartureSummary") {
            lazyState.current.screen = "DepartureSummary"
        }
        if (props?.location.state?.detail == "ApproveToSendTravelAgent") {
            lazyState.current.screen = "ApproveToSendTravelAgent"
        }
        if (props?.location.state?.detail == "ApproveToSendParticipant") {
            lazyState.current.screen = "ApproveToSendParticipant"
        }
        if (props?.location.state?.detail == "BookedTickets") {
            lazyState.current.screen = "BookedTickets"
        }
        if (props?.location.state?.detail == "BookTickets") {
            lazyState.current.screen = "BookTickets"
        }
        if (props?.location.state?.detail == "CancelledSummary") {
            lazyState.current.screen = "CancelledSummary"
        }
        if (props?.location.state?.detail == "SentTickets") {
            lazyState.current.screen = "SentTickets"
        }
        if (props?.location.state?.detail == "TicketToBeCancel") {
            lazyState.current.screen = "TicketToBeCancel"
        }
        if (props?.location.state?.detail == "DayOfEvent") {
            lazyState.current.screen = "DayOfEvent"
        }
    }, []);


    const getparticipantFlightrecordviewbyid = (id) => {
        setIsLoading(true);
        axios.get('/api/Participant/GetParticipantFlightViewRecordById/' + id)
            .then((Response) => {
                setParticipantInFlightRecord(Response.data.filter((x) => x.in_Out == true));
                setParticipantOutFlightRecord(Response.data.filter((x) => x.in_Out == false))
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const getparticipantrecordviewbyid = (id) => {
        setIsLoading(true);
        axios.get('/api/Participant/GetParticipantViewRecordById/' + id)
            .then((Response) => {
                setParticipantname(Response.data[0]?.fullName);
                setParticipantRecord(Response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const DepartOnFormate = (event) => {
        if (event.departureDateTime == null) {
            return null;
        } else {
            const demployee = moment(event.departureDateTime).format('DD/MM/YYYY HH:mm');
            return demployee;
        };
    };

    const ArriveAtFormate = (event) => {
        if (event.arrivalDateTime == null) {
            return null;
        } else {
            const demployee = moment(event.arrivalDateTime).format('DD/MM/YYYY HH:mm');
            return demployee;
        };
    };

    const rightToolbarTemplate = () => {
        return (

            <div className="my-2">
                <Button severity="secondary" outlined icon="fa fa-arrow-left" className="p-button-secondary mr-2" tooltip="Back" tooltipOptions={{ position: 'bottom' }} onClick={(e) => history.goBack()} />
            </div>

        );
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="mt-3">
                <h5 className="headerfont"><span>{Participantname}</span></h5>
            </div>
        );
    };

    return (
        <div className="trico-Grid">
            <Toast ref={toast} />
            {isLoading ? <LoadingSpinner /> :
                (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont"><span><i className="fa fa-solid fa-users mr-2"></i>View Attendee Record</span></h5>
                                {/* <h6>{Participantname}</h6> */}
                                <Toolbar className="field" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                                <Accordion multiple activeIndex={0}>
                                    <AccordionTab header="Attendee Details">
                                        <div className="field">
                                            <DataTable value={ParticipantRecord} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="participantType" header="Participant Type"></Column>
                                                <Column field="phone" header="Phone"></Column>
                                                {/* <Column field="tricoConfirmed" header="Trico Confirmed"></Column> */}
                                                <Column field="zone" header="Zone"></Column>
                                                <Column field="mbbsRegistrationNumber" header="Mbbs Reg No."></Column>
                                                <Column field="dmRegistrationNumber" header="Dm Reg No."></Column>
                                                <Column field="tricoConfirmed" header="Trico Confirmed"></Column>

                                            </DataTable>
                                        </div>
                                    </AccordionTab>

                                    <AccordionTab header="Remarks">
                                        <div className="field">
                                            <DataTable value={ParticipantRecord} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="participantRemarks" header="Attendee Remarks"></Column>
                                                <Column field="ticketCancellationRemarks" header="Ticket Canceation Record"></Column>
                                                <Column field="travelRemarks" header="Travel Remarks"></Column>
                                            </DataTable>
                                        </div>
                                    </AccordionTab>

                                    <AccordionTab className={lazyState.current.screen == "ViewAllAttendee" || lazyState.current.screen == "TravelSummary" || lazyState.current.screen == "ArrivalSummary"
                                        || lazyState.current.screen == "DepartureSummary" || lazyState.current.screen == "ApproveToSendTravelAgent" || lazyState.current.screen == "ApproveToSendParticipant"
                                        || lazyState.current.screen == "BookedTickets" || lazyState.current.screen == "BookTickets" || lazyState.current.screen == "CancelledSummary"
                                        || lazyState.current.screen == "SentTickets" || lazyState.current.screen == "TicketToBeCancel" ? "" : "hidden"} header="Travel Details">
                                        <div className="field">

                                            <DataTable value={ParticipantRecord} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="inFlightBookingStatus" header="In Flight Booking Status"></Column>
                                                <Column field="outFlightBookingStatus" header="Out Flight Booking Status"></Column>
                                                <Column field="inBatch" header="In Batch"></Column>
                                                <Column field="outBatch" header="Out Batch"></Column>
                                                <Column field="smokingPrefrence" header="Smoking Preference"></Column>
                                                <Column field="mealPrefrence" header="Meal Preference"></Column>
                                                <Column field="infare" header="In Fare"></Column>
                                                <Column field="outFare" header="Out Fare"></Column>
                                                <Column field="mailedTicket" header="Mailed Ticket"></Column>
                                                <Column field="ticketBooked" header="Ticket Booked"></Column>
                                            </DataTable>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab className={lazyState.current.screen == "ViewAllAttendee" || lazyState.current.screen == "TravelSummary" || lazyState.current.screen == "ArrivalSummary"
                                        || lazyState.current.screen == "ApproveToSendTravelAgent" || lazyState.current.screen == "ApproveToSendParticipant"
                                        || lazyState.current.screen == "BookedTickets" || lazyState.current.screen == "BookTickets"
                                        || lazyState.current.screen == "CancelledSummary" || lazyState.current.screen == "SentTickets"
                                        || lazyState.current.screen == "TicketToBeCancel" ? "" : "hidden"} header="In Flight Details">
                                        <div className="field">
                                            {/* <h5 className="text-center" style={{ fontFamily: 'New Century Schoolbook, TeX Gyre Schola, serif', fontWeight: "bolder", color: ' rgb(42, 102, 151)' }}>In Flight Status</h5> */}
                                            <DataTable value={ParticipantInFlightRecord} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="flightNo" header="FlightNo"></Column>
                                                <Column field="airlineName" header="Airline"></Column>
                                                <Column field="departFrom" header="Depart From"></Column>
                                                <Column body={DepartOnFormate} header="Departure Datetime"></Column>
                                                <Column field="arriveAt" header="Arrive At"></Column>
                                                <Column body={ArriveAtFormate} header="Arrival Datetime"></Column>
                                            </DataTable>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab className={lazyState.current.screen == "ViewAllAttendee" || lazyState.current.screen == "TravelSummary" || lazyState.current.screen == "DepartureSummary"
                                        || lazyState.current.screen == "ApproveToSendTravelAgent" || lazyState.current.screen == "ApproveToSendParticipant"
                                        || lazyState.current.screen == "BookedTickets" || lazyState.current.screen == "BookTickets"
                                        || lazyState.current.screen == "CancelledSummary" || lazyState.current.screen == "SentTickets"
                                        || lazyState.current.screen == "TicketToBeCancel" ? "" : "hidden"} header="Out Flight Details">
                                        <div className="field">
                                            {/* <h5 className="text-center" style={{ fontFamily: 'New Century Schoolbook, TeX Gyre Schola, serif', fontWeight: "bolder", color: 'rgb(42, 102, 151)' }}>Out Flight Status</h5 > */}
                                            <DataTable value={ParticipantOutFlightRecord} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="flightNo" header="FlightNo"></Column>
                                                <Column field="airlineName" header="Airline"></Column>
                                                <Column field="departFrom" header="Depart From"></Column>
                                                <Column body={DepartOnFormate} header="Departure Datetime"></Column>
                                                <Column field="arriveAt" header="Arrive At"></Column>
                                                <Column body={ArriveAtFormate} header="Arrival Datetime"></Column>
                                            </DataTable>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab className={lazyState.current.screen == "ViewAllAttendee" || lazyState.current.screen == "ApproveToSendTravelAgent" || lazyState.current.screen == "ApproveToSendParticipant"
                                        || lazyState.current.screen == "SentTickets" ? "" : "hidden"} header="Hotel Details">
                                        <div className="field">
                                            <DataTable value={ParticipantRecord} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="needAccomodation" header="Need Accomodation"></Column>
                                                <Column field="hotel" header="Hotel"></Column>
                                                <Column field="occupancy" header="Occupancy"></Column>
                                            </DataTable>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab className={lazyState.current.screen == "ViewAllAttendee" || lazyState.current.screen == "ApproveToSendParticipant" || lazyState.current.screen == "SentTickets" || lazyState.current.screen == "DayOfEvent" ? "" : "hidden"} header="Event Details">
                                        <div className="field">
                                            <DataTable value={ParticipantRecord} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="certificateIssued" header="Certificate Issued"></Column>
                                                <Column field="merchandiseIssued" header="Merchandise Issued"></Column>  
                                                <Column field="isCheckedIn" header="IsCheckedIn"></Column>
                                                <Column field="isArrived" header="IsArrived"></Column>
                                                <Column field="present" header="Present"></Column>
                                                <Column field="cardNo" header="Card No"></Column>
                                                {/* {/* <Column field="mealPrefrence" header="Meal Preference"></Column> */}
                                            </DataTable>
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div>
                        </div >
                    </>
                )
            }
        </div>
    )
};

export default ViewAttedeeRecord;
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Paginator } from 'primereact/paginator';
import classNames from 'classnames';
import '../../assets/layout/styling.css';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";

const Batch = () => {

    let emptyBatch = {
        id: '',
        name: '',
    };

    // Hooks
    const [Batchlist, setBatchlist] = useState([]);
    const [id, setid] = useState('');
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [BatchData, setBatchData] = useState(emptyBatch);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [batchName, setBatchName] = useState("");
    const [selectedColumns, setSelectedColumns] = useState([]);

    // Flags
    const [AddnewDialog, setAddnewDialog] = useState(false);    // Adding New Batch
    const [deleteDialog, setdeleteDialog] = useState(false);  // Deleting New Batch
    const [submitted, setSubmitted] = useState(false);   //Checking Submitted Or Not
    const [isExportExcel, setIsExportExcel] = useState(false);

    // Const
    const UserName = localStorage.getItem('Username');
    // const date = new Date();
    const toast = useRef(null);
    const dt = useRef(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        batch: null
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        document.documentElement.style.setProperty('overflow', 'hidden');
        getBatchlist();
    }, []);

    // - All Api

    // Get All Batch
    const getBatchlist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setBatchlist([]);
        axios.post('/api/Batch/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event ? event.sortField : "Name",
            BatchName: lazyState.current.batch ? lazyState.current.batch.trim() : null
        }).then(Response => {
            if (!isExportExcel) setBatchlist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    // Id: dataObj.id,
                    Name: dataObj.name,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        // Id: dataObj.id,
                        Name: dataObj.name,
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'Batch');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setBatchlist(Response.data.data);
            setIsLoading(false);
        })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // Get By Id
    const getbatchbyid = (id) => {
        setIsLoading(true);
        axios.get('/api/Batch/GetAsync/' + id)
            .then((Response) => {
                setid(Response.data.id);
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    // Add New Batch
    const Insertupdatebatch = () => {
        setSubmitted(true);
        if (BatchData.name.trim()) {
            if (BatchData.id == '') {
                const url = '/api/Batch/Insert';
                const data = {
                    "Name": BatchData.name?.toUpperCase().trim(),
                    "CreatedBy": UserName?.toUpperCase(),
                    // "Created": date,
                }
                axios.post(url, data)
                    .then(() => {
                        setAddnewDialog(false);
                        getBatchlist(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Batch Added', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: Batch With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Batch Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            }
            if (BatchData.id) {
                const url = '/api/Batch/Update';
                const data = {
                    "id": BatchData.id,
                    "Name": BatchData.name?.toUpperCase().trim(),
                    "CreatedBy": CreatedBy,
                    "Created": Created,
                    "ModifiedBy": UserName,
                    // "Modified": date
                }
                axios.put(url, data)
                    .then(() => {
                        setAddnewDialog(false);
                        getBatchlist(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Batch Updated', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: Batch With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Batch Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
    };

    // To Delete Batch
    const DeleteBatch = () => {
        setIsLoading(true);
        setBatchData(emptyBatch);
        axios.delete('/api/Batch/Delete/' + id)
            .then((result) => {
                if (result.status === 200) {
                    setdeleteDialog(false);
                    getBatchlist(isExportExcel, lazyState.current);
                    setIsLoading(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Batch Deleted', life: 3000 });
                    // document.documentElement.style.setProperty('overflow', 'auto');
                };
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    // Functions

    // On Add New Batch
    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setBatchData(emptyBatch);
        setSubmitted(false);
        setAddnewDialog(true);
    };

    // On Edit Batch
    const editProduct = (Batch) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setAddnewDialog(true);
        getbatchbyid(Batch.id);
        let _Batch = { ...Batch };
        _Batch['id'] = (Batch.id) || '';
        _Batch['name'] = (Batch.name) || '';
        setBatchData(_Batch);
    };

    // On Delete Batch
    const confirmDeleteProduct = (Batch) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setdeleteDialog(true);
        setBatchData(Batch);
        getbatchbyid(Batch.id);
    };

    // On Hide Dialog For Edit / Add Batch
    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setAddnewDialog(false);
        setSubmitted(false);
    };

    // On Hide Delete Dialog
    const hideDeleteProductDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setdeleteDialog(false);
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        //lazyState.current = event;
        getBatchlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getBatchlist(isExportExcel, lazyState.current);
            setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    // All Buttons

    // Edit And Delete Buttons
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <AccessControl
                    allowedPermissions={["edit:batch"]}>
                    <Button icon="fa fa-pencil" className="p-button-rounded mr-2" onClick={() => editProduct(rowData)} tooltip="Edit" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
                <AccessControl
                    allowedPermissions={["delete:batch"]}>
                    <Button icon="fa fa-trash" className="p-button-rounded mr-2" onClick={() => confirmDeleteProduct(rowData)} tooltip="Delete" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
            </div>
        );
    };

    // Modals Button To Add Batch
    const productDialogFooter = (
        <div className="ml-2">
            <Button label="Save" icon="fa fa-check"
                className="p-button-outlined " onClick={() => Insertupdatebatch(id)} outlined />
            <Button label="Cancel" icon="fa fa-times"
                className="p-button-outlined " onClick={(e) => { hideDialog(e); setAddnewDialog(false) }} outlined />
        </div>
    );

    // Delete Confirmation Button
    const deleteProductDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" onClick={() => DeleteBatch(id)} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={() => hideDeleteProductDialog(false)} outlined />
        </>
    );

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <AccessControl
                allowedPermissions={["add:batch"]}>
                <div className="my-2">
                    <Button label="Add Batch" icon="fa fa-plus"
                        className="p-button-outlined mr-2"
                        // className="p-button-info mr-2"
                        onClick={openNew} outlined />
                </div>
            </AccessControl>
        )
    };

    //On Input Change In Text Box Of Batch
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Batch = { ...BatchData };
        _Batch[`${name}`] = val;
        setBatchData(_Batch);
    };

    const BatchNameFilterTemplate = () => {
        return (
            <InputText name="batchName" className="p-inputtext"
                value={batchName} placeholder="Enter Batch Name" onChange={(e) => setBatchName(e.target.value)}
                onKeyDown={(e) => BatchNameFilteronEnter(e)} style={{ width: '300px' }}></InputText>
        )
    };

    const BatchNameFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.batch = e.target.value ? e.target.value : null
                getBatchlist(isExportExcel, lazyState.current);
            } else {
                setIsLoading(true);
                lazyState.current.batch = null;
                getBatchlist(isExportExcel, lazyState.current);
            };
        };
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.batch == null ? true : false} type="button"
                    // icon="fa fa-solid fa-refresh" 
                    // className="p-button-outlined mr-2"
                    icon="pi pi-filter-slash" className=" mr-2 p-button-primary"
                    onClick={(e) => { lazyState.current.batch = null; setBatchName(null); getBatchlist(isExportExcel, lazyState.current) }} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2"
                    onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                <AccessControl
                    allowedPermissions={["export:batch"]}>
                    <Button disabled={!(Batchlist != null && Batchlist.length > 0)} onClick={() => { getBatchlist(true); }} icon="pi pi-file-excel" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        )
    };

    // Html Part
    return (
        <AccessControl
            allowedPermissions={["list:batch"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-cog mr-2"></i>Batch</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable size="small" stripedRows ref={dt} value={Batchlist} lazy rows={lazyState.current.rows}
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort dataKey="id" className="datatable-responsive" responsiveLayout="scroll" scrollHeight="calc(100vh - 250px)" scrollable
                                        filterDisplay={"row"}>
                                        {/* filterDisplay={enableFilter ? "row" : null}> */}
                                        <Column field="name" header="Name" sortField="Name" sortable headerStyle={{ width: '80%', minWidth: '10rem' }} filter filterElement={BatchNameFilterTemplate} showFilterMenu={false}></Column>
                                        <Column header="Action" body={actionBodyTemplate}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }}
                                        first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>

                                    <Dialog visible={AddnewDialog} draggable={false}
                                        // header={BatchData.id == '' ? <span><i className="fa fa-plus-square mr-2"></i>Add Batch</span> : <span><i className="fa fa-edit mr-2"></i>Update Batch</span>} 
                                        header="Batch Details"
                                        modal className="p-fluid w-30rem" footer={productDialogFooter} onHide={hideDialog}>
                                        <div className="field">
                                            <label id="label" htmlFor="Batch">Batch</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText
                                                // id="name"
                                                autoFocus name="name" required placeholder="Enter Batch" value={BatchData.name?.toUpperCase()} onChange={(e) => onInputChange(e, 'name')}
                                                className={classNames({ 'p-invalid p-inputtext': submitted && !BatchData.name })} maxLength="15"
                                            // id="p-inputtext"
                                            />
                                            {submitted && !BatchData.name && <small className="p-invalid">Batch is required.</small>}
                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteDialog} draggable={false} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                                        <div className="flex align-items-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>Are you sure you want to delete <b>{BatchData.name}</b> Batch? </span>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    );
};

export default Batch;
import jwt from 'jwt-decode';

const userDetails = () => {
    if (localStorage.getItem('access_token') != null) {
        return jwt(localStorage.getItem('access_token'));
    }
    return null;
}

export default userDetails;



// import jwt from 'jwt-decode';
// import { useHistory } from 'react-router-dom';

// const userDetails = () => {
//     const tokenString = localStorage.getItem('access_token');
//     if (tokenString !== '' && tokenString !== null && tokenString !== undefined) {
//         let expiry = (JSON.parse(atob(tokenString.split('.')[1]))).exp;
//         // console.log((JSON.parse(atob(tokenString.split('.')[1]))).exp);
//         // console.log(Math.floor((new Date).getTime() / 1000));
//         if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
//             window.location.assign('/');
//         }
//         else {
//             return jwt(localStorage.getItem('access_token'));
//         }
//     }
//     return null;
//     // else {
//     //     return true;
//     // }
// }

// export default userDetails;

// isTokenExpired(): boolean {
//     const tokenString: string = localStorage.getItem('access_token');
//     if (tokenString !== '' && tokenString !== null && tokenString !== 'undefined') {
//       let expiry = (JSON.parse(atob(tokenString.split('.')[1]))).exp;
//       return (Math.floor((new Date).getTime() / 1000)) >= expiry;
//     } else {
//       return true;
//     }
//   }
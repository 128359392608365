import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Paginator } from 'primereact/paginator';
import classNames from 'classnames';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Dropdown } from 'primereact/dropdown';

const Airline = () => {

    let emptyAirline = {
        id: '',
        name: '',
    };

    // Hooks
    const [Airlinelist, setAirlinelist] = useState([]);
    const [id, setid] = useState('');
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [Airlinedata, setAirlinedata] = useState(emptyAirline);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);

    // Flags
    const [AddnewDialog, setAddnewDialog] = useState(false);    // Adding New Country
    const [deleteDialog, setDeleteProductDialog] = useState(false);  // Deleting New Country
    const [submitted, setSubmitted] = useState(false);  //Checking Submitted Or Not
    const [airLineName, setAirLineName] = useState("");
    const [enableFilter, setEnableFilter] = useState(false);
    const [isExportExcel, setIsExportExcel] = useState(false);

    // Const
    const UserName = localStorage.getItem('Username');
    // const date = new Date();
    const toast = useRef(null);
    const dt = useRef(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: null,
        airlinefilter: null
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getairlinelist();
        document.documentElement.style.setProperty('overflow', 'hidden');
    }, []);


    // - All Api

    // Get All Airline List
    const getairlinelist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setAirlinelist([]);
        axios.post('/api/Airline/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: event ? event.sortOrder : 1,
            CurrentPageNumber: event ? ((event.first / event.rows) + 1) : lazyState.current.page,
            PageSize: event ? event.rows : lazyState.current.rows,
            SortField: event ? event.sortField : "Name",
            AirlineName: lazyState.current.airlinefilter ? lazyState.current.airlinefilter.trim() : null
        }).then(Response => {
            if (!isExportExcel) setAirlinelist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    // Id: dataObj.id,
                    Name: dataObj.name,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        // Id: dataObj.id,
                        Name: dataObj.name,
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'Airline');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setAirlinelist(Response.data.data);
            setIsLoading(false);
        })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };


    // Get By Id
    const getairlinebyid = (id) => {
        setIsLoading(true);
        axios.get('/api/Airline/GetAsync/' + id)
            .then((Response) => {
                setid(Response.data.id);
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // Add New Airline

    const insertupdateairline = () => {
        setSubmitted(true);
        if (Airlinedata.name.trim()) {
            if (Airlinedata.id == '') {
                const url = '/api/Airline/Insert';
                const data = {
                    "Name": Airlinedata.name?.toUpperCase().trim(),
                    "CreatedBy": UserName?.toUpperCase(),
                    // "Created": date,
                };
                axios.post(url, data)
                    .then(() => {
                        setAddnewDialog(false);
                        getairlinelist(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Airline Added', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: Airline With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Airline Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
            if (Airlinedata.id) {
                const url = '/api/Airline/Update';
                const data = {
                    "id": Airlinedata.id,
                    "Name": Airlinedata.name?.toUpperCase().trim(),
                    "CreatedBy": CreatedBy?.toUpperCase(),
                    "Created": Created,
                    "ModifiedBy": UserName?.toUpperCase(),
                    // "Modified": date
                }
                axios.put(url, data)
                    .then(() => {
                        setAddnewDialog(false);
                        getairlinelist(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Airline Updated', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: Airline With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Airline Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
    };

    // To Delete Country
    const deleteairline = () => {
        setIsLoading(true);
        setAirlinedata(emptyAirline);
        axios.delete('/api/Airline/Delete/' + id)
            .then((result) => {
                if (result.status === 200) {
                    setDeleteProductDialog(false);
                    getairlinelist(isExportExcel, lazyState.current);
                    setIsLoading(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Airline Deleted', life: 3000 });
                    // document.documentElement.style.setProperty('overflow', 'auto');
                };
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    // Functions

    // On Add New Country
    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setAirlinedata(emptyAirline);
        setSubmitted(false);
        setAddnewDialog(true);
    };

    // On Edit Country
    const editProduct = (airline) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setAddnewDialog(true);
        getairlinebyid(airline.id);
        let _Airline = { ...Airlinedata };
        _Airline['id'] = (airline.id);
        _Airline['name'] = (airline.name);
        setAirlinedata(_Airline);
    };

    // On Delete Country
    const confirmDeleteProduct = (airline) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setDeleteProductDialog(true);
        setAirlinedata(airline);
        getairlinebyid(airline.id);
    };

    // On Hide Dialog For Edit / Add Airline
    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setAddnewDialog(false);
        setSubmitted(false);
    };

    // On Hide Delete Dialog
    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current = event;
        getairlinelist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current = {
                first: event.first,
                rows: event.rows,
                page: event.page,
                sortField: lazyState.current.sortField,
                sortOrder: lazyState.current.sortOrder,
            };
            getairlinelist(isExportExcel, lazyState.current);
            setIsLoading(false);
        // }
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    // All Buttons

    // Edit And Delete Buttons
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <AccessControl
                    allowedPermissions={["edit:airline"]}>
                    <Button icon="fa fa-pencil" className="p-button-rounded mr-2" onClick={() => editProduct(rowData)} tooltip="Edit" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
                <AccessControl
                    allowedPermissions={["delete:airline"]}>
                    <Button icon="fa fa-trash" className="p-button-rounded mr-2" onClick={() => confirmDeleteProduct(rowData)} tooltip="Delete" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
            </div>
        );
    };

    // Modals Button To Add/ Update Airline
    const productDialogFooter = (
        <>
            <Button label="Save" icon="fa fa-check" onClick={() => insertupdateairline(id)} outlined />
            <Button label="Cancel" icon="fa fa-times" onClick={(e) => { hideDialog(e); setAddnewDialog(false) }} outlined />
        </>
    );

    // Delete Confirmation Button
    const deleteProductDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" onClick={() => deleteairline(id)} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={() => hideDeleteProductDialog(false)} outlined />
        </>
    );

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <AccessControl
                allowedPermissions={["add:airline"]}>
                <div className="my-2">
                    <Button label="Add Airline" icon="fa fa-plus"
                        className="mr-2" onClick={openNew} outlined />
                </div>
            </AccessControl>
        )
    };

    // On Input Change In Text Box Of Airline
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Airline = { ...Airlinedata };
        _Airline[`${name}`] = val;

        setAirlinedata(_Airline);
    };

    const AirlineNameFilter = () => {
        return (
            <InputText name="airlineName" value={airLineName} placeholder="Enter Airline" required autoFocus onChange={(e) => setAirLineName(e.target.value)}
                onKeyDown={(e) => AirlineFilteronEnter(e)} style={{ width: '300px' }}></InputText>
        );
    };

    const AirlineFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.airlinefilter = e.target.value ? e.target.value : null
                getairlinelist(isExportExcel, lazyState.current);
            } else {
                setIsLoading(true);
                lazyState.current.airlinefilter = null
                getairlinelist(isExportExcel, lazyState.current);
            };
        };
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.airlinefilter == null ? true : false} icon="pi pi-filter-slash" className="mr-2" onClick={(e) => { lazyState.current.airlinefilter = null; setAirLineName(null); getairlinelist(isExportExcel, lazyState.current) }} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2"
                    onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                <AccessControl
                    allowedPermissions={["export:airline"]}>
                    <Button disabled={!(Airlinelist != null && Airlinelist.length > 0)} onClick={() => { getairlinelist(true); }} icon="pi pi-file-excel" className="mr-2" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        );
    };

    // Html Part
    return (
        <AccessControl
            allowedPermissions={["list:airline"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid  fa-plane fa-rotate-110 mr-2"></i>Airlines</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable size="small" stripedRowsref={dt} value={Airlinelist} lazy rows={lazyState.current.rows}
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort scrollable scrollHeight="calc(100vh - 250px)"
                                        dataKey="id" className="datatable-responsive" responsiveLayout="scroll"
                                        filterDisplay={"row"}>
                                        {/* filterDisplay={enableFilter ? "row" : null}> */}
                                        <Column field="name" sortField="Name" header="Name" sortable headerStyle={{ width: '80%', minWidth: '10rem' }} filterElement={AirlineNameFilter} filter showFilterMenu={false}></Column>
                                        <Column header="Action" body={actionBodyTemplate}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>

                                    <Dialog visible={AddnewDialog} draggable={false} style={{ width: '450px' }}
                                        // header={Airlinedata.id == '' ? <span><i className="fa fa-plus-square mr-2"></i>Add Airline</span> : <span><i className="fa fa-edit mr-2"></i>Update Airline</span>} 
                                        header="Airline Details"
                                        modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                                        <div className="field">
                                            <label id="label" htmlFor="Airline">Airline</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText maxlength="50" id="name" name="name" required autoFocus placeholder="Enter Airline" value={Airlinedata.name?.toUpperCase()} onChange={(e) => onInputChange(e, 'name')}
                                                className={classNames({ 'p-invalid': submitted && !Airlinedata.name })} />
                                            {submitted && !Airlinedata.name && <small className="p-invalid">Airline is required.</small>}
                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteDialog} draggable={false} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                                        <div className="flex align-items-center">
                                            <i className="fa fa-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>Are you sure you want to delete <b>{Airlinedata.name}</b> airline?</span>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    );
};

export default Airline;
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Paginator } from 'primereact/paginator';
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from 'react-router-dom';
import * as Designations from '../../enums/Designations';
import * as Travelmodes from '../../enums/Travelmodes';
import { MultiSelect } from 'primereact/multiselect';
import exportData from "../../services/exportToExcelService";
import * as Genders from '../../enums/Genders';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from 'primereact/tooltip';

const CancelledTickets = () => {

    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [fullName, setfullName] = useState(null)
    const [hospitalNamefilter, sethospitalNamefilter] = useState(null);
    const [companyNameFilter, setcompanyNameFilter] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        Designationfilter: null,
        Toeventcityfilter: null,
        Fromeventcityfilter: null,
        Genderfilter: null,
        name: null,
        city: null,
        boardingCity: null,
        state: null,
        country: null,
        MobileNo: null,
        email: null,
        filterHospitalName: null,
        filterCompany: null,
        TricoConfirmedfilter:null
    }); // For Sorting And Pagination Purpose
    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [Cityfilter, setCityfilter] = useState(null);
    const [Statefilter, setStatefilter] = useState(null);
    const [Countryfilter, setCountryfilter] = useState(null);
    const [companyList,setCompanyList] = useState([]);
    const [tricoConfirmedList, setTricoConfirmedList] = useState([]);
    let orderedSelectedColumns = [];
    let unSelectedColumns = [];
    var company = localStorage.getItem('Company');
    var usrDetails = userDetails();

    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty('overflow', 'hidden');
        getFilterListsData();
    }, [])

    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
         //Company
         var listCompanyFilterString = null;
         if (lazyState.current.CompanyFilter != null && lazyState.current.CompanyFilter != undefined && lazyState.current.CompanyFilter != '') {
             if (lazyState.current.CompanyFilter.length > 0) {
                 if (lazyState.current.CompanyFilter.length > 1) {
                     listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name).join(',');
                 } else {
                     listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name)[0];
                 }
             }
         } 
        //Trico Confirmed Batch
        var listTricoConfirmedFilterString = null;
        if (lazyState.current.TricoConfirmedfilter != null && lazyState.current.TricoConfirmedfilter != undefined && lazyState.current.TricoConfirmedfilter != '') {
            if (lazyState.current.TricoConfirmedfilter.length > 0) {
                if (lazyState.current.TricoConfirmedfilter.length > 1) {
                    listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name).join(',');
                } else {
                    listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name)[0];
                 }
             }
         }
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios.post('/api/Participant/GetAll', {
            TicketInFlightBookingStatus: 'CANCELLED',
            TicketOutFlightBookingStatus: 'CANCELLED',
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "Firstname",
            FullName: lazyState.current.name ? lazyState.current.name.trim() : null,
            HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
            CompanyFilter: usrDetails.role === 'MR' ? company : listCompanyFilterString,
            City: lazyState.current.city ? lazyState.current.city.trim() : null,
            BoardingCity: lazyState.current.boardingCity ? lazyState.current.boardingCity.trim() : null,
            State: lazyState.current.state ? lazyState.current.state.trim() : null,
            country: lazyState.current.country ? lazyState.current.country.trim() : null,
            Email: lazyState.current.email ? lazyState.current.email.trim() : null,
            MobileNo: lazyState.current.MobileNo ? lazyState.current.MobileNo.trim() : null,
            Gender: lazyState.current.Genderfilter ? lazyState.current.Genderfilter.name.trim() : null,
            Designation: lazyState.current.Designationfilter ? lazyState.current.Designationfilter.name.trim() : null,
            Gender: lazyState.current.Genderfilter ? lazyState.current.Genderfilter.name.trim() : null,
            ToEventCity: lazyState.current.Toeventcityfilter ? lazyState.current.Toeventcityfilter.code.trim() : null,
            FromEventCity: lazyState.current.Fromeventcityfilter ? lazyState.current.Fromeventcityfilter.code.trim() : null,
            SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
            TricoConfirmed: listTricoConfirmedFilterString ? listTricoConfirmedFilterString : null
        }).then(Response => {
            if (!isExportExcel) setParticipantlist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    Id: dataObj.serialNumber,
                    Name: dataObj.fullName,
                    Company: dataObj.company,
                    TricoConfirmed: dataObj.tricoConfirmed,
                    Email: dataObj.email,
                    MobileNo: dataObj.mobileNo,
                    BoardingCity: dataObj.boardingcityname,
                    // Hospital: dataObj.hospitalName,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Company: dataObj.company,
                        TricoConfirmed: dataObj.tricoConfirmed,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                        BoardingCity: dataObj.boardingcityname,
                        // Hospital: dataObj.hospitalName,
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'CancelledTickets');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setParticipantlist(Response.data.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const getFilterListsData = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetAllFilterListData")
            .then((Response) => {
                const separatedLists = Response.data.reduce((acc, item) => {
                    // Create a new array for each type if it doesn't exist
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    // Push the formatted item to the respective array
                    acc[item.type].push({ name: item.name, code: item.name });
                    return acc;
                }, {});

                // Sort each list by 'name'
                Object.keys(separatedLists).forEach(type => {
                    separatedLists[type].sort((a, b) => a.name.localeCompare(b.name));
                });
                setCompanyList(separatedLists.Company || [])
                setTricoConfirmedList(separatedLists.TricoConfirmed || []); 
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="fullName" value={fullName} placeholder="Enter FirstName"
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const BoardingCityFilterTemplate = () => {
        return (
            <InputText id="boardingcity" value={boardingcityfilter} placeholder="Enter BoardingCity"
                onChange={(e) => setboardingcityfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const EmailFilterTemplate = () => {
        return (
            <InputText id="Email" value={filterEmail} placeholder="Enter Email"
                onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const MobileFilterTemplate = () => {
        return (
            <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile No."
                onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const HospitalFilterTemplate = () => {
        return (
            <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital"
                onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const CompanyFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.CompanyFilter}
                options={companyList}
                itemTemplate={CompanyFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => CompanyFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const CompanyFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const CompanyFilterBodyTemplate = (rowData) => {
        const company = rowData.company;
        return (
            <div className="flex align-items-center gap-2">
                <span>{company}</span>
            </div>
        );
    };

    const CompanyFilterClick = (e) => {
        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { CompanyFilter: e.value });
        e.preventDefault();
    }



    const FilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.name = e.target.value ? e.target.value : null
                        break;
                    case "city":
                        lazyState.current.city = e.target.value ? e.target.value : null
                        break;
                    case "state":
                        lazyState.current.state = e.target.value ? e.target.value : null
                        break;
                    case "country":
                        lazyState.current.country = e.target.value ? e.target.value : null
                        break;
                    case "Email":
                        lazyState.current.email = e.target.value ? e.target.value : null
                        break;
                    case "MobileNo":
                        lazyState.current.MobileNo = e.target.value ? e.target.value : null
                        break;
                    case "boardingcity":
                        lazyState.current.boardingCity = e.target.value ? e.target.value : null
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                    case "TricoConfirmed":
                        lazyState.current.TricoConfirmedfilter = e.target.value ? e.target.value : null;
                        break;    
                };
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.name = null
                        break;
                    case "city":
                        lazyState.current.city = null
                        break;
                    case "state":
                        lazyState.current.state = null
                        break;
                    case "country":
                        lazyState.current.country = null
                        break;
                    case "Email":
                        lazyState.current.email = null
                        break;
                    case "MobileNo":
                        lazyState.current.MobileNo = null
                        break;
                    case "boardingcity":
                        lazyState.current.boardingCity = null
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = null
                        break;
                    case "company":
                        lazyState.current.filterCompany = null
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                    case "TricoConfirmed":
                        lazyState.current.TricoConfirmedfilter = null;
                        break;    
                };
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Designationfilter} options={Designations.Designation} optionLabel="name" showClear onChange={(e) => { lazyState.current.Designationfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Designation: e.value }); e.preventDefault() }} placeholder="Select Designation" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Genderfilter} options={Genders.Gender} optionLabel="name" showClear onChange={(e) => { lazyState.current.Genderfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Gender: e.value }); e.preventDefault() }} placeholder="Select Gender" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Toeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Toeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { ToEventCity: e.value }); e.preventDefault() }} placeholder="Select TravelMode " className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const FromEventCityTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Fromeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Fromeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { FromEventCity: e.value }); e.preventDefault() }} placeholder="Select TravelMode" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const CityFilterTemplate = () => {
        return (
            <InputText id="city"  value={Cityfilter} placeholder="Enter City"
                onChange={(e) => setCityfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const StateFilterTemplate = () => {
        return (
            <InputText id="state"  value={Statefilter} placeholder="Enter State"
                onChange={(e) => setStatefilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const CountryFilterTemplate = () => {
        return (
            <InputText id="country"  value={Countryfilter} placeholder="Enter Country"
                onChange={(e) => setCountryfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const TricoConfirmedFilterTemplate = () => {
                        return (
                            <MultiSelect
                                value={lazyState.current.TricoConfirmedfilter}
                                options={tricoConfirmedList}
                                itemTemplate={TricoConfirmedFilterItemTemplate}
                                optionLabel="name"
                                onChange={(e) => TricoConfirmedFilterClick(e)}
                                placeholder="Select Any"
                                className="p-column-filter"
                                maxSelectedLabels={1}
                                selectionLimit={3}
                                style={{ minWidth: '14rem' }}
                                filter
                            />
                        );
                    };
                
        const TricoConfirmedFilterBodyTemplate = (rowData) => {
                const tricoConfirmed = rowData.tricoConfirmed;
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{tricoConfirmed}</span>
                    </div>
                    );
                };
        const TricoConfirmedFilterItemTemplate = (option) => {
                 return (
                    <div className="flex align-items-center gap-2">
                        <span>{option.name}</span>
                    </div>
                    );
                };
                
        const TricoConfirmedFilterClick = (e) => {
                lazyState.current.TricoConfirmedfilter = e.target.value ? e.target.value : null;
                getparticipantlist(isExportExcel, { TricoConfirmed: e.value });
                e.preventDefault();
                };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getparticipantlist(isExportExcel, lazyState.current);
            setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    const exportExcel = () => {
        getparticipantlist(true);
    };

    const removeFilter = () => {
        lazyState.current.name = null;
        setfullName(null);
        lazyState.current.email = null;
        setFilterEmail(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.MobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.boardingCity = null;
        setboardingcityfilter(null);
        lazyState.current.city = null;
        setCityfilter(null);
        lazyState.current.state = null;
        setStatefilter(null);
        lazyState.current.country = null;
        setCountryfilter(null);
        lazyState.current.Genderfilter = null;
        lazyState.current.Toeventcityfilter = null;
        lazyState.current.Fromeventcityfilter = null;
        lazyState.current.Designationfilter = null;
        setSerialNumber(null);
        lazyState.current.serialnumber = null;
        lazyState.current.TricoConfirmedfilter = null;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["route:cancelledtickets"]}>
                    <Button disabled={lazyState.current.name == null && lazyState.current.email == null && lazyState.current.filterHospitalName == null &&
                        lazyState.current.filterCompany == null && lazyState.current.MobileNo == null && lazyState.current.boardingCity == null &&
                        lazyState.current.country == null && lazyState.current.state == null && lazyState.current.city == null && lazyState.current.Designationfilter == null &&
                        lazyState.current.Genderfilter == null && lazyState.current.Toeventcityfilter == null && lazyState.current.serialnumber == null && lazyState.current.Fromeventcityfilter == null && lazyState.current.TricoConfirmedfilter ==  null ? true : false}
                        icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                </AccessControl>
                <div className="my-2">{header}</div>
                <AccessControl allowedPermissions={["export:cancelledtickets"]}>
                    <Button disabled={!(participantlist != null && participantlist.length > 0)} tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={() => { exportExcel(); }} icon="pi pi-file-excel" className="mr-2" outlined/>
                </AccessControl>
            </div>
        )
    };

    //#region Method for Column toggle
    const hidecolumns = [
        { field: "designation", header: "Designation" },
        { field: "gender", header: "Gender" },
        { field: "cityname", header: "City" },
        { field: "statename", header: "State" },
        { field: "countryname", header: "Country" },
        { field: "travelmode_Inbound", header: "Travel In Bound" },
        { field: "travelmode_Outbound", header: "Travel Out Bound" }
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        orderedSelectedColumns = hidecolumns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter(o => !selectedColumns.some(i => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    }

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map(col => {
                switch (col.header) {
                    case "City":
                        if (lazyState.current.city != null) {
                            lazyState.current.city = null;
                            isFiltered = true;
                            return setCityfilter(null);
                        }
                        break;
                    case "State":
                        if (lazyState.current.state != null) {
                            lazyState.current.state = null;
                            isFiltered = true;
                            return setStatefilter(null);
                        }
                        break;
                    case "Country":
                        if (lazyState.current.country != null) {
                            lazyState.current.country = null;
                            isFiltered = true;
                            return setCountryfilter(null);
                        }
                        break;
                    case "To Event City":
                        if (lazyState.current.Toeventcityfilter != null) {
                            lazyState.current.Toeventcityfilter = null;
                            isFiltered = true;
                            return lazyState.current.Toeventcityfilter;
                        }
                        break;
                    case "From Event City":
                        if (lazyState.current.Fromeventcityfilter != null) {
                            lazyState.current.Fromeventcityfilter = null;
                            isFiltered = true;
                            return lazyState.current.Fromeventcityfilter;
                        }
                        break;
                    case "Designation":
                        if (lazyState.current.Designationfilter != null) {
                            lazyState.current.Designationfilter = null;
                            isFiltered = true;
                            return lazyState.current.Designationfilter;
                        }
                        break;
                    case "Gender":
                        if (lazyState.current.Genderfilter != null) {
                            lazyState.current.Genderfilter = null;
                            isFiltered = true;
                            return lazyState.current.Genderfilter;
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(isExportExcel, lazyState.current);
            };
        };
    };

    const header = (
        <AccessControl allowedPermissions={["route:cancelledtickets"]}>
            <div style={{ textAlign: 'left', marginRight: '10px' }}>
                <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: '20em' }} display="chip" />
            </div>
        </AccessControl>
    );

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            case "Designation":
                return DesignationFilterTemplate;
            case "Gender":
                return GenderFilterTemplate;
            case "City":
                return CityFilterTemplate;
            case "State":
                return StateFilterTemplate;
            case "Travel In Bound":
                return ToEventCityTemplate;
            case "Travel Out Bound":
                return FromEventCityTemplate;
            case "Country":
                return CountryFilterTemplate;
        };
    };

    const sortColumn = {
        "Designation": 'Designation',
        "cityname": 'cityname',
        "Statename": 'Statename',
        "Countryname": 'Countryname',
        "Travel In Bound": 'Travelmode_Inbound',
        "Travel Out Bound": 'Travelmode_Outbound',
        "Gender": 'Gender',
    };

    const hidecolumnComponents = selectedColumns.map(col => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: '15%', minWidth: '15rem' }} sortField={sortColumn[col.header]}
            filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} />;
    });
    //#endregion

    const ButtonMailto = (rowData) => {
        return (
            <Link className='linkcolors' id='mailto'
                to='#'
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >{rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link className='linkcolors' id='callto'
                to='#'
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >{rowData.mobileNo}
            </Link>
        );
    }

    //#region Method for view attendees 
    const ViewAttendees = (rowData) => {
        return (
            <AccessControl allowedPermissions={["view:cancelledtickets"]}>
                <Link className='linkcolors' id='viewattendee'
                    to='#'
                    onClick={(e) => {
                        history.push('/home/viewparticipant/' + rowData.id)
                    }}
                >{rowData.fullName}
                </Link>
            </AccessControl>
        );
    };

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                    <Link to='#' onClick={(e) => history.push( { pathname: '/home/viewattendeeRecord/' + rowData.id , state: { detail: "CancelledSummary" } })}> <span className="fa fa-solid fa-eye" id="viewattendeerecord" style={{color:' rgb(42, 102, 151)'}}></span></Link> 
            </div>
        );
    };

    return (
        <AccessControl allowedPermissions={["route:cancelledtickets"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-ticket mr-2"></i>Cancelled Tickets</span></h5>
                                    <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <Tooltip target="#viewattendee" content="View Attendee" />
                                    <Tooltip target="#mailto" content="Mail To" />
                                    <Tooltip target="#callto" content="Call To" />
                                    <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                    {/* <Toolbar className="mb-4" ></Toolbar> */}
                                    <DataTable size="small" lazy rows={lazyState.current.rows} scrollable scrollHeight="calc(100vh - 250px)"
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort stripedRows ref={dt} value={participantlist} selection={participantlist} onSelectionChange={(e) => setParticipantlist(e.value)}
                                        filterDisplay={"row"} >
                                        <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: '0%', minWidth: '2rem' }} ></Column>
                                        <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                        <Column body={ViewAttendees} frozen field="fullName" sortField="FullName" header="Name" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={UserFilterTemplate} showClearButton></Column>
                                        <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} body={CompanyFilterBodyTemplate} filterField="Company" filterMenuStyle={{ width: '14rem' }} ></Column>
                                        <Column field="tricoConfirmed" sortField="TricoConfirmed" sortable header="Trico Confirmed" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={TricoConfirmedFilterTemplate} body={TricoConfirmedFilterBodyTemplate} filterField="tricoConfirmed" filterMenuStyle={{ width: '14rem' }} ></Column>
                                        <Column body={ButtonMailto} sortField="Email" header="Email" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate} showClearButton></Column>
                                        <Column body={ButtonPhoneto} sortField="MobileNo" header="Mobile No" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={MobileFilterTemplate} showClearButton></Column>
                                        <Column field="boardingcityname" sortField="boardingcityname" header="Boarding City" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={BoardingCityFilterTemplate} showClearButton></Column>
                                        {/* <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} showClearButton></Column> */}
                                        {hidecolumnComponents}
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    )
}
export default CancelledTickets;
import React,{useEffect} from "react";
import eventclosedimg from './assets/images/registration_closed.jpg'

const EventClosed = () => {

    // On Screen Load
    // useEffect(() => {
    //     document.documentElement.style.setProperty('overflow', 'hidden')
    // }, []);

    return (
        <div className="main-event-closed">
            <div className="banner-event-closed">
                <h1 className="bannermain-event-closed">
                    <label >Welcome to TRICO : 2025</label></h1>
            </div>
            <div className="bannerBorder-event-closed">
            </div>
            <div className="contentArea-event-closed">
                <div className="welcome-text-event-closed">
                    TRICO is a Transradial Intervention course conducted every year by Apex Heart Institute under the leadership of Padma Shri Prof. Tejas Patel. TRICO is considered as one of the premier TRI course in the world. It is an effort to bring together the interventional cardiologists from all over the world and provide a stage to teach, innovate and improve the transradial technique. The participants, delegates and others can access their account.
                </div>
                <div className="LoginBox-event-closed" style={{ height: "auto" }}>

                    <table>
                        <tr>
                            <td>
                                <img style={{ border: "none", textDecoration: "none" }} title="Registration Closed!" src={eventclosedimg} />
                                {/* <img src="Images/registration_closed.jpg" title="Registration Closed!" style="border: none; text-decoration: none;" /> */}
                            </td>
                            <td>
                                <h4 style={{ color: "color:#777", fontFamily: "none", fontSize: "medium" }} >The registration for TRICO: 2025 is now closed. please visit <u><a style={{ color: "#777" }} href="http://www.transradialworld.com/" target="_blank">http://www.transradialworld.com</a></u> for the event updates.</h4></td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    )
}
export default EventClosed;
import React from "react";
import '../../src/assets/layout/certificate.css';

const PrintCertificate = (props) => {
    const capitalizeWords = (str) => {
        if (str.length === 0) {
            return ''; // Return empty string for empty input
        }

        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    return (
        <body>
            <style>
                {`
                    .bgimgdelegate {
                        background-image: url(${props.delegateurl}) !important;
                        background-size: cover !important;
                        background-repeat: no-repeat !important;
                        background-position: center center !important;
                        width: 210mm;
                        height: 297mm;
                    }

                    .bgimgfaculty {
                        background-image: url(${props.facultyurl}) !important;
                        background-size: cover !important;
                        background-repeat: no-repeat !important;
                        background-position: center center !important;
                        width: 210mm;
                        height: 297mm;
                    }
                `}
            </style>
            <div className={props.designation == 'DELEGATE' ? 'bgimgdelegate' : 'bgimgfaculty'}>
                <h1 className='bgimgH1'>{capitalizeWords(props.name)}</h1>
            </div>
        </body>
    )
}

export default PrintCertificate;
import jwt from 'jwt-decode';

// const tokenDetails = () => {
//     var usrToken;
//     var menuToken;
//     if (localStorage.getItem('access_token') != null) {
//         usrToken = jwt(localStorage.getItem('access_token'));
//     }
//     if (localStorage.getItem('menu_token') != null) {
//         menuToken = jwt(localStorage.getItem('menu_token'));
//     }

//     if ((usrToken != null && usrToken != undefined) || (menuToken != null && menuToken != undefined)) {
//         usrToken
//     }
//     return null;
// }

const menuDetails = () => {
    if (localStorage.getItem('menu_token') != null) {
        return jwt(localStorage.getItem('menu_token'));
    }
    return null;
}

export default menuDetails;
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Link } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from 'react-router-dom';
import * as Designations from '../../enums/Designations';
import * as Travelmodes from '../../enums/Travelmodes';
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import exportData from "../../services/exportToExcelService";
import { Toolbar } from "primereact/toolbar";
import * as Genders from '../../enums/Genders';
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Dialog } from 'primereact/dialog';
import * as RegistrationStatus from '../../enums/RegistrationStatus';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';

const AttendeesonHold = () => {

    let emptyparticipant = [
        {
            id: '',
            registrationstatus: '',
        }
    ];

    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [participantdata, setparticipantdata] = useState(emptyparticipant);
    const [email, setemail] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [fullName, setfullName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [hospitalNamefilter, sethospitalNamefilter] = useState(null);
    const [companyNameFilter, setcompanyNameFilter] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        CompanyFilter: null,
        ParticipantNameFilter: null,
        EmailFilter: null,
        MobileNoFilter: null,
        CityFilter: null,
        StateFilter: null,
        CountryFilter: null,
        BoardingCityFilter: null,
        HospitalNameFilter: null,
        Designationfilter: null,
        Toeventcityfilter: null,
        Fromeventcityfilter: null,
        Genderfilter: null,
    }); // For Sorting And Pagination Purpose
    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [Cityfilter, setCityfilter] = useState(null);
    const [Statefilter, setStatefilter] = useState(null);
    const [Countryfilter, setCountryfilter] = useState(null);
    const [changestatusdialog, setchangestatusdialog] = useState(false);  // Status Change Dialog
    const [id, setid] = useState(''); // Setting ParticipantId    
    const [companyList,setCompanyList] = useState([]);
    const UserName = localStorage.getItem('Username');

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    var company = localStorage.getItem('Company');
    let orderedSelectedColumns = [];
    let unSelectedColumns = [];
    var usrDetails = userDetails();


    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty('overflow', 'hidden');
        getFilterListsData();
    }, []);

    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
         //Company
         var listCompanyFilterString = null;
         if (lazyState.current.CompanyFilter != null && lazyState.current.CompanyFilter != undefined && lazyState.current.CompanyFilter != '') {
             if (lazyState.current.CompanyFilter.length > 0) {
                 if (lazyState.current.CompanyFilter.length > 1) {
                     listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name).join(',');
                 } else {
                     listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name)[0];
                 }
             }
         }
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios.post('/api/Participant/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "Firstname",
            registrationStatus: 'HOLD',
            CompanyFilter: usrDetails.role === 'MR' ? company : listCompanyFilterString,
            FullName: lazyState.current.ParticipantNameFilter ? lazyState.current.ParticipantNameFilter.trim() : null,
            Email: lazyState.current.EmailFilter ? lazyState.current.EmailFilter.trim() : null,
            MobileNo: lazyState.current.MobileNoFilter ? lazyState.current.MobileNoFilter.trim() : null,
            Designation: lazyState.current.Designationfilter ? lazyState.current.Designationfilter.name.trim() : null,
            Gender: lazyState.current.Genderfilter ? lazyState.current.Genderfilter.name.trim() : null,
            ToEventCity: lazyState.current.Toeventcityfilter ? lazyState.current.Toeventcityfilter.code.trim() : null,
            FromEventCity: lazyState.current.Fromeventcityfilter ? lazyState.current.Fromeventcityfilter.code.trim() : null,
            City: lazyState.current.CityFilter ? lazyState.current.CityFilter.trim() : null,
            State: lazyState.current.StateFilter ? lazyState.current.StateFilter.trim() : null,
            Country: lazyState.current.CountryFilter ? lazyState.current.CountryFilter.trim() : null,
            BoardingCity: lazyState.current.BoardingCityFilter ? lazyState.current.BoardingCityFilter.trim() : null,
            SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
            HospitalName: lazyState.current.HospitalNameFilter ? lazyState.current.HospitalNameFilter.trim() : null
        }).then(Response => {
            if (!isExportExcel) setParticipantlist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    Id: dataObj.serialNumber,
                    Name: dataObj.fullName,
                    Hospital: dataObj.hospitalName,
                    Company: dataObj.company,
                    Email: dataObj.email,
                    MobileNo: dataObj.mobileNo,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Hospital: dataObj.hospitalName,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'HoldAttendees');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setParticipantlist(Response.data.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const getFilterListsData = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetAllFilterListData")
            .then((Response) => {
                const separatedLists = Response.data.reduce((acc, item) => {
                    // Create a new array for each type if it doesn't exist
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    // Push the formatted item to the respective array
                    acc[item.type].push({ name: item.name, code: item.name });
                    return acc;
                }, {});

                // Sort each list by 'name'
                Object.keys(separatedLists).forEach(type => {
                    separatedLists[type].sort((a, b) => a.name.localeCompare(b.name));
                });
                setCompanyList(separatedLists.Company || [])

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // get participant Record by id
    const getparticipantbyid = (id) => {
        setIsLoading(true);
        axios.get('/api/Participant/GetParticipantById/' + id)
            .then((Response) => {
                setid(id)
                let data = []
                data.push(Response.data);
                let _participant = data;
                _participant['registrationstatus'] = (Response.data.registrationStatus);
                setparticipantdata(_participant);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    //Update Registration Status of participant
    const updatestatus = (id) => {
        setIsLoading(true);
        axios.put('/api/Participant/updateparticipantbymultiple', {
            id: id,
            registrationstatus: participantdata.registrationstatus,
            ModifiedBy:UserName
        })
            .then(res => {
                setchangestatusdialog(false);
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Status Updated', life: 3000 });
                // document.documentElement.style.setProperty('overflow', 'auto');
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="fullName" value={fullName} placeholder="Enter First Name"
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const BoardingCityFilterTemplate = () => {
        return (
            <InputText id="boardingcityname" value={boardingcityfilter} placeholder="Enter Boarding City"
                onChange={(e) => setboardingcityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const HospitalFilterTemplate = () => {
        return (
            <InputText id="hospitalname" value={hospitalNamefilter} placeholder="Enter Hospital Name"
                onChange={(e) => sethospitalNamefilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const CompanyFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.CompanyFilter}
                options={companyList}
                itemTemplate={CompanyFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => CompanyFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const CompanyFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const CompanyFilterBodyTemplate = (rowData) => {
        const company = rowData.company;
        return (
            <div className="flex align-items-center gap-2">
                <span>{company}</span>
            </div>
        );
    };

    const CompanyFilterClick = (e) => {
        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { CompanyFilter: e.value });
        e.preventDefault();
    }

    const EmailFilterTemplate = () => {
        return (
            <InputText id="Email" value={filterEmail} placeholder="Enter Email"
                onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const MobileFilterTemplate = () => {
        return (
            <InputText id="Mobile" value={filterMobileNo} placeholder="Enter Mobile No."
                onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Designationfilter} options={Designations.Designation} optionLabel="name" showClear onChange={(e) => { lazyState.current.Designationfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Designation: e.value }); e.preventDefault() }} placeholder="Select Designation" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Genderfilter} options={Genders.Gender} optionLabel="name" showClear onChange={(e) => { lazyState.current.Genderfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Gender: e.value }); e.preventDefault() }} placeholder="Select Gender" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Toeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Toeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { ToEventCity: e.value }); e.preventDefault() }} placeholder="Select Travel Mode " className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const FromEventCityTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Fromeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Fromeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { FromEventCity: e.value }); e.preventDefault() }} placeholder="Select Travel Mode" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const CityFilterTemplate = () => {
        return (
            <InputText id="city" value={Cityfilter} placeholder="Enter City"
                onChange={(e) => setCityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const StateFilterTemplate = () => {
        return (
            <InputText id="state" value={Statefilter} placeholder="Enter State"
                onChange={(e) => setStatefilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const CountryFilterTemplate = () => {
        return (
            <InputText id="country" value={Countryfilter} placeholder="Enter Country"
                onChange={(e) => setCountryfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.ParticipantNameFilter = e.target.value ? e.target.value : null
                        break;
                    case "boardingcityname":
                        lazyState.current.BoardingCityFilter = e.target.value ? e.target.value : null
                        break;
                    case "hospitalname":
                        lazyState.current.HospitalNameFilter = e.target.value ? e.target.value : null
                        break;
                    case "company":
                        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null
                        break;
                    case "Email":
                        lazyState.current.EmailFilter = e.target.value ? e.target.value : null
                        break;
                    case "Mobile":
                        lazyState.current.MobileNoFilter = e.target.value ? e.target.value : null
                        break;
                    case "city":
                        lazyState.current.CityFilter = e.target.value ? e.target.value : null
                        break;
                    case "state":
                        lazyState.current.StateFilter = e.target.value ? e.target.value : null
                        break;
                    case "country":
                        lazyState.current.CountryFilter = e.target.value ? e.target.value : null
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                };
                getparticipantlist(isExportExcel);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.ParticipantNameFilter = null
                        break;
                    case "boardingcityname":
                        lazyState.current.BoardingCityFilter = null
                        break;
                    case "hospitalname":
                        lazyState.current.HospitalNameFilter = null
                        break;
                    case "company":
                        lazyState.current.CompanyFilter = null
                        break;
                    case "Email":
                        lazyState.current.EmailFilter = null
                        break;
                    case "Mobile":
                        lazyState.current.MobileNoFilter = null
                        break;
                    case "city":
                        lazyState.current.CityFilter = null
                        break;
                    case "state":
                        lazyState.current.StateFilter = null
                        break;
                    case "country":
                        lazyState.current.CountryFilter = null
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                };
                getparticipantlist(isExportExcel);
                setIsLoading(false);
            };
        };
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
        setIsLoading(false);
    // }
};

//Template for pagination
const template2 = {
    layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    'CurrentPageReport': (options) => {
        return (
            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                Showing {options.first} to {options.last} of {options.totalRecords} entries
            </span>
        )
    },
    RowsPerPageDropdown: (options) => {
        return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
    },
};

const exportExcel = () => {
    getparticipantlist(true);
};

const removeFilter = () => {
    lazyState.current.CompanyFilter = null;
    setcompanyNameFilter(null);
    lazyState.current.ParticipantNameFilter = null;
    setfullName(null);
    lazyState.current.BoardingCityFilter = null;
    setboardingcityfilter(null);
    lazyState.current.CityFilter = null;
    setCityfilter(null);
    lazyState.current.CountryFilter = null;
    setCountryfilter(null);
    lazyState.current.Designationfilter = null;
    lazyState.current.HospitalNameFilter = null;
    sethospitalNamefilter(null);
    lazyState.current.EmailFilter = null;
    setFilterEmail(null);
    lazyState.current.MobileNoFilter = null;
    setFilterMobileNo(null);
    lazyState.current.Genderfilter = null;
    lazyState.current.StateFilter = null;
    setStatefilter(null);
    lazyState.current.Toeventcityfilter = null;
    lazyState.current.Fromeventcityfilter = null;
    lazyState.current.serialnumber = null;
    setSerialNumber(null);
    getparticipantlist(isExportExcel, lazyState.current);
};

// Right Side Toolbar
const rightToolbarTemplate = () => {
    return (
        <div className="my-2">
            <AccessControl allowedPermissions={["route:attendeesonhold"]}>
                <Button disabled={lazyState.current.CompanyFilter == null && lazyState.current.ParticipantNameFilter == null && lazyState.current.BoardingCityFilter == null
                    && lazyState.current.CityFilter == null && lazyState.current.CountryFilter == null && lazyState.current.Designationfilter == null &&
                    lazyState.current.HospitalNameFilter == null && lazyState.current.EmailFilter == null && lazyState.current.MobileNoFilter == null &&
                    lazyState.current.Genderfilter == null && lazyState.current.StateFilter == null && lazyState.current.Toeventcityfilter == null &&
                    lazyState.current.serialnumber == null && lazyState.current.Fromeventcityfilter == null ? true : false}
                    icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
            </AccessControl>
            <div className="my-2">{header}</div>
            <AccessControl allowedPermissions={["export:attendeesonhold"]}>
                <Button disabled={!(participantlist != null && participantlist.length > 0)} tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={() => { exportExcel(); }} icon="pi pi-file-excel" className="mr-2" outlined />
            </AccessControl>
        </div>
    )
};

//#region Method for Column toggle
const hidecolumns = [
    { field: "designation", header: "Designation" },
    { field: "gender", header: "Gender" },
    { field: "boardingcityname", header: "BoardingCity" },
    { field: "cityname", header: "City" },
    { field: "statename", header: "State" },
    { field: "countryname", header: "Country" },
    { field: "travelmode_Inbound", header: "Travel In Bound" },
    { field: "travelmode_Outbound", header: "Travel Out Bound" }
];

const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    orderedSelectedColumns = hidecolumns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
    unSelectedColumns = hidecolumns.filter(o => !selectedColumns.some(i => i.field === o.field && i.header === o.header));
    unselectedColumnData();
    setSelectedColumns(orderedSelectedColumns);
};

const unselectedColumnData = () => {
    let isFiltered = false;
    if (unSelectedColumns) {
        unSelectedColumns.map(col => {
            switch (col.header) {
                case "Designation":
                    if (lazyState.current.Designationfilter != null) {
                        lazyState.current.Designationfilter = null;
                        isFiltered = true;
                        return lazyState.current.Designationfilter;
                    };
                    break;
                case "Gender":
                    if (lazyState.current.Genderfilter != null) {
                        lazyState.current.Genderfilter = null;
                        isFiltered = true;
                        return lazyState.current.Genderfilter;
                    };
                    break;
                case "BoardingCity":
                    if (lazyState.current.BoardingCityFilter != null) {
                        lazyState.current.BoardingCityFilter = null;
                        isFiltered = true;
                        return setboardingcityfilter(null);
                    };
                    break;
                case "City":
                    if (lazyState.current.CityFilter != null) {
                        lazyState.current.CityFilter = null;
                        isFiltered = true;
                        return setCityfilter(null);
                    };
                    break;
                case "State":
                    if (lazyState.current.StateFilter != null) {
                        lazyState.current.StateFilter = null;
                        isFiltered = true;
                        return setStatefilter(null);
                    };
                    break;
                case "Country":
                    if (lazyState.current.CountryFilter != null) {
                        lazyState.current.CountryFilter = null;
                        isFiltered = true;
                        return setCountryfilter(null);
                    };
                    break;
                case "Travel In Bound":
                    if (lazyState.current.Toeventcityfilter != null) {
                        lazyState.current.Toeventcityfilter = null;
                        isFiltered = true;
                        return lazyState.current.Toeventcityfilter;
                    };
                    break;
                case "Travel Out Bound":
                    if (lazyState.current.Fromeventcityfilter != null) {
                        lazyState.current.Fromeventcityfilter = null;
                        isFiltered = true;
                        return lazyState.current.Fromeventcityfilter;
                    };
                    break;
            };
        });
        if (isFiltered) {
            getparticipantlist();
        };
    };
};


const header = (
    <div style={{ textAlign: 'left', marginRight: '10px' }}>
        <AccessControl allowedPermissions={["route:attendeesonhold"]}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: '20em' }} display="chip" />
        </AccessControl>
    </div>
);

const hideColumnsFilterTemplate = (column) => {
    switch (column) {
        case "Designation":
            return DesignationFilterTemplate;
        case "Gender":
            return GenderFilterTemplate;
        case "BoardingCity":
            return BoardingCityFilterTemplate;
        case "City":
            return CityFilterTemplate;
        case "State":
            return StateFilterTemplate;
        case "Travel In Bound":
            return ToEventCityTemplate;
        case "Travel Out Bound":
            return FromEventCityTemplate;
        case "Country":
            return CountryFilterTemplate;
        case "Gender":
            return GenderFilterTemplate;
    };
};

const sortColumn = {
    "Designation": 'Designation',
    "company": 'company',
    "boardingcityname": 'boardingcityname',
    "cityname": 'cityname',
    "Statename": 'Statename',
    "Countryname": 'Countryname',
    "Travel In Bound": 'Travelmode_Inbound',
    "Travel Out Bound": 'Travelmode_Outbound',
    "Gender": 'Gender',
};

const hidecolumnComponents = selectedColumns.map(col => {
    return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: '15%', minWidth: '15rem' }} sortField={sortColumn[col.header]}
        filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} />;
});
//#endregion


const ButtonMailto = (rowData) => {
    return (
        <Link className='linkcolors' id='mailto'
            to='#'
            onClick={(e) => {
                window.location.href = `mailto:${rowData.email}`;
                e.preventDefault();
            }}
        >{rowData.email}
        </Link>
    );
};

const ButtonPhoneto = (rowData) => {
    return (
        <Link className='linkcolors' id='callto'
            to='#'
            onClick={(e) => {
                window.location.href = `tel:${rowData.mobileNo}`;
                e.preventDefault();
            }}
        >{rowData.mobileNo}
        </Link>
    );
};

const ViewAttendees = (rowData) => {
    return (
        <AccessControl allowedPermissions={["view:attendeesonhold"]}>
            <Link className='linkcolors' id='viewattendee'
                to='#'
                onClick={(e) => {
                    history.push('/home/viewparticipant/' + rowData.id)
                }}
            >{rowData.fullName}
            </Link>
        </AccessControl>
    );
};

const onDropdownChange = (e, name) => {
    const val = (e.target && e.target.value.name) || '';
    let _participant = { ...participantdata };
    _participant[`${name}`] = val;
    setparticipantdata(_participant);
};

const opendialog = (participant) => {
    // document.documentElement.style.setProperty('overflow', 'hidden')
    setchangestatusdialog(true);
    getparticipantbyid(participant);
};

const actionBodyTemplate = (rowData) => {
    return (
        <div className="actions">
            <AccessControl allowedPermissions={["changestatus:attendeesonhold"]}>
                <Button icon="fa fa-solid fa-retweet" size="lg" className="p-button-rounded mr-2" tooltip="Change Status" tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                    onClick={() => { opendialog(rowData.id); setid(rowData.id); setemail(rowData.email); }} outlined />
            </AccessControl>
        </div>
    );
};

// Modals Button To Save Changes
const changestatusfootet = (
    <>
        <Button label="Save" icon="fa fa-check" className="p-button-success" onClick={() => { updatestatus(id); }} outlined />
        <Button label="Cancel" icon="fa fa-times" className="p-button-secondary" onClick={() => { 
            // document.documentElement.style.setProperty('overflow', 'auto'); 
            setchangestatusdialog(false) }} outlined />
    </>
);

return (
    <AccessControl allowedPermissions={["route:attendeesonhold"]} renderNoAccess={() => <NoAccess />} >
        <div className="trico-Grid">
            <Toast ref={toast} />
            {isLoading ? <LoadingSpinner /> :
                (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont"><span><i className="fa fa-solid fa-users mr-2"></i>Attendees On Hold</span></h5>
                                <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <DataTable size="small" lazy rows={lazyState.current.rows}
                                    onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder} scrollHeight="calc(100vh - 250px)" scrollable
                                    removableSort stripedRows ref={dt} value={participantlist} selection={participantlist} onSelectionChange={(e) => setParticipantlist(e.value)}
                                    dataKey="Id" responsiveLayout="scroll" filterDisplay="row">
                                    <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column body={ViewAttendees} sortField="FullName" frozen field="fullName" header="Name" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={UserFilterTemplate} showClearButton></Column>
                                    <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} showClearButton></Column>
                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} body={CompanyFilterBodyTemplate} filterField="Company" filterMenuStyle={{ width: '14rem' }} ></Column>
                                    <Column body={ButtonMailto} sortField="Email" field="email" header="Email" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                    <Column body={ButtonPhoneto} sortField="MobileNo" field="mobileNo" header="Mobile No." sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                    {hidecolumnComponents}
                                    <Column header="Action" body={actionBodyTemplate} exportable={false} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                                </DataTable>

                                <Dialog visible={changestatusdialog} draggable={false} style={{ width: '450px' }} header="Change Status" modal className="p-fluid" footer={changestatusfootet} onHide={() => { 
                                    // document.documentElement.style.setProperty('overflow', 'auto'); 
                                    setchangestatusdialog(false) }}>
                                    <div className="field">
                                        <label id="label" htmlFor="registrationstatus">Registration Status</label>
                                        <Dropdown value={participantdata.registrationstatus}
                                            placeholder={participantdata.registrationstatus == '' ? "Select Status" : participantdata.registrationstatus}
                                            required autoFocus onChange={(e) => onDropdownChange(e, 'registrationstatus')}
                                            options={RegistrationStatus.RegistrationStatus} optionLabel="name"
                                            className="w-full " />
                                    </div>
                                </Dialog>
                                <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                            </div>
                        </div >
                    </>
                )
            }
        </div>
    </AccessControl>
)
};

export default AttendeesonHold;
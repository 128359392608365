import React, { useEffect, useState } from "react";
import { Link, Route } from "react-router-dom";
import classNames from "classnames";
import { Menu } from "primereact/menu";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { useRef } from "react";
import userDetails from "./services/userTokenDetails";
import { Avatar } from "primereact/avatar";
import "primeicons/primeicons.css";
import { SlideMenu } from "primereact/slidemenu";

export const AppTopbar = (props) => {
    const menu = useRef(null);
    var usrDetails = userDetails();
    const [HideTopBar, setHideTopBar] = useState(false);

    useEffect(() => {
        getHeaders();
        // if (usrDetails?.role == "MR" || usrDetails?.role == "SUBMR" || usrDetails?.role == "DOCTOR") {

        // }
    }, []);

    const getHeaders = () => {
        fetch("config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                // setRegEndDate(data[4].RegistrationendDt);
                if ((usrDetails?.role == null || usrDetails?.role == null) && new Date() > new Date(data[4].RegistrationendDt)) {
                    setHideTopBar(true);
                } else {
                    setHideTopBar(false);
                }
            });
    };

    const HomePathRoleWise = () => {
        if (usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN") {
            return "/#/home/dashboard";
        } else if (usrDetails?.role == "DOCTOR") {
            return "/#/home/doctorview";
        } else {
            return "/#/home";
        }
    };

    const SignOut = () => {
        window.location.assign("/");
        localStorage.clear();
    };

    return (
        <div className={HideTopBar == true ? "hidden" : "layout-topbar"}>
            <a href={HomePathRoleWise()}>
                <img className="w-9rem cursor-pointer" src={props.layoutColorMode === "light" ? "assets/layout/images/tricoguru-logo-3-1.png" : "assets/layout/images/tricoguru-logo-3-1.png"} alt=" T R I C O" />
            </a>

            <button
                type="button"
                className={
                    usrDetails == null
                        ? "hidden"
                        // : usrDetails.role === "DOCTOR" || usrDetails.role == null || usrDetails.role == "VENUE CO-ORDINATOR" || usrDetails.role == "AIRPORT CO-ORDINATOR" || usrDetails.role == "HOTEL CO-ORDINATOR"
                        // ? "hidden"
                        : "p-link  layout-menu-button layout-topbar-button"
                }
                onClick={props.onToggleMenuClick}
                style={{ width: "3rem" }}
            >
                <i className={usrDetails == null ? "hidden" : usrDetails.role === "DOCTOR" || usrDetails.role == null ? "hidden" : "pi pi-bars"} />
            </button>
            {/* <Menu model={items} popup ref={menu} id="popup_menu" /> */}
            {/* <SlideMenu ref={menu} model={items} popup viewportHeight={220} menuWidth={175}></SlideMenu> */}
            <label id="label">{localStorage.getItem("Company")?.toUpperCase()}</label>
            {/* <Avatar className={classNames("layout-topbar-menu lg:flex origin-top")} label={usrDetails?.fullName.charAt(0).toUpperCase()} style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} />
            <label  style={{ marginLeft:'5px' }}>{usrDetails?.fullName}</label> */}
            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>
            <ul className={classNames(" layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li>
                    <div className="p-link layout-topbar-button">
                        <h5 className="mt-3">{localStorage.getItem("Username")?.toUpperCase()} </h5>
                        {/* <Avatar label={usrDetails?.fullName.charAt(0).toUpperCase()} style={{ backgroundColor: '#9c27b0', color: 'black' }} /> */}
                    </div>
                </li>
                <li className={localStorage.getItem("Username") ? "" : "hidden"}>
                    <button className="p-link layout-topbar-button" onClick={(event) => SignOut()} style={{ width: "3rem" }}>
                        <i className="pi pi-sign-out" />
                        <span>Log Out</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
